import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Avatar, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { UserRequestListService } from '../services/UserRequestListService';
import { IUserRequestListResult } from '../types/userRequestListtype';
import CartPage from './CartPage';
import Navbar from '../components/Navbar';
const RequestList = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [userRequestList, setUserRequestList] = useState<IUserRequestListResult[] | undefined>([]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const GetUserRequestList: any = async () => {
        let res = (await UserRequestListService.GetAllUserRequestList()).data;
        if (res.data) {
            console.log(res.data);
            setUserRequestList(res.data);
        }
    }

    useEffect(() => {
        GetUserRequestList();
    }, [])

    interface columnType {
        id?: string,
        label?: string,
        align?: string,
        minWidth?: string,
    }
    const columns: columnType[] = [
        {
            id: 'ImageUrl', align: 'center', label: '', minWidth: "50px",
        },
        {
            id: 'ItemName', label: 'Requested Item', minWidth: "130px"
        },
        {
            id: 'UserName',
            label: 'User Name',
            minWidth: "100px"
        },
        {
            id: 'StatusDate',
            label: 'Status Date',
            minWidth: "100px"
        },
        {
            id: 'Status',
            label: 'Status',
        },
    ];

    return (
        <>
            <Navbar title="TechHub" />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={9}>
                    <Paper
                        elevation={4}
                        sx={{
                            maxWidth: "100%",
                            minHeight: "70%",
                            maxHeight: "100%",
                            padding: 3,
                            margin: 2,
                            textAlign: "center",
                        }}
                    >
                        <h1>User Request List</h1>

                        <Paper className='requestTableDiv' sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ minHeight: 500, maxHeight: 500 }}>
                                <Table stickyHeader aria-label="sticky table" sx={{ fontWeight: "bold" }}>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column: any) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userRequestList && userRequestList
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((list: IUserRequestListResult, index: Number) => {
                                                return (
                                                    <TableRow hover tabIndex={-1}>
                                                        <TableCell style={{ minWidth: "50px" }} ><Avatar
                                                            alt="Remy Sharp"
                                                            src={process.env.PUBLIC_URL + list.imageUrl}
                                                            sx={{ width: "auto", height: "auto" }}
                                                        /></TableCell>
                                                        <TableCell style={{ minWidth: "130px" }}>{list.itemName}</TableCell>
                                                        <TableCell style={{ minWidth: "100px" }}>{list.userName}</TableCell>
                                                        <TableCell style={{ minWidth: "100px" }}>{list.statusDate}</TableCell>
                                                        <TableCell style={{ minWidth: "100px" }}>{list.statusType}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={userRequestList!.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <CartPage></CartPage>
                </Grid>
            </Grid>
        </>
    )
}

export default RequestList