import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { CategoryItemFormService } from '../../services/CategoryItemFormModule/CategoryItemFormService';
import { GetErrorMessageFromResponse } from '../../common/commonFunction';
import { useSnackBar } from '../../components/context/snackbar-context';
import { IFormDetails } from '../../types/formType';
import FormBuilder from './FormBuilder';
import { Box, Grid, Button, Breadcrumbs } from '@mui/material';
import Navbar from '../../components/Navbar';
import InputFormControl from '../../components/controls/InputFormControl';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useConfirmPopup } from '../../components/context/confirmation-context';
import { userStore } from '../../store/store';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Loader from '../../components/common components/Loader';

const AddEditForm = () => {
    const [isLoading, setIsloading] = useState<boolean>(true);
    const [formData, setFormData] = useState<any>();
    const { id } = useParams();
    const uStore: any = userStore();
    const snackbar = useSnackBar();
    const confirmPopupService = useConfirmPopup();
    const validationSchema = Yup.object()
        .shape({
            formName: Yup.string().required("Form name is required"),
            templateID: Yup.string()
        }).required();
    const navigate: any = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const GetFormDataByID = () => {
        CategoryItemFormService.getFormDetailsById(id)
            .then((res: any) => {
                if (res && res.data.code === 200) {
                    setFormData(res.data.data);
                }
                else {
                    snackbar.showSnackBar(res.data.data, "error");
                }
            })
            .catch(err => {
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error")
            });
    }

    useEffect(() => {
        if (Number(id) !== 0 && formData) {
            setValue("formName", formData.formName);
            setValue("templateID", formData.templateID);
            setIsloading(false);

        }
        else {
            setValue("formName", "");
            setValue("templateID", "");
        }
    }, [formData]);

    const handleCancelFormBuilder = () => {
        confirmPopupService.showPopup("Are you sure you want to cancel?", () => {
            confirmPopupService.hidePopup();
            navigate('/form-list');
        });
    }
    useEffect(() => {
        if (Number(id) !== 0)
        {
            setIsloading(true);
            GetFormDataByID();
        }
        else {
            setIsloading(false);
        }
    }, []);

    return (
        <>
            <div className='breadcumDiv'>
                {
                    uStore.getUser().role === "Administrator" ?
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb">
                            <NavLink to="/pending-request" title="Pending Request">
                                Pending Request
                            </NavLink>
                            <NavLink to="/form-list" title="Form Management">
                                Form Management
                            </NavLink>
                            <span className="active-breadcumb">Add-Update Form</span>
                        </Breadcrumbs>) :
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/search" title="Make A Request">
                                Make A Request
                            </NavLink>
                            <NavLink to="/form-list" title="Form Management">
                                Form Management
                            </NavLink>
                            <span className="active-breadcumb">Add-Update Form</span>
                        </Breadcrumbs>)
                }
            </div>
            <Navbar title="Form Managment" />
            {
                isLoading ?
                    (
                        <Loader />
                    ) :
                    (
                        <div style={{ backgroundColor: "#f1f1f1" }}>
                            <form onSubmit={handleSubmit(async (data: any) => {
                    const dynamicForm: any = window.localStorage.getItem("SavedForm");
                    let formData: IFormDetails = {
                        id: Number(id),
                        templateID: data.templateID,
                        formName: data.formName,
                        form: dynamicForm
                    }
                    CategoryItemFormService.addOrUpdateItemForm(formData)
                        .then((res: any) => {
                            if (res && res.data.code === 200) {
                                snackbar.showSnackBar(res.data.data, "success");
                                navigate('/form-list');
                            }
                            else {
                                snackbar.showSnackBar(res.data.data, "error");
                            }
                        })
                        .catch(err => {
                            snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error")
                        })
                })}>
                    <div className='formBuilderTitle'>
                        <div>
                            <Box className="py-2">
                                <h1 className="text-2xl">Form Builder Details</h1>
                            </Box>
                        </div>
                        <div style={{ width: "30%" }}>
                            <Grid className="flex justify-end" container spacing={2}>
                                            <Grid sx={{ textAlign: "left" }} item xs={8} md={8}>
                                                <div className='row' >
                                                    <div className="form-lable col-4" style={{ marginBottom: "5px" }}>
                                                        <label>Form Name</label>
                                                    </div>
                                                    <div className='col-8 formNameDiv' >
                                                        <InputFormControl
                                                            name="formName"
                                                            label=""
                                                            errors={errors}
                                                            control={control}
                                                            type=""
                                                        />
                                                    </div>

                                                </div>
                                            </Grid>
                                            <Grid sx={{ textAlign: "left" }} item xs={4} md={4}>
                                                <div className='row' >
                                                    <div className="form-lable col-4" style={{ marginBottom: "5px" }}>
                                                        <label>Template ID</label>
                                                    </div>
                                                    <div className='col-8 formNameDiv' >
                                                        <InputFormControl
                                                            name="templateID"
                                                            label=""
                                                            errors={errors}
                                                            control={control}
                                                            type=""
                                                        />
                                                    </div>

                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div>
                        <FormBuilder formDetails={formData} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "right", marginTop: "15px" }}>
                        <Button type='submit' sx={{ marginBottom: 2, marginRight: 1 }} size="large" variant="contained">Submit</Button>
                        <Button color="primary" sx={{ marginBottom: 2, marginRight: 1 }} size="large" variant="contained" onClick={() => { handleCancelFormBuilder(); }}>
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
                    )
            }


        </>
    )
}

export default AddEditForm