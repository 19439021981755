import { AxiosResponse } from "axios";
import instance from "../../common/http.common";
import {
  AddUpdateCategory,
  DeleteCategory,
  GetCategoryDetailsById,
  GetCategoryList,
} from "../../common/ApiEndPoints";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";
const _baseUrl: string = `${_rootUrl}/Category`;

const getCategory = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + GetCategoryList);
};

const removeCategory = async (id: number): Promise<AxiosResponse<any>> => {
  return await instance.delete(
    `${_baseUrl + DeleteCategory}/?categoryId=${id}`
  );
};

const getCategoryById = async (id: number): Promise<AxiosResponse<any>> => {
  return await instance.get(
    `${_baseUrl + GetCategoryDetailsById}/?categoryId=${id}`
  );
};

const addOrUpdateCategory = async (
  id: number,
  categoryName: string
): Promise<AxiosResponse<any>> => {
  let data = {
    Id: id,
    CategoryName: categoryName,
  };
  return await instance.post(_baseUrl + AddUpdateCategory, data);
};

export const CategoryService = {
  getCategory,
  getCategoryById,
  removeCategory,
  addOrUpdateCategory,
};
