import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar';
import { Box, Breadcrumbs, Button, Card, FormControl, Grid, InputAdornment, InputLabel, List, OutlinedInput, Typography } from '@mui/material';
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useConfirmPopup } from '../components/context/confirmation-context';
import UserThreeDots from '../components/UserThreeDots';
import useStore, { userStore } from '../store/store';
import Loader from '../components/common components/Loader';
import { IUserList } from '../types/user';
import PersonIcon from '@mui/icons-material/Person';
import { useSnackBar } from '../components/context/snackbar-context';
import AddUser from '../components/AddUser';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { NavLink } from 'react-router-dom';
import { UserDetialsService } from './../services/UserDetialsService';
import { GetErrorMessageFromResponse, searchUser } from '../common/commonFunction';
import { useMsal } from '@azure/msal-react';

import axios from 'axios';

const UserManagement = () => {
    const [isLoading, setIsloading] = useState(true);
    const [OpenPopup, SetOpenPopup] = useState(false);
    const { userList, fetchUserList } = useStore();
    const [userDetails, setUserDetails] = useState({});
    const uStore: any = userStore();
    const [filteredUserList, setFilteredUserList] =
        useState<IUserList[]>(userList);

    const [searchText, setSearched] = useState("");
    const [searchQueryString, setSearchQueryString] = useState("");

    const snackbar = useSnackBar();
    const confirmPopupService = useConfirmPopup();

    const { instance, accounts } = useMsal();
    //const msalInstance = new PublicClientApplication(msalConfig);

    const getAllUsers = async () => {
        fetchUserList().then((res: any) => {
            if (res) {
                setFilteredUserList(res);
                setIsloading(false);
            }
        });
    }
    useEffect(() => {
        setSearched("");
        setSearchQueryString("");
        getAllUsers();
    }, [])

    const handleCancelUser = (id: any) => {
        confirmPopupService.showPopup("Are you sure you want to remove this user?", () => { handleConfirmCanceledUser(id) });
    }
    const handleConfirmCanceledUser = async (id: any) => {
        let res = await UserDetialsService.CancelUser(id);
        if (res.data) {
            if (res.data.code === 200 && res.data.data === "success") {
                getAllUsers();
                snackbar.showSnackBar('User has been removed successfully.', "success");
                confirmPopupService.hidePopup();
            }
            else {
                snackbar.showSnackBar('User has not been removed.', "error");
                confirmPopupService.hidePopup();
            }
        }
        else {
            snackbar.showSnackBar('Internal server error.', "error");
        }
    }

    const handleCloseAddUserPopup = (isShowPopup: boolean) => {
        SetOpenPopup(isShowPopup);
    }

    const InputHandler = (e: any) => {
        setSearched(e.target.value);
        if (e.target.value.length === 0 && e.target.value === "")
            setSearchQueryString(e.target.value);
    };

    const onKeyDownHandler = (e: any) => {
        if (e.keyCode === 13 || e.key === "Enter") {
            setSearchQueryString(searchText);
        }
    };
    const AddUpdateUser = async (id: any, emailID: any) => {
        // console.log(userDetails);
        //  userDetails.id = id;
        //const email = 'snananiya@adevco.inc';
        const accounts = instance.getAllAccounts();
        if (accounts.length === 0) {
            throw new Error('No accounts available');
        }

        const accessToken = await instance.acquireTokenSilent({
            scopes: ['User.Read.All'],
            account: accounts[0],
        });

        console.log(accessToken.accessToken);

        //axios.get(`https://graph.microsoft.com/v1.0/me`, {
        axios.get(`https://graph.microsoft.com/v1.0/users/${emailID}?$expand=manager`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + accessToken.accessToken
            }
        })
            .then((response: any) => {
                console.log('Request successful:', response.data);
                let managerId = null;
                if (response.data.manager) {
                    managerId = response.data.manager.mail;
                }
                let userData = {
                    id: 0,
                    email: response.data.mail,
                    firstName: response.data.givenName,
                    lastName: response.data.surname,
                    title: response.data.jobTitle,
                    managerMailId: managerId
                }
                UserDetialsService.AddNewUser(userData).then((res: any) => {
                if (res) {
                    if (res.data.code === 302 && res.data.messages[0] === "found") {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else if (res.data.code === 200 && res.data.messages[0] === "Success") {
                        if (id === 0) {
                            confirmPopupService.showPopup("Is this user an TechHub Administrator?", () => { handleConfirmAdminUser(res.data.data.id) });
                            SetOpenPopup(false);
                        }
                        else {
                            snackbar.showSnackBar('User has been updated successfully.', "success");
                            SetOpenPopup(false);
                        }
                        getAllUsers();
                    } else {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                }
            })
            .catch(err =>
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error"));
            })
            .catch((error: any) => {
                snackbar.showSnackBar("This user has not been registered as an azure pmc tenant.", "error");
            });
    }

    const handleConfirmAdminUser = (userId: any) => {
        UserDetialsService.UpdateUserRole(userId)
            .then((res: any) => {
                if (res) {
                    if (res.data.code === 404 && res.data.messages[0] === "found") {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else if (res.data.code === 200 && res.data.messages[0] === "Success") {
                        snackbar.showSnackBar('User has been added successfully.', "success");
                        confirmPopupService.hidePopup();
                        SetOpenPopup(false);
                    } else {
                        snackbar.showSnackBar("Somthing Went Wrong!", "error");
                    }
                } else {
                    snackbar.showSnackBar("Somthing Went Wrong!", "error");
                }
                getAllUsers();
            }).catch(err => snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error"));
    }
    const handleUpdateUser = (details: any) => {
        if (details) {
            setUserDetails(details);
            SetOpenPopup(true);
        }
    }
    useEffect(() => {
        setFilteredUserList([]);
        if (searchQueryString.length === 0 && searchQueryString === "") {
            setFilteredUserList(userList);
        }
        else {
            let result: any = searchUser(
                searchQueryString,
                userList
            );
            if (result && result.length > 0) {
                setFilteredUserList(result);
            }
        }



    }, [searchQueryString]);

    return (
        <>
            <div className='breadcumDiv'>
                {
                    uStore.getUser().role === "Administrator" ?
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/pending-request" title="Pending Request">
                                Pending Request
                            </NavLink>
                            <span className="active-breadcumb">User Management</span>
                        </Breadcrumbs>) :
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/search" title="Make A Request">
                                Make A Request
                            </NavLink>
                            <span className="active-breadcumb">User Management</span>
                        </Breadcrumbs>)
                }

            </div>
            <Navbar title="User Management" />
            <Typography
                sx={{
                    maxWidth: "100%",
                    minHeight: "70%",
                    maxHeight: "100%",
                    padding: 3,
                    margin: 2,
                    textAlign: "center",
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                    <FormControl sx={{ m: 1, width: '50%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type="text"
                            onChange={(e: any) => {
                                InputHandler(e);
                            }}
                            onKeyDown={(e: any) => {
                                onKeyDownHandler(e);
                            }}
                            placeholder="Search here"
                            endAdornment={
                                <InputAdornment position="end" sx={{ cursor: "pointer " }}>
                                    <SearchSharpIcon
                                        onClick={() => {
                                            setSearchQueryString(searchText);
                                        }}
                                    />
                                </InputAdornment>
                            }
                            label="Search"
                        />
                    </FormControl>
                    <Button onClick={() => {
                        setUserDetails({
                            id: 0,
                        })
                        SetOpenPopup(true);
                    }} sx={{ height: "50px" }} variant="contained"> + New User</Button>
                </Box>
                <div>
                    {
                        isLoading ?
                            (
                                <Loader />
                            ) :
                            (
                                <List sx={{ margin: "auto", textAlign: "center", width: "95%" }}>
                                    <Grid container sx={{ gridAutoRows: "1fr" }} rowSpacing={4} spacing={2}>
                                        {
                                            filteredUserList && filteredUserList.length > 0 ?
                                                (
                                                    filteredUserList.map((list: any) => {
                                                        return (
                                                            <Grid item xs={11} sm={6} md={3} >
                                                                <Card elevation={3} sx={{ p: 2, height: "95%" }} className='PendingRequestItem'>
                                                                    <div>
                                                                        <Box sx={{ display: "flex", mb: 3, justifyContent: "space-between" }}>
                                                                            <div>
                                                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "start" }}>
                                                                                    <div>
                                                                                        {
                                                                                            list.ProfileImageURL ?
                                                                                                <img alt='' style={{ height: "80px", width: "80px" }} src={process.env.PUBLIC_URL + '/Icon/SharePoint Access.png'}></img>
                                                                                                :
                                                                                                <PersonIcon sx={{ height: "80px", width: "80px", border: "2px solid", borderRadius: "50px" }} />

                                                                                        }
                                                                                    </div>
                                                                                    <div className='userInformation'>
                                                                                        <div className='userName'>{list.firstName + " " + list.lastName}</div>
                                                                                        <div className='userDesignation'><i>{list.designation}</i></div>
                                                                                        <div className='userEmail'><b>{list.userEmail}</b></div>
                                                                                    </div>
                                                                                </Box>
                                                                            </div>
                                                                            <div>
                                                                                <></>
                                                                                <UserThreeDots userDetails={list} handleUpdateUser={(details: any) => handleUpdateUser(details)} handleCancelUser={(id: any) => handleCancelUser(id)} />
                                                                            </div>
                                                                        </Box>

                                                                        <div className='ShowIsAdmin'>
                                                                            {
                                                                                list.role === "Administrator" && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                                                                                    <ManageAccountsIcon sx={{ height: "50px", width: "50px" }} />
                                                                                    <label >Administrator</label>
                                                                                </Box>
                                                                            }

                                                                        </div>
                                                                    </div>

                                                                </Card>
                                                            </Grid>

                                                        )
                                                    })
                                                ) :
                                                (
                                                    <label className='noDataFoundMessage'>User not Found</label>
                                                )
                                        }

                                    </Grid>
                                </List>
                            )

                    }

                </div>
            </Typography>
            <AddUser userDetails={userDetails} isOpenDialog={OpenPopup} onAddUpdateUser={(id, emailID) => { AddUpdateUser(id, emailID) }} handleCloseAddUserPopup={(isShowPopup) => { handleCloseAddUserPopup(isShowPopup) }} />
        </>
    )
}

export default UserManagement
