import { AxiosResponse } from "axios";
import instance from "../../common/http.common";
import {
  AddUpdateItemForm,
  DeleteItemForm,
  GetFormDataById,
  GetItemFormList,
} from "../../common/ApiEndPoints";
import { IFormDetails } from "../../types/formType";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";
const _baseUrl: string = `${_rootUrl}/ItemForm`;

const getCategoryItemFormList = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + GetItemFormList);
};

const removeCategoryItemForm = async (
  id: number
): Promise<AxiosResponse<any>> => {
  return await instance.delete(`${_baseUrl + DeleteItemForm}/?formId=${id}`);
};

const addOrUpdateItemForm = async (
  FormData: IFormDetails
): Promise<AxiosResponse<any>> => {
  return await instance.post(_baseUrl + AddUpdateItemForm, FormData);
};

const getFormDetailsById = async (id: any): Promise<AxiosResponse<any>> => {
  return await instance.get(`${_baseUrl + GetFormDataById}/?formId=${id}`);
};

export const CategoryItemFormService = {
  getCategoryItemFormList,
  removeCategoryItemForm,
  addOrUpdateItemForm,
  getFormDetailsById,
};
