import { Breadcrumbs, Grid, Paper, Box, Button, TableContainer } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Loader from '../../components/common components/Loader';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import { GetErrorMessageFromResponse } from '../../common/commonFunction';
import { useConfirmPopup } from '../../components/context/confirmation-context';
import { useSnackBar } from '../../components/context/snackbar-context';
import { userStore } from '../../store/store';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { EmployeeOnboardingEquipmentService } from '../../services/EmployeeOnboardingEquipmentModule/EmployeeOnboardingEquipmentService';
import { IEmployeeOnBoardingList } from '../../types/categoryListType';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import AddEditEmployeeOnBoardingDetails from './AddEditEmployeeOnBoardingDetails';

const EmployeeOnBoardingList = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [employeeOnBoardingEquipmnetList, setEmployeeOnBoardingEquipmnetList] = useState<IEmployeeOnBoardingList[]>([]);
    const [employeeOnBoardingEquipmnetDetails, setEmployeeOnBoardingEquipmnetDetails] = useState<any>({});
    const [OpenAddEditEmployeeOnBoardingDetailsDialog, SetOpenAddEditEmployeeOnBoardingDetailsDialog] = useState<boolean>(false);
    const confirmPopupService = useConfirmPopup();
    const snackbar = useSnackBar();
    const uStore: any = userStore();
    const navigate: any = useNavigate();

    const GetEmployeeOnBoardingEquipmnetListList = () => {
        EmployeeOnboardingEquipmentService.GetEmployeeOnboardingList()
            .then((res: any) => {
                if (res) {
                    setEmployeeOnBoardingEquipmnetList(res.data.data);
                    setIsLoading(false);
                }
            })
            .catch((err: any) =>
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error"))
    }
    const handleDelete = (id: number) => {
        confirmPopupService.showPopup("Are you sure you want to remove this employee on boarding equipment?", () => { deleteEmployeeOnboardingEquipment(id) });
    }

    const deleteEmployeeOnboardingEquipment = async (id: number) => {
        let res = await EmployeeOnboardingEquipmentService.RemoveEmployeeOnboarding(id);
        if (res.data) {
            if (res.data.code === 200 && res.data.data === "success") {
                confirmPopupService.hidePopup();
                GetEmployeeOnBoardingEquipmnetListList();
                snackbar.showSnackBar('Form has been removed successfully.', "success");
            }
            else {
                snackbar.showSnackBar('Form has not removed successfully.', "error");
            }
        }
        else {
            snackbar.showSnackBar('Internal server error.', "error");
        }
    }

    const handleEdit = (id: any) => {
        navigate(`/edit-form/${id}`);
    }
    const columns: GridColDef[] = [
        {
            field: 'image', headerName: '', sortable: false, width: 150, renderCell: (params: any) => {
                console.log(params);
                return (
                    <>
                        <div className='ItemListGridImageDiv'>
                            <img
                                alt=''
                                src={params.row.equipmentImageURL}
                            />
                        </div>
                    </>
                );
            }
        },
        {
            field: 'equipmentName',
            headerName: 'Label',
            editable: false,
            width: 685,
            renderCell: (params) => {
                return (
                    <>
                        <div style={{ fontSize: "18px" }}>
                            {params.value}
                        </div>
                    </>
                );
            }
        },
        {
            field: 'itemCount',
            headerName: 'Total Items Count',
            editable: false,
            sortable: true,
            width: 300,
        },
        {
            field: "action",
            headerName: "",
            width: 100,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    <>
                        <EditIcon
                            onClick={() => {
                                setEmployeeOnBoardingEquipmnetDetails(params.row);
                                SetOpenAddEditEmployeeOnBoardingDetailsDialog(true);
                            }}
                            style={{ marginRight: 4 }} />
                        <DeleteIcon
                            onClick={() => { handleDelete(Number(params.row.id)); }}
                        />
                    </>
                );
            }
        }
    ];

    const AddEditEmployeeOnBoardingData = (id: number, data: any) => {
        data.id = id;
        EmployeeOnboardingEquipmentService.AddUpdateEmployeeOnboarding(data)
            .then((res: any) => {
                if (res && res.data.code === 200) {
                    handleCloseAddEditEmployeeOnBoardingPopup(false);
                    snackbar.showSnackBar(res.data.data, "success");
                    GetEmployeeOnBoardingEquipmnetListList();
                }
                else {
                    snackbar.showSnackBar(res.data.data, "error");
                }
            })
            .catch(err => {
                handleCloseAddEditEmployeeOnBoardingPopup(false);
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error")
            })
    }

    const handleCloseAddEditEmployeeOnBoardingPopup = (isShowPopup: boolean) => {
        SetOpenAddEditEmployeeOnBoardingDetailsDialog(isShowPopup);
    }
    useEffect(() => {
        GetEmployeeOnBoardingEquipmnetListList();
    }, [])

    return (
        <>
            <div className='breadcumDiv'>
                {
                    uStore.getUser().role === "Administrator" ?
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/pending-request" title="Pending Request">
                                Pending Request
                            </NavLink>
                            <span className="active-breadcumb">Employee Onboarding Equipment</span>
                        </Breadcrumbs>) :
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/search" title="Make A Request">
                                Make A Request
                            </NavLink>
                            <span className="active-breadcumb">Employee Onboarding Equipment</span>
                        </Breadcrumbs>)
                }
            </div>
            <Navbar title="Employee Onboarding" />
            {
                isLoading ? (
                    <Loader />
                ) :
                    (
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid sx={{ m: "auto" }} item xs={8}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        maxWidth: "100%",
                                        minHeight: "70%",
                                        maxHeight: "100%",
                                        margin: 2,
                                        textAlign: "left",
                                    }}
                                >
                                    <Box sx={{ display: "flex", p: 2, justifyContent: "space-between", alignItems: "end" }}>
                                        <h1 style={{ fontWeight: "bold" }}>Employee Onboarding Equipment List</h1>
                                        <Button color="primary" sx={{ backgroundColor: "rgb(109, 135, 255)", marginBottom: 2, marginRight: 1 }} size="large" variant="contained"
                                            onClick={() => {
                                                let data = {
                                                    id: 0,
                                                    equipmentName: "",
                                                    equipmentImageURL: "",
                                                    itemCount: 0,
                                                    itemIds: "",
                                                    itemQuantity: 1,
                                                    isDeleted: false
                                                }
                                                setEmployeeOnBoardingEquipmnetDetails(data);
                                                SetOpenAddEditEmployeeOnBoardingDetailsDialog(true);
                                            }}
                                        >
                                            <AddIcon /> Add Employee Onboarding Equipment
                                        </Button>
                                    </Box>

                                    <Paper className='requestTableDiv' sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ minHeight: 500, maxHeight: 600 }}>
                                            <div className='ItemDataGridDiv'>
                                                <DataGrid
                                                    rows={employeeOnBoardingEquipmnetList}
                                                    columns={columns}
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: {
                                                                pageSize: 20,
                                                            },
                                                        },
                                                    }}
                                                    rowHeight={80}
                                                />
                                            </div>
                                            {/* <Table stickyHeader aria-label="sticky table" sx={{ fontWeight: "bold" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ minWidth: "130px" }}>Form Name</TableCell>
                                                        <TableCell style={{ minWidth: "20px" }}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {employeeOnBoardingEquipmnetList && employeeOnBoardingEquipmnetList
                                                        .map((list: IEmployeeOnBoardingList, index: Number) => {
                                                            return (
                                                                <TableRow key={Number(list.id)} hover tabIndex={-1}>
                                                                    <TableCell style={{ minWidth: "130px" }}>{list.equipmentName}</TableCell>
                                                                    <TableCell style={{ width: "20px" }}>
                                                                        <EditIcon
                                                                            onClick={() => {
                                                                                handleEdit(list.id);
                                                                            }}
                                                                            style={{ marginRight: 4 }} />
                                                                        <DeleteIcon
                                                                            onClick={() => { handleDelete(Number(list.id)); }} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table> */}
                                        </TableContainer>
                                    </Paper>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
            }

            <AddEditEmployeeOnBoardingDetails isOpenAddEditEmployeeOnBoardingDetailsDialog={OpenAddEditEmployeeOnBoardingDetailsDialog} AddEditEmployeeOnBoardingData={(id: number, categoryData: any) => { AddEditEmployeeOnBoardingData(id, categoryData); }} handleCloseAddEditEmployeeOnBoardingPopup={(isShowPopup: boolean) => { handleCloseAddEditEmployeeOnBoardingPopup(isShowPopup) }} itemDetails={employeeOnBoardingEquipmnetDetails} />
        </>
    )
}

export default EmployeeOnBoardingList