// import 'bootstrap/dist/css/bootstrap.css';
import "./App.css";
import "react-multi-carousel/lib/styles.css";
import "./assets/css/dev-style.css";
import SearchPage from "./pages/SearchPage";
import { Theme } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import RequestList from "./pages/RequestList";
import Login from "./components/account/Login";
import ExternalLogin from "./components/account/ExternalLogin";
import PendingRequests from "./pages/PendingRequests";
import HistoricalRequests from "./pages/HistoricalRequests";
import UserManagement from "./pages/UserManagement";
import ProtectedRoute from "./common/utils/ProtectedRoute";
import MsalAuth from "./components/account/MsalAuth";
import SetupInterceptors from "./common/http-interceptors-setup";
import useStore from "./store/store";
import CategoryList from "./pages/Category module/CategoryList";
import CategoryItemList from './pages/Item management module/CategoryItemList';
import FormList from './pages/Form Management module/FormList';
import AddEditForm from './pages/Form Management module/AddEditForm';
import ConfirmRequestPage from "./pages/ConfirmRequestPage";
import CartPage from "./pages/CartPage";
import EmployeeOnBoardingList from './pages/EmployeeOnBoarding/EmployeeOnBoardingList';
import HardwarePackagesRoleList from "./pages/HardwarePackagesforRolesModule/HardwarePackagesRoleList";
function App() {


  const styles = (theme: Theme) => ({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.4em'
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey'
      }
    }
  });

  SetupInterceptors(useStore());
  return (<>

    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate replace to="/account/external-login" />} />
            <Route path="/auth" element={<MsalAuth />} />
            <Route path="/account/login" element={<Login />} />
            <Route path="/account/external-login" element={<ExternalLogin />} />
            <Route path="/search" element={
              <ProtectedRoute><SearchPage /></ProtectedRoute>
            } />
          <Route path="/search:category" element={
            <ProtectedRoute><SearchPage /></ProtectedRoute>
          } />
            <Route path="/user-request-list" element={<ProtectedRoute><RequestList /></ProtectedRoute>} />
          <Route path="/confirm-request" element={<ProtectedRoute><ConfirmRequestPage /></ProtectedRoute>} />
          {/* <Route path="/confirm-request" element={<ProtectedRoute><ItemsFormStepper /></ProtectedRoute>} /> */}
            <Route path="/pending-request" element={<ProtectedRoute><PendingRequests /></ProtectedRoute>} />
            <Route path="/historical-request" element={<ProtectedRoute><HistoricalRequests /></ProtectedRoute>} />
            <Route path="/user-management" element={<ProtectedRoute><UserManagement /></ProtectedRoute>} />
          <Route path="/category-management" element={<ProtectedRoute><CategoryList /></ProtectedRoute>} />
          <Route path="/categoryitem-management" element={<ProtectedRoute><CategoryItemList /></ProtectedRoute>} />
          <Route path="/form-list" element={<ProtectedRoute><FormList /></ProtectedRoute>} />
          <Route path="/employee-onboarding-equipment" element={<ProtectedRoute><EmployeeOnBoardingList /></ProtectedRoute>} />
          <Route path="/hardware-package-for-role" element={<ProtectedRoute><HardwarePackagesRoleList /></ProtectedRoute>} />
          <Route path="/edit-form/:id" element={<ProtectedRoute><AddEditForm /></ProtectedRoute>} />
          <Route path="/cart" element={<ProtectedRoute><CartPage /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </div>
  </>
  );
}

export default App;
