import React from 'react'
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { IFormDetails } from '../../types/formType'
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { surveyJSON } from './survey';
import { themeJson } from '../../assets/css/CommonTheme';

interface props {
    formDetails?: IFormDetails | null
}
const options = {
    haveCommercialLicense: true,
    showThemeTab: true,
    //showLogicTab: true,
};
const FormBuilder: React.FC<props> = ({ formDetails }) => {
    const creator = new SurveyCreator(options);
    var localStorageName = "SavedForm";
    const initialJSON = (formDetails && formDetails.id !== 0) ? JSON.parse(formDetails.form) : surveyJSON;

    creator.JSON = initialJSON;
    creator.isAutoSave = true;
    creator.theme = themeJson;

    window.localStorage.setItem(localStorageName, creator.text);
    creator.saveSurveyFunc = function (saveNo: any, callback: any) {
        console.log(creator.text);
        window.localStorage.setItem(localStorageName, creator.text);
        callback(saveNo, true);
    };
    return (
        <div style={{ height: "75vh" }}>
            <SurveyCreatorComponent creator={creator} />
        </div>
    )
}

export default FormBuilder