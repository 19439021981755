import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, Box, DialogActions, Button } from '@mui/material';
import React, { useEffect } from 'react'
import InputCheckboxFormControl from '../../components/controls/InputCheckboxFormControl';
import InputFormControl from '../../components/controls/InputFormControl';
import InputTextAreaFormControl from '../../components/controls/InputTextAreaFormControl';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import InputPhoneNumberFormControl from './../../components/controls/InputPhoneNumberFormControl';

interface props {
    isOpenAddUpdateAddressDialog: boolean,
    AddUpdateShippingAddress: (addressDetails: any) => void,
    handleCloseShippingAddressPopup: (isShowPopup: boolean) => void,
}
const AddUpdateAddress = ({ isOpenAddUpdateAddressDialog, AddUpdateShippingAddress, handleCloseShippingAddressPopup }: props) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [CountryOption, setCountryOption] = React.useState<any>();
    const REGEX = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
    const validationSchema = Yup.object()
        .shape({
            fullName: Yup.string().required("Please enter full name"),
            phoneNumber: Yup.string().required("Please enter phon number").matches(REGEX, "Please enter phone number in this formate: 000-000-0000"),
            addressLine1: Yup.string().required("Please enter address"),
            addressLine2: Yup.string(),
            city: Yup.string().required("Please enter city"),
            country: Yup.string().required("Please enter country"),
            state: Yup.string().required("Please enter state"),
            pincode: Yup.string().required("Please enter pincode"),
            defaultAddress: Yup.boolean(),
        }).required();

    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleCloseShippingAddressPopup(false);
        // setValue("description", "");
        // setValue("image", "");
        // setValue("longDesc", "");
        // setValue("itemSubCategoryId", "");
        // setValue("hasForm", false);
        // setValue("formId", "");

    };

    const handleDialog = () => {
        setOpenDialog(isOpenAddUpdateAddressDialog);
    };

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const GetCountryOriginList = () => {

    }

    useEffect(() => {
        GetCountryOriginList();
        handleDialog();
    }, [isOpenAddUpdateAddressDialog]);


    return (
        <Dialog className='AddUserDialog' open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>Add delivery address</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(async (data: any, e: any) => {

                })}>
                    <DialogContentText sx={{ p: 1 }}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Country/Region</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputSelectFormControl
                                            errors={errors}
                                            control={control}
                                            name="itemSubCategoryId"
                                            label=""
                                            options={CountryOption}
                                        />
                                    </div>
                                </div>
                            </Grid> */}
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Full name (First and Last name)</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputFormControl
                                            name="fullName"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Phone number</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputPhoneNumberFormControl
                                            name="phoneNumber"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>
                                </div>
                                <span style={{ fontSize: "14px", fontStyle: "italic", paddingLeft: "10px" }}>May be used to assist delivery</span>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Address Line 1</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputTextAreaFormControl
                                            name="addressLine1"
                                            label=""
                                            errors={errors}
                                            control={control}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Address Line 2</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputTextAreaFormControl
                                            name="addressLine2"
                                            label=""
                                            errors={errors}
                                            control={control}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Town/City</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputFormControl
                                            name="city"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>State</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputFormControl
                                            name="state"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Country</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputFormControl
                                            name="country"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Pin Code</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputFormControl
                                            name="pinCode"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label></label>
                                    </div>
                                    <div className='col-8 InputCheckBoxControl'>
                                        <InputCheckboxFormControl
                                            errors={errors}
                                            control={control}
                                            name="defaultAddress"
                                            label="Make this my default address" />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    <DialogActions sx={{ p: 2 }}>
                        <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleCloseDialog}>Cancel</Button>
                        <Button type='submit' sx={{ fontSize: "20px" }} variant="contained">Add</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog >
    )
}

export default AddUpdateAddress