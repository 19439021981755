import {
    FormControl,
    FormHelperText,
    TextField,
} from "@mui/material";
import { Field } from "formik";
import React from "react";
import { Controller } from "react-hook-form";

interface InputProps {
    name: string;
    label: string;
    control: any;
    errors?: any;
    type: string;
    initialValue?: any;
}

const InputPhoneNumberFormControl: React.FC<InputProps> = (props) => {
    const { name, label, control, errors, type, initialValue } = props;
    const validatePhoneNumber = (value: any) => {
        // Check if the value is a valid US phone number
        const isValidPhoneNumber = /^\d{10}$/.test(value);

        return isValidPhoneNumber || 'Please enter a valid phone number';
    };
    return (
        <>
            {
                (
                    <FormControl {...props} error={Boolean(errors[name])}>
                        <Controller
                            rules={{
                                validate: validatePhoneNumber,
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...Field}
                                    InputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    sx={{ width: "100%" }}
                                    error={Boolean(errors[name])}
                                    id="outlined-required"
                                    type={type}
                                    label={label}
                                    placeholder="(000)-000-0000"
                                    onChange={onChange}
                                    defaultValue={value}
                                />
                            )}
                            name={name}
                            control={control}
                            defaultValue={initialValue}
                        />
                        {errors && <FormHelperText>{errors[name]?.message}</FormHelperText>}
                    </FormControl>
                )
            }
        </>
    );
};

export default InputPhoneNumberFormControl;
