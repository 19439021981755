import React, { useEffect, useState } from "react";
import { Breadcrumbs, Card, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import ResultListPage from "./ResultListPage";
import Navbar from "../components/Navbar";
import useStore from "../store/store";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate, useSearchParams } from "react-router-dom";
import MainScreenItemList from "../components/MainScreenItemList";
import { Icategory } from "../types/CategoryType";

const SearchPage = () => {

  const [searchText, setSearched] = useState("");
  const [searchQueryString, setSearchQueryString] = useState("");
  const [ItemList, setItemList] = useState<any>();
  const { allCategoryList, allItemList } = useStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const InputHandler = (e: any) => {
    setSearched(e.target.value);
    if (e.target.value.length === 0 && e.target.value === "")
      setSearchQueryString(e.target.value);
  };

  const onKeyDownHandler = (e: any) => {
    if (e.keyCode === 13 || e.key === "Enter") {
      setSearchQueryString(searchText);
    }
  };

  useEffect(() => {
    setSearchQueryString("");
    setSearched("");
    const handlePopstate = () => {
      setSearchQueryString("");
      setSearched("");
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    setSearchQueryString("");
    setSearched("");
    const param1 = searchParams.get('category');
    if (param1) {
      let item_list: any = [];
      allCategoryList.map((category_data: Icategory) => {
        if (category_data.categoryName.toLowerCase().replaceAll(" ", '_') === param1) {
          item_list = allItemList.filter(x => x.itemSubCategory.categoryName.toLowerCase().replaceAll(" ", '_') === param1);
          setItemList(item_list);
        }
      });
      if (item_list && item_list.length > 0) {
        setItemList(item_list);
      } 
      else {
        navigate("/search");
      }
    }
    else {
      setItemList(allItemList);
    }
  }, [searchParams, allItemList]);

  // useEffect(() => {
  //   // setItemList(ItemList);
  //   const param1 = searchParams.get('category');
  //   if (param1) {
  //     let item_list: any = [];
  //     allCategoryList.map((category_data: Icategory) => {
  //       if (category_data.categoryName.toLowerCase().replaceAll(" ", '_') === param1) {
  //         item_list = allItemList.filter(x => x.itemSubCategory.categoryName.toLowerCase().replaceAll(" ", '_') === param1);
  //         setItemList(item_list);
  //       }
  //     });
  //     if (item_list && item_list.length > 0) {

  //     }
  //     else {
  //       navigate("/search");
  //     }
  //   }
  // }, [ItemList]);

  return (
    <>
      <div className='breadcumDiv'>
        {
          (<Breadcrumbs
            separator={<ArrowForwardIosIcon />}
            aria-label="breadcrumb"
          >
            <span className="active-breadcumb"> Make A Request</span>
          </Breadcrumbs>)
        }

      </div>
      <Navbar title="TechHub" />
      <div className="searchBar">
        <Card
          sx={{
            minWidth: "85%",
            maxWidth: "100%",
            textAlign: "left",
            boxShadow: "none",
            background: "#E3E6E6"
          }}
        >

        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
          <OutlinedInput
                  type="text"
                  onChange={(e: any) => {
                    InputHandler(e);
                  }}
                  onKeyDown={(e: any) => {
                    onKeyDownHandler(e);
                  }}
            value={searchText}
                  placeholder="Search here"
                  endAdornment={
                    <InputAdornment position="end" sx={{ cursor: "pointer " }}>
                      <SearchSharpIcon
                        onClick={() => {
                          setSearchQueryString(searchText);
                        }}
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
        </Card >
      </div>
      {
        console.log("searchQueryString", searchQueryString, searchParams, ItemList)}
      {ItemList && (searchQueryString !== "" || searchParams.size > 0) && (searchQueryString.length > 0 || searchParams.size > 0) ? (
        <ResultListPage searchText={searchQueryString} ItemList={ItemList} />
              ) : (
          <div style={{ background: "#E3E6E6", minHeight: "80vh", paddingBottom: "1px" }}>
            {
              allCategoryList && allCategoryList.length > 0 ? (
                allCategoryList.map((category: Icategory) => {
                  return (
                    <MainScreenItemList categoryName={category.categoryName} />
                  )
                })
              ) : ("")
            }

            </div>
      )}

      {/* <Grid item xs={3}>
          <CartPage></CartPage>
        </Grid> */}
    </>
  );
};

export default SearchPage;
