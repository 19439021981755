import { AxiosResponse } from "axios";
import { CheckAppVersion, ExternalLoginRequest } from "../common/ApiEndPoints";
import instance from "../common/http.common";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";
const _baseUrl: string = `${_rootUrl}/ExternalLogin`;

const UserExternalLogin = async (data: any): Promise<AxiosResponse<any>> => {
  let res = await instance.post(_baseUrl + ExternalLoginRequest, data);
  return res.data;
};

const CheckLatestAppVersion = async (): Promise<AxiosResponse<any>> => {
  let res = await instance.get(_baseUrl + CheckAppVersion);
  return res.data;
};

export const LoginService = {
  UserExternalLogin,
  CheckLatestAppVersion,
};
