import { AxiosResponse } from "axios";
import instance from "../common/http.common";
import {
  AddItemIntoCart,
  AddMultipleItemIntoCart,
  ClearCart,
  GetCartListByUser,
  RemoveItemFromCart,
} from "../common/ApiEndPoints";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";
const _baseUrl: string = `${_rootUrl}/CartDetails`;

const AddToCatrItem = async (
  userEmail: string,
  cartItem: any
): Promise<AxiosResponse<any>> => {
  let data = {
    userEmail: userEmail,
    categoryItem: cartItem,
    formData: cartItem.formData,
    formDataWithTitle: cartItem.formDataWithTitle,
  };
  return await instance.post(_baseUrl + AddItemIntoCart, data);
};

const AddMultipleItemsintoCart = async (
  userEmail: string,
  listOfItems: any
): Promise<AxiosResponse<any>> => {
  let data = {
    userEmail: userEmail,
    listOfItems: listOfItems,
    formData: null,
  };
  return await instance.post(_baseUrl + AddMultipleItemIntoCart, data);
};

const GetAllCartItems = async (userMail: any): Promise<AxiosResponse<any>> => {
  return await instance.get(
    `${_baseUrl + GetCartListByUser}/?userEmailId=${userMail}`
  );
};

const RemoveToCatrItem = async (
  userEmail: string,
  categoryItem: any
): Promise<AxiosResponse<any>> => {
  let data = {
    cartId: categoryItem.cartId,
    userEmail: userEmail,
    categoryItem: categoryItem,
  };
  return await instance.delete(_baseUrl + RemoveItemFromCart, { data: data });
};

const ClearCartItems = async (
  userEmail: string
): Promise<AxiosResponse<any>> => {
  return await instance.delete(
    `${_baseUrl + ClearCart}/?userEmailId=${userEmail}`
  );
};
export const CartService = {
  AddToCatrItem,
  GetAllCartItems,
  RemoveToCatrItem,
  ClearCartItems,
  AddMultipleItemsintoCart,
};
