import React, { useEffect, useState } from 'react'
import { Box, Breadcrumbs, Card, CardContent, Grid, List } from '@mui/material';
import Navbar from '../components/Navbar'

import { ConvertDate, GetErrorMessageFromResponse, GetTime, ITCatalogOrderXMLCompomnent } from '../common/commonFunction';
import { useConfirmPopup } from '../components/context/confirmation-context';
import { useSnackBar } from '../components/context/snackbar-context';
import Loader from '../components/common components/Loader';
import PendingRequestThreeDots from '../components/PendingRequestThreeDots';
import { NavLink } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { userStore } from '../store/store';
import ShowNotes from '../components/ShowNotes';
import { UserRequestListService } from './../services/UserRequestListService';
import PendingRequestItemsKenbanMenu from './../components/PendingRequestItemsKenbanMenu';
import InfoIcon from '@mui/icons-material/Info';
import ShowItemFormData from '../components/modelPopup/ShowItemFormData';
import ApprovedIcon from '../assets/icon/Approved.png';
import DeniedIcon from '../assets/icon/Denied.png';
import NoteIcon from '../assets/icon/notes.png';

const PendingRequests = () => {
    const [isLoading, setIsloading] = useState(true);
    const [selectedRequestNotes, SetSelectedRequestNotes] = useState<any>([]);
    const [selectedItemFormDetails, SetSelectedItemFormDetails] = useState<any>([]);
    const [selectedItemDetails, SetselectedItemDetails] = useState<any>();
    const [selectedRequestId, SetselectedRequestId] = useState<any>();
    const [OpenShowNotePopup, SetOpenShowNotePopup] = useState(false);
    const [OpenShowItemDetailsPopup, SetOpenShowItemDetailsPopup] = useState(false);
    const [pendingRequests, setPendingRequests] = React.useState([]);
    const snackbar = useSnackBar();
    const confirmPopupService = useConfirmPopup();
    const uStore: any = userStore();

    const getPendingRequest = async () => {
        let res = await UserRequestListService.GetAllPendingRequestList(uStore.getUser().userEmail);
        if (res.data) {
            setPendingRequests(res.data.data);
            setIsloading(false);
        }
    }

    const handleCancelRequest = async (id: any) => {
        confirmPopupService.showPopup("Are you sure you want to cancel this request? You will not be able to recover it once it is cancelled.", () => { handleCanceledRequest(id) });
    }

    const handleCanceledRequest = async (id: any) => {
        let res = await UserRequestListService.CancelRequestList(id);
        if (res.data) {
            if (res.data.code === 200 && res.data.data === "success") {
                snackbar.showSnackBar('Your request has been canceled successfully.', "success");
                getPendingRequest();
            }
            else {
                snackbar.showSnackBar('Your request has not been cancele', "error");
            }
        }
        else {
            snackbar.showSnackBar('Internal server error.', "error");
        }
        confirmPopupService.hidePopup();
    }

    const handleCancelItemOfRequest = async (requestId: any, itemId: any) => {
        confirmPopupService.showPopup("Are you sure you want to cancel this item from request? You will not be able to recover it once it is cancelled.", () => { handleCanceledItemOfRequest(requestId, itemId) });
    }

    const handleCanceledItemOfRequest = async (requestId: any, itemId: any) => {
        let res = await UserRequestListService.CancelItemOfRequest(requestId, itemId);
        if (res.data) {
            if (res.data.code === 200 && res.data.data === "success") {
                snackbar.showSnackBar('Your item has been removed from request successfully.', "success");
                getPendingRequest();
            }
            else {
                snackbar.showSnackBar('Your item has not been removed from request.', "error");
            }
        }
        else {
            snackbar.showSnackBar('Internal server error.', "error");
        }
        confirmPopupService.hidePopup();
    }


    const addNotes = (requestId: any, noteText: string, handleCloseDialog: () => void) => {
        const userName = uStore.getUser().userEmail;
        UserRequestListService.AddNoteForRequest(requestId, noteText, userName)
            .then((res: any) => {
                if (res) {
                    handleCloseDialog();
                    if (res.data.code === 200 && res.data.data === "success") {
                        getPendingRequest();
                        snackbar.showSnackBar('Note has been added successfully.', "success");
                    }
                    else if (res.data.code === 404) {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else {
                        snackbar.showSnackBar('Note has not been added.', "error");
                    }
                }
            })
            .catch(err => {
                handleCloseDialog();
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error");
            })
    }

    const handleapproveRequest = async (requestWithItem: any, newStatus: any, createdUserEmail: string, CreatedUserName: string) => {
        confirmPopupService.showPopup("Are you sure you want to approve all items on this request?", () => { approveRequest(requestWithItem, newStatus, createdUserEmail, CreatedUserName) });
    }

    const approveRequest = (requestWithItem: any, newStatus: string, createdUserEmail: string, CreatedUserName: string) => {
        let ItemsWithGeneralHardwareRequest: any = [];
        let ItemsWithoutGeneralHardwareRequest: any = [];
        let new_description = "";

        if (requestWithItem.items && requestWithItem.items.length > 0) {
            requestWithItem.items.map((item: any) => {
                if (item.catagoryItemsform && (item.catagoryItemsform.formName === "General Hardware Request" || item.catagoryItemsform.templateID === "3476")) {
                    ItemsWithGeneralHardwareRequest.push(item);
                }
                else {
                    ItemsWithoutGeneralHardwareRequest.push(item);
                }
            });
        }

        if (ItemsWithoutGeneralHardwareRequest && ItemsWithoutGeneralHardwareRequest.length > 0) {
            ItemsWithoutGeneralHardwareRequest.map((item: any) => {

                let problemTypeValue = "";
                if (item.category.id === 1) {
                    problemTypeValue = "Hardware Request";
                }
                else if (item.category.id === 2) {
                    problemTypeValue = "Software Request";
                }
                else if (item.category.id === 4) {
                    problemTypeValue = "Employee Lifecycle";
                }

                const numWeeks = 2;
                const now = new Date();
                now.setDate(now.getDate() + numWeeks * 7);

                const SysAidpayload: any = {
                    info: [
                        { key: 'due_date', value: now.getTime().toString() },
                        { key: 'status', value: "2" },
                        { key: 'problem_type', value: problemTypeValue },
                        { key: 'description', value: item.description },
                        { key: 'CustomColumn93sr', value: CreatedUserName },
                        { key: 'request_user_name', value: CreatedUserName },
                        {
                            key: 'notes',
                            value: []
                        },
                    ],
                };

                if (item.formData) {
                    let formDataValue = JSON.parse(item.formData);
                    var formDataValueResultArrayObject: any = Object.keys(formDataValue).map(function (key) {
                        return { key: key, value: formDataValue[key] };
                    });
                    if (item.sysAidTemplateId) {
                        formDataValueResultArrayObject.map((obj: any) => {
                            SysAidpayload.info.push(obj);
                        })
                    }
                }



                item.SysAidDetails =
                {
                    sysAidPayload: JSON.stringify(SysAidpayload),
                    sysAidTemplateId: item.sysAidTemplateId
                };
            })
        }
        if (ItemsWithGeneralHardwareRequest && ItemsWithGeneralHardwareRequest.length > 0) {
            ItemsWithGeneralHardwareRequest.map((item: any) => {
                if (new_description === "") {
                    new_description += item.description;
                }
                else {
                    new_description += ", " + item.description;
                }
            })
        }
        if (ItemsWithGeneralHardwareRequest && ItemsWithGeneralHardwareRequest.length > 0) {
            ItemsWithGeneralHardwareRequest.map((item: any) => {

                let problemTypeValue = "Hardware Request";

                const numWeeks = 2;
                const now = new Date();
                now.setDate(now.getDate() + numWeeks * 7);
                const SysAidpayload: any = {
                    info: [
                        { key: 'due_date', value: now.getTime().toString() },
                        { key: 'status', value: "2" },
                        { key: 'problem_type', value: problemTypeValue },
                        { key: 'description', value: new_description },
                        { key: 'CustomColumn93sr', value: CreatedUserName },
                        { key: 'request_user_name', value: CreatedUserName },
                        {
                            key: 'notes',
                            value: []
                        },
                    ],
                };

                if (item.formData) {
                    let formDataValue = JSON.parse(item.formData);
                    var formDataValueResultArrayObject: any = Object.keys(formDataValue).map(function (key) {
                        return { key: key, value: formDataValue[key] };
                    });
                    if (item.sysAidTemplateId) {
                        formDataValueResultArrayObject.map((obj: any) => {
                            SysAidpayload.info.push(obj);
                        })
                    }
                }

                item.SysAidDetails =
                {
                    sysAidPayload: JSON.stringify(SysAidpayload),
                    sysAidTemplateId: item.sysAidTemplateId
                };
            })
        }

        const cXMlRequestPayload = ITCatalogOrderXMLCompomnent(requestWithItem.items, uStore.getUser().firstName + " " + uStore.getUser().lastName, uStore.getUser().userEmail);
        UserRequestListService.ChangeCurrentRequestStatus(requestWithItem, newStatus, uStore.getUser().userEmail, null, cXMlRequestPayload, ItemsWithGeneralHardwareRequest, ItemsWithoutGeneralHardwareRequest)
            .then((res: any) => {
                if (res) {
                    if (res.data.code === 200) {
                        getPendingRequest();
                        snackbar.showSnackBar(res.data.data, "success");
                    }
                    else if (res.data.code === 404) {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else if (res.data.code === 400) {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else {
                        snackbar.showSnackBar('All items of request has not been approved.', "error");
                    }
                    confirmPopupService.hidePopup();
                }
            }).catch(err => {
                confirmPopupService.hidePopup();
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error");
            })
    }

    const handleapproveItemOfRequest = async (requestId: any, item: any, newStatus: any, createdUserEmail: string, CreatedUserName: string) => {
        confirmPopupService.showPopup("Are you sure you want to approve this item?", () => { approveItemOfRequest(requestId, item, newStatus, createdUserEmail, CreatedUserName) });
    }
    const approveItemOfRequest = (requestId: any, item: any, newStatus: string, createdUserEmail: string, CreatedUserName: string) => {
        let items = [];
        items.push(item);

        let problemTypeValue = "";
        if (item.category.id === 1) {
            problemTypeValue = "Hardware Request";
        }
        else if (item.category.id === 2) {
            problemTypeValue = "Software Request";
        }
        else if (item.category.id === 4) {
            problemTypeValue = "Employee Lifecycle";
        }

        const numWeeks = 2;
        const now = new Date();
        now.setDate(now.getDate() + numWeeks * 7);

        const SysAidpayload: any = {
            info: [
                { key: 'due_date', value: now.getTime().toString() },
                { key: 'status', value: "2" },
                { key: 'problem_type', value: problemTypeValue },
                { key: 'description', value: item.description },
                { key: 'CustomColumn93sr', value: CreatedUserName },
                { key: 'request_user_name', value: CreatedUserName },
                {
                    key: 'notes',
                    value: []
                },
            ],
        };

        if (item.formData) {
            let formDataValue = JSON.parse(item.formData);
            var formDataValueResultArrayObject: any = Object.keys(formDataValue).map(function (key) {
                return { key: key, value: formDataValue[key] };
            });
            if (item.sysAidTemplateId) {
                formDataValueResultArrayObject.map((obj: any) => {
                    SysAidpayload.info.push(obj);
                })
            }
        }


        const cXMlRequestPayload = ITCatalogOrderXMLCompomnent(items, uStore.getUser().firstName + " " + uStore.getUser().lastName, uStore.getUser().userEmail);
        UserRequestListService.ChangeCurrentRequestItemStatus(requestId, item, newStatus, uStore.getUser().userEmail, null, cXMlRequestPayload, JSON.stringify(SysAidpayload))
            .then((res: any) => {
                if (res) {
                    if (res.data.code === 200 && res.data.data === "success") {
                        getPendingRequest();
                        snackbar.showSnackBar('Request item has been approved successfully.', "success");
                    }
                    else if (res.data.code === 404) {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else if (res.data.code === 400) {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else {
                        snackbar.showSnackBar('Request item has not been approved successfully.', "error");
                    }
                    confirmPopupService.hidePopup();
                }
            }).catch(err => {
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error");
                confirmPopupService.hidePopup();
            })
    }

    const deniedRequest = (requestWithItem: any, newStatus: string, deniedReason: string, handleCloseDialog: () => void) => {
        UserRequestListService.ChangeCurrentRequestStatus(requestWithItem, newStatus, uStore.getUser().userEmail, deniedReason)
            .then((res: any) => {
                if (res) {
                    handleCloseDialog();
                    if (res.data.code === 200 && res.data.data === "success") {
                        getPendingRequest();
                        snackbar.showSnackBar('All items of request has been denied successfully.', "success");
                    }
                    else if (res.data.code === 404) {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else if (res.data.code === 400) {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else {
                        snackbar.showSnackBar('All items of request has not been denied.', "error");
                    }
                }
            })
            .catch(err => {
                handleCloseDialog();
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error");
            })
    }

    const deniedItemOfRequest = (requestId: any, item: any, newStatus: string, deniedReason: string, handleCloseDialog: () => void) => {
        UserRequestListService.ChangeCurrentRequestItemStatus(requestId, item, newStatus, uStore.getUser().userEmail, deniedReason)
            .then((res: any) => {
                if (res) {
                    handleCloseDialog();
                    if (res.data.code === 200 && res.data.data === "success") {
                        getPendingRequest();
                        snackbar.showSnackBar('Request item has been denied successfully.', "success");
                    }
                    else if (res.data.code === 404) {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else if (res.data.code === 400) {
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                    else {
                        snackbar.showSnackBar('Request has not been denied successfully.', "error");
                    }
                }
            })
            .catch(err => {
                handleCloseDialog();
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error");
            })
    }
    const handleCloseShowNotePopup = (isShowPopup: boolean) => {
        SetOpenShowNotePopup(isShowPopup);
    }

    const handleCloseShowItemDetialsPopup = (isShowPopup: boolean) => {
        SetOpenShowItemDetailsPopup(isShowPopup);
    }

    const changeStartDateForNewEmployee = (date: any, formDetailsWithTitle: any, formData: any, selectedItemId: any, selectedRequestId: any) => {
        const newFormDetailsWithTitle = JSON.parse(formDetailsWithTitle);
        const updatedformDetailsWithTitle: any = { ...newFormDetailsWithTitle };
        const dateObj = new Date(date);
        const formattedDate = dateObj.toISOString().substr(0, 10);
        updatedformDetailsWithTitle["Start Date"] = formattedDate;

        const newFormData = JSON.parse(formData);
        const updateFormData: any = { ...newFormData };
        updateFormData["CustomColumn64sr"] = new Date(date).getTime().toString();

        UserRequestListService.UpdateStartDateIntoItemFormDetails(selectedRequestId, selectedItemId, updatedformDetailsWithTitle, updateFormData)
            .then((res: any) => {
                if (res) {
                    if (res && res.data.code === 200) {
                        handleCloseShowItemDetialsPopup(false);
                        snackbar.showSnackBar(res.data.data, "success");
                        getPendingRequest();
                    }
                    else {
                        handleCloseShowItemDetialsPopup(false);
                        snackbar.showSnackBar(res.data.data, "error");
                    }
                }
            })
            .catch(err => {
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error");
            })

    }


    useEffect(() => {
        getPendingRequest();
    }, [])

    return (
        <>
            <div className='breadcumDiv'>
                {
                    uStore.getUser().role === "Administrator" ?
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <span className="active-breadcumb">Pending Request</span>
                        </Breadcrumbs>) :
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/search" title="Make A Request">
                                Make A Request
                            </NavLink>
                            <span className="active-breadcumb">Pending Request</span>
                        </Breadcrumbs>)
                }

            </div>
            <Navbar title="Pending Request" />
            <Card
                sx={{
                    mt: 3,
                    p: 2,
                    minWidth: "100%",
                    maxWidth: "100%",
                    textAlign: "start",
                    boxShadow: "none",
                    pr: 0,
                }}>
                <CardContent>
                    {
                        isLoading ? (
                            <Loader />
                        ) :
                            (
                                    <List sx={{ margin: "auto", textAlign: "center", width: "95%" }}>
                                        <Grid container sx={{ gridAutoRows: "1fr" }} rowSpacing={2} spacing={3}>
                                            {
                                                pendingRequests && pendingRequests.length > 0 ? (
                                                pendingRequests.map((list: any) => {
                                                        return (
                                                            <Grid item xs={12} sm={6} md={6} lg={4} key={list.id} sx={{ mb: 3 }}>
                                                                <Card elevation={3} sx={{ pl: 2, pt: 2, pr: 2, height: "100%" }} className='PendingRequestItem'>
                                                                    <Box sx={{ display: "flex", mb: 3, justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div style={{ textAlign: "left", width: "75%" }}>
                                                                            <div style={{ marginBottom: "20px" }}>
                                                                                {
                                                                                    (list.behalfUserName) ?
                                                                                        (
                                                                                            <label className='PendingRequestUserDetails'>
                                                                                                Requested by {list.createdUserName} {list.behalfUserName !== " " ? `(on behalf of ${list.behalfUserName})` : ""}   on {ConvertDate(list.createdDate)} @ {GetTime(list.createdDate)}
                                                                                            </label>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <label className='PendingRequestUserDetails'>
                                                                                                Requested by {list.createdUserName} on {ConvertDate(list.createdDate)} @ {GetTime(list.createdDate)}
                                                                                            </label>
                                                                                        )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Box sx={{ display: "flex", mb: 3, justifyContent: "start", alignItems: "center" }}>
                                                                                {
                                                                                    ((list.request_Notes && list.request_Notes.length > 0) &&
                                                                                        (
                                                                                        <img onClick={() => {
                                                                                            SetOpenShowNotePopup(true);
                                                                                            SetSelectedRequestNotes(list.request_Notes);
                                                                                        }} className='NoteListIcon' src={NoteIcon} alt='' />
                                                                                        )
                                                                                    )}
                                                                                <PendingRequestThreeDots list={list}
                                                                                    deniedRequest={(requestWithItem, newStatus, deniedReason, handleCloseDialog) => { deniedRequest(requestWithItem, newStatus, deniedReason, handleCloseDialog) }}
                                                                                    approveRequest={(requestWithItem, newStatus, createdUserEmail, CreatedUserName) => { handleapproveRequest(requestWithItem, newStatus, createdUserEmail, CreatedUserName) }}
                                                                                    addNotes={(id, noteText, handleCloseDialog) => { addNotes(id, noteText, handleCloseDialog) }}
                                                                                    handleCancelRequest={(id) => handleCancelRequest(id)} />
                                                                            </Box>
                                                                        </div>
                                                                    </Box>
                                                                    <div>
                                                                        {
                                                                            list.items.map((item: any, index: number) => {
                                                                                return (
                                                                                    <>
                                                                                        <Box key={index} sx={{ display: "flex", mb: 1, justifyContent: "center", alignItems: "start" }}>
                                                                                            <div style={{ width: "100%" }}>
                                                                                            <div className='requestItemStatusDesc'>
                                                                                                {
                                                                                                    item.status === "Pending" &&
                                                                                                    (<span >
                                                                                                        <b>Status : </b> Pending
                                                                                                    </span>)
                                                                                                }
                                                                                                {
                                                                                                    item.status === "Approved" && (
                                                                                                            <Box sx={{ display: "flex", mb: 1, justifyContent: "center", alignItems: "center" }}>
                                                                                                                <img className='ApprovedDeniedIcon' src={ApprovedIcon} alt='' /> <span style={{ marginLeft: "10px" }}>
                                                                                                                <b> Approved </b>
                                                                                                            </span>
                                                                                                        </Box>
                                                                                                    )
                                                                                                }
                                                                                                {
                                                                                                    item.status === "Denied" && (
                                                                                                            <Box sx={{ display: "flex", mb: 1, justifyContent: "center", alignItems: "center" }}>
                                                                                                                <img className='ApprovedDeniedIcon' src={DeniedIcon} alt='' /> <span style={{ marginLeft: "10px" }}>
                                                                                                                <b> Denied :  </b> {item.deniedReason}
                                                                                                            </span>
                                                                                                        </Box>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                                {/* <div className="requestItemImage">
                                                                                                <img alt="" src={process.env.PUBLIC_URL + item.imageUrl}></img>
                                                                                            </div> */}
                                                                                            <div className="requestItemDesc">
                                                                                                <span>{item.description}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                                item.formDataWithTitle && (<InfoIcon
                                                                                                onClick={() => {
                                                                                                    SetOpenShowItemDetailsPopup(true);
                                                                                                        SetSelectedItemFormDetails(item.formDataWithTitle);
                                                                                                    SetselectedItemDetails(item);
                                                                                                    SetselectedRequestId(list.id)
                                                                                                }}
                                                                                                style={{ height: "40px", width: "30px" }} />)
                                                                                        }
                                                                                        <PendingRequestItemsKenbanMenu list={list} item={item}
                                                                                            deniedItemOfRequest={(requestId, item, newStatus, deniedReason, handleDeniedItemReasonCloseDialog) => { deniedItemOfRequest(requestId, item, newStatus, deniedReason, handleDeniedItemReasonCloseDialog) }}
                                                                                                approveItemOfRequest={(requestId, item, newStatus, createdUserEmail, CreatedUserName) => { handleapproveItemOfRequest(requestId, item, newStatus, createdUserEmail, CreatedUserName) }}
                                                                                            handleCancelItemOfRequest={(requestId, itemId) => handleCancelItemOfRequest(requestId, itemId)} />
                                                                                    </Box >
                                                                                        <hr style={{ marginBottom: "15px" }}></hr>
                                                                                    </>
                                                                                )

                                                                            })
                                                                        }
                                                                    </div>

                                                                    {/* <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                                                                        <Button sx={{ marginBottom: 2, marginRight: 1 }} size="large" variant="contained"
                                                                            onClick={() => {
                                                                                handleCompleteRequest(list.id);
                                                                            }}>Complete Request</Button>
                                                                    </div> */}

                                                                </Card>
                                                            </Grid>
                                                        )
                                                    })
                                                ) :
                                                    (
                                                        <label className='noDataFoundMessage'> No Pending Request Found</label>
                                                    )
                                            }
                                        </Grid>
                                </List>
                            )
                    }
                </CardContent>
            </Card>
            <ShowNotes requestNotes={selectedRequestNotes} isOpenDialog={OpenShowNotePopup} handleCloseShowNotePopup={(isShowPopup: any) => { handleCloseShowNotePopup(isShowPopup) }} />
            <ShowItemFormData selectedRequestId={selectedRequestId} selectedItemDetails={selectedItemDetails} changeStartDate={(date: any, formDetailsWithTitle: any, formData: any, selectedItemId: any, selectedRequestId: any) => changeStartDateForNewEmployee(date, formDetailsWithTitle, formData, selectedItemId, selectedRequestId)} formDetails={selectedItemFormDetails} isOpenDialog={OpenShowItemDetailsPopup} handleCloseShowItemDetialsPopup={(isShowPopup: any) => { handleCloseShowItemDetialsPopup(isShowPopup) }} />
        </>
    )
}

export default PendingRequests