import axios from "axios";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";

const defaultOptions = {
  baseUrl: _rootUrl,
  headers: {},
};

// Create instance
let axiosInstance = axios.create(defaultOptions);

export default axiosInstance;
