import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import React, { useContext } from "react";
import { createContext } from "react";


type InformationPopupContextActions = {
    showPopup: (text: string) => void;
};

interface InformationPopupContextProviderProps {
    children: React.ReactNode
}

const InformationPopupContext = createContext({} as InformationPopupContextActions);

const InformationPopupProvider: React.FC<InformationPopupContextProviderProps> = ({
    children,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>('');

    const showPopup = (text: string) => {
        setMessage(text);
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        <InformationPopupContext.Provider value={{ showPopup }}>
            {children}
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className='DialogTitleDiv' id="alert-dialog-title"><b>{"Information"}</b></DialogTitle>
                <DialogContent sx={{ marginTop: "10px" }}>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className="btn-list">
                        <Button
                            sx={{ mr: 2 }}
                            variant="outlined"
                            title="No"
                            onClick={() => handleClose()}
                        >
                            OK
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </InformationPopupContext.Provider>
    </>
    )
}


const useInformationPopup = (): InformationPopupContextActions => {
    const context = useContext(InformationPopupContext);

    if (!context) {
        throw new Error('useInformationPopup must be used within an InformationPopupProvider');
    }

    return context;
};

export { InformationPopupProvider, useInformationPopup };