import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Carousel from 'react-multi-carousel';
import { responsiveForPopup } from '../common/carouselCommonProperty';
import Add from "../assets/icon/Add.png";
import { ICatagoryItemListResult, ISuggestedItems } from '../types/categoryListType';
import { useSnackBar } from '../components/context/snackbar-context';
import { useCartListStore, userStore } from '../store/store';
import CategoryItemForm from '../components/modelPopup/CategoryItemForm';
import { useInformationPopup } from './context/information-context';

interface props {
    suggestiveItemList: ISuggestedItems[],
    isOpenSuggestedItemDialog: boolean,
    handleCloseSuggestedItemPopup: (showSuggestedItemPopup: boolean) => void,
    currentItemList: any
}


const SuggestiveSellItemList: React.FC<props> = ({ currentItemList, suggestiveItemList, isOpenSuggestedItemDialog, handleCloseSuggestedItemPopup }) => {
    const [showFormPopup, setShowFormPopup] = useState<boolean>(false);
    const [itemData, setItemData] = useState<any>();
    const [openDialog, setOpenDialog] = React.useState(false);
    const pStore: any = useCartListStore();
    const uStore: any = userStore();
    const infoPopup = useInformationPopup();
    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleCloseSuggestedItemPopup(false);
    };

    const handleCloseItemFormPopup = (showFormPopup: boolean) => {
        setShowFormPopup(showFormPopup);
    }

    const handleDialog = () => {
        setOpenDialog(isOpenSuggestedItemDialog);
    };
    const snackBar = useSnackBar();
    const AddToCartItem = async (itemDetails: ICatagoryItemListResult) => {
        itemDetails.formData = JSON.stringify(itemDetails.formData);

        if (await pStore.setCartList(uStore.user.userEmail, itemDetails)) {
            // snackBar.showSnackBar("Item has been added to your cart!", "success");
            infoPopup.showPopup("Item has been added to your cart !");
        }
        else
            snackBar.showSnackBar("Item has already in your cart.", "info");
        handleCloseItemFormPopup(false);
    }
    useEffect(() => {
        handleDialog();
    }, [isOpenSuggestedItemDialog])
    return (
        <>
            <Dialog className='AddUserDialog' open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>Suggested Additional Items</DialogTitle>
                <DialogContent>
                    <div>
                        <Carousel
                            showDots={false}
                            containerClass="carousel-with-custom-dots"
                            responsive={responsiveForPopup}
                            arrows={true}
                            partialVisible={true}
                            renderButtonGroupOutside={true}
                        >
                            {suggestiveItemList && suggestiveItemList.length > 0 ? (
                                suggestiveItemList.map((list: ISuggestedItems, i: number) => (
                                    <div className="listItemDiv" key={i}>
                                        <div className="subcategoryImage">
                                            <img alt="" src={process.env.PUBLIC_URL + list.imageUrl}></img>
                                        </div>
                                        <div className="subcategoryDesc">
                                            <div className="subcategoryImageDesc">
                                                <span>{list.description} </span>
                                            </div>
                                            <div className="subcategoryAddtoCart">
                                                <img alt=""
                                                    src={Add}
                                                    onClick={() => {

                                                            if (list.hasForm && list.catagoryItemsform) {
                                                                //if (false && list.catagoryItemsform) {
                                                                setItemData(list);
                                                                setShowFormPopup(true);
                                                            }
                                                            else {
                                                                if (pStore.setCartList(uStore.user.userEmail, list))
                                                                    // snackBar.showSnackBar("Item has been added to your cart!", "success");
                                                                    infoPopup.showPopup("Item has been added to your cart !");
                                                            }
                                                    }}
                                                ></img>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <label>Item not found</label>
                            )}
                        </Carousel>
                    </div>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button sx={{ fontSize: "20px" }} variant="contained" onClick={() => {
                        handleCloseDialog();
                    }}>Continue</Button>
                </DialogActions>
            </Dialog >
            <CategoryItemForm itemData={itemData} isOpenFormDialog={showFormPopup} handleCloseItemFormPopup={(showFormPopup) => { handleCloseItemFormPopup(showFormPopup) }} addToCartItem={(itemData) => AddToCartItem(itemData)} />
        </>
    )
}

export default SuggestiveSellItemList