/* eslint-disable react-hooks/rules-of-hooks */
import { Alert, AlertColor, Snackbar, SnackbarOrigin } from '@mui/material';
import React, { createContext, useContext, useState } from 'react'


type SnackBarContextActions = {
    showSnackBar: (text: string, typeColor: AlertColor) => void;
};

interface SnackBarContextProviderProps {
    children: React.ReactNode;
}

const SnackBarContext = createContext({} as SnackBarContextActions);

const SnackbarProvider: React.FC<SnackBarContextProviderProps> = ({
    children,
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [typeColor, setTypeColor] = useState<AlertColor>('success');
    const [anchorPosition, setAnchorPosition] = useState<SnackbarOrigin>({ vertical: 'bottom', horizontal: 'right' })


    const showSnackBar = (text: string, color: AlertColor) => {
        if (color === 'info') {
            setAnchorPosition({ vertical: 'bottom', horizontal: 'right' });
        }
        setMessage(text);
        setTypeColor(color);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <SnackBarContext.Provider value={{ showSnackBar }}>
                <Snackbar open={open} autoHideDuration={typeColor === 'success' ? 2000 : 5000}
                    anchorOrigin={anchorPosition} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={typeColor} sx={{ width: '100%', fontSize: "20px" }}>
                        {message}
                    </Alert>
                </Snackbar>
                {children}
            </SnackBarContext.Provider>
        </>
    )
}

const useSnackBar = () => {
    const context = useContext(SnackBarContext);

    if (!context) {
        throw new Error('useSnackBar must be used within an SnackBarProvider');
    }

    return context;
}
export { SnackbarProvider, useSnackBar }