import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { userStore } from '../store/store';

interface PendingRequestThreeDotsProps {
    list: any,
    handleCancelRequest: (id: any) => void,
    addNotes: (id: any, noteText: string, handleAddNoteCloseDialog: () => void) => void,
    approveRequest: (requestWithItem: any, currentStatus: string, createdUserEmail: string, CreatedUserName: string) => void
    deniedRequest: (requestWithItem: any, currentStatus: string, deniedReason: string, handleDeniedReasonCloseDialog: () => void) => void
}
const PendingRequestThreeDots: React.FC<PendingRequestThreeDotsProps> = (props) => {

    const [noteText, setNoteText] = useState<string>("");
    const [denyReasonText, setDenyReasonText] = useState<string>("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openAddNoteDialog, setOpenAddNoteDialog] = React.useState(false);
    const [openDeniedReasonDialog, setOpenDeniedReasonDialog] = React.useState(false);
    const open = Boolean(anchorEl);
    const uStore: any = userStore();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddNoteCloseDialog = () => {
        setOpenAddNoteDialog(false);
    };

    const handleDeniedReasonCloseDialog = () => {
        setOpenDeniedReasonDialog(false);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    props.list.isOwnRequest === false &&
                    (
                        <>
                            <MenuItem 
                                onClick={() => {
                                    props.approveRequest(props.list, "Approved", props.list.createdUserEmail, props.list.createdUserName);
                                }}>
                                Approve All
                            </MenuItem>
                            <MenuItem 
                                onClick={() => {
                                    setOpenDeniedReasonDialog(true);
                                    // props.deniedRequest(props.list.id, "Denied");
                                }}>
                                Deny All
                            </MenuItem>
                        </>
                    )
                }
                {
                    (uStore.getUser().role).toLowerCase() === "administrator" &&
                    (
                        <>
                            <MenuItem
                                disabled={props.list.request_Notes.length > 0 ? true : false}
                                onClick={() => {
                                    setOpenAddNoteDialog(true);
                                }}>
                                Add Note
                            </MenuItem>
                        </>
                    )
                }
                <MenuItem disabled={(props.list.status === "Approved" && (uStore.getUser().role).toLowerCase() !== "administrator") ? true : false} onClick={(e) => { debugger; props.handleCancelRequest(props.list.id); }}>
                    Cancel The Entire Request
                </MenuItem>
            </Menu>

            <Dialog className='AddNoteDialog' sx={{ width: "100%" }} open={openAddNoteDialog} onClose={handleAddNoteCloseDialog}>
                <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>Add Note</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ p: 1 }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="note"
                        label="Note"
                        type="text"
                        value={noteText}
                        onChange={(event) => { setNoteText(event?.target.value) }}
                        multiline
                        rows={5}
                        variant="outlined"
                    />
                    </DialogContentText>

                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleAddNoteCloseDialog}>Cancel</Button>
                    <Button sx={{ fontSize: "20px" }} variant="contained" onClick={() => { props.addNotes(props.list.id, noteText, handleAddNoteCloseDialog) }}>Add</Button>
                </DialogActions>
            </Dialog >

            <Dialog className='DeniedRequestDialog' sx={{ width: "100%" }} open={openDeniedReasonDialog} onClose={handleDeniedReasonCloseDialog}>
                <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>Denied Request Reason</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ p: 1 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="denyReason"
                            label="Deny Reason"
                            type="text"
                            value={denyReasonText}
                            onChange={(event) => { setDenyReasonText(event?.target.value) }}
                            multiline
                            rows={5}
                            variant="outlined"
                        />
                    </DialogContentText>

                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleDeniedReasonCloseDialog}>Cancel</Button>
                    <Button sx={{ fontSize: "20px" }} variant="contained" onClick={() => { props.deniedRequest(props.list, "Denied", denyReasonText, handleDeniedReasonCloseDialog) }}>Add</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default PendingRequestThreeDots