import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import InputFormControl from './controls/InputFormControl';
import InputCheckboxFormControl from './controls/InputCheckboxFormControl';

interface AddUserProps {
    isOpenDialog: boolean;
    userDetails: any;
    onAddUpdateUser: (id: any, emailId: any) => void;
    handleCloseAddUserPopup: (isShowPopup: boolean) => void
}
const AddUser: React.FC<AddUserProps> = (props) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleCloseDialog = () => {
        setOpenDialog(false);
        props.handleCloseAddUserPopup(false);
    };

    const handleDialog = () => {
        setOpenDialog(props.isOpenDialog);
    };
    const EMAIL_REGX = new RegExp(/.+@(psychmc|integratedtelehealth)\.com$/);
    const validationSchema = Yup.object()
        .shape({
            //email: Yup.string().email().required().matches(EMAIL_REGX, "Invalid email address. It support only psychmc.com email."),
            email: Yup.string().email().required("Please enter email address"),
            // firstName: Yup.string().required("First name is required"),
            // lastName: Yup.string().required("Last name is required"),
            // title: Yup.string().required("title is required"),
            // isAdmin: Yup.boolean(),
        }).required();

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (props.userDetails.id === 0) {
            setValue("email", "");
            // setValue("firstName", "");
            // setValue("lastName", "");
            // setValue("title", "");
        }
        else {
            setValue("email", props.userDetails.userEmail);
            // setValue("firstName", props.userDetails.firstName);
            // setValue("lastName", props.userDetails.lastName);
            // setValue("title", props.userDetails.designation);
            // setValue("isAdmin", props.userDetails.role == "Administrator" ? true : false);
        }

        handleDialog();
    }, [props.isOpenDialog])

    return (
        <>
            <Dialog className='AddUserDialog' open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>{props.userDetails.id === 0 ? "Add New" : "Update"}  User</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(async (data: any) => {
                        //  data.id = 0;
                        props.onAddUpdateUser(props.userDetails.id, data.email);
                    })}>
                        <DialogContentText sx={{ p: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className='row'>
                                        <div className="form-lable col-4">
                                            <label>User Email</label>
                                        </div>
                                        <div className='col-8'>
                                            <InputFormControl
                                                name="email"
                                                label=""
                                                errors={errors}
                                                control={control}
                                                type=""
                                                initialValue={""}
                                            />
                                        </div>

                                    </div>
                                </Grid>
                                {/* <Grid item xs={12} md={12}>
                                    <div className='row'>
                                        <div className="form-lable col-4">
                                            <label>First Name</label>
                                        </div>
                                        <div className='col-8'>
                                            <InputFormControl
                                                name="firstName"
                                                label=""
                                                errors={errors}
                                                control={control}
                                                type=""
                                                initialValue={""}
                                            />
                                        </div>

                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='row'>
                                        <div className="form-lable col-4">
                                            <label>Last Name</label>
                                        </div>
                                        <div className='col-8'>
                                            <InputFormControl
                                                name="lastName"
                                                label=""
                                                errors={errors}
                                                control={control}
                                                type=""
                                                initialValue={""}
                                            />
                                        </div>

                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='row'>
                                        <div className="form-lable col-4">
                                            <label>Title</label>
                                        </div>
                                        <div className='col-8'>
                                            <InputFormControl
                                                name="title"
                                                label=""
                                                errors={errors}
                                                control={control}
                                                type=""
                                                initialValue={""}
                                            />
                                        </div>

                                    </div>
                                </Grid>
                                {
                                    props.userDetails.role && (
                                        <Grid item xs={12} md={12}>
                                            <div className='row'>
                                                <div className="form-lable col-4">
                                                    <label></label>
                                                </div>
                                                <div className='col-8'>
                                                    <InputCheckboxFormControl
                                                        errors={errors}
                                                        control={control}
                                                        name="isAdmin"
                                                        label="Is user Administrator"
                                                    />
                                                </div>

                                            </div>
                                        </Grid>
                                    )
                                } */}
                            </Grid>
                        </DialogContentText>
                        <DialogActions sx={{ p: 2 }}>
                            <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleCloseDialog}>Cancel</Button>
                            <Button type='submit' sx={{ fontSize: "20px" }} variant="contained" >{props.userDetails.id === 0 ? "Add" : "Update"}</Button>
                        </DialogActions>
                    </form>
                </DialogContent>

            </Dialog >
        </>
    )
}

export default AddUser