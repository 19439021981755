import { AxiosResponse } from "axios";
import instance from "../../common/http.common";
import {
  AddRemoveHardwareItemsForRole,
  GetEmployeeOnboardingEquipmentList,
  GetEmployeeRolesList,
} from "../../common/ApiEndPoints";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";
const _baseUrl: string = `${_rootUrl}/HardwarePackagesForRole`;

const GetEmployeeRoleList = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + GetEmployeeRolesList);
};

const GetSpecificEmployeeRole = async (
  roleName: string
): Promise<AxiosResponse<any>> => {
  return await instance.get(
    _baseUrl + `/GetSpecificEmployeeRole?role=${roleName}`
  );
};
const addRemoveHardwareItem = async (
  id: number,
  ItemIds: string
): Promise<AxiosResponse<any>> => {
  let data = {
    id: id,
    listOfItemIds: ItemIds,
  };
  return await instance.post(_baseUrl + AddRemoveHardwareItemsForRole, data);
};

export const HardwarePackagesRoleService = {
  GetEmployeeRoleList,
  addRemoveHardwareItem,
  GetSpecificEmployeeRole,
};
