import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react'
import { useAuth } from '../context/auth-context';
import { useSnackBar } from '../context/snackbar-context';
import { useNavigate } from 'react-router-dom';
import useStore, { userStore } from '../../store/store';
import { LoginService } from '../../services/LoginService';
import { loginRequest } from '../../msal.config';
import { responsive } from './../../common/carouselCommonProperty';
import axios from 'axios';

const MsalAuth = () => {
    const { instance, accounts } = useMsal();
    const { setIsLoginRequired, reset } = useStore();
    const auth: any = useAuth();
    const snackBar = useSnackBar();
    const navigate = useNavigate();
    const uStore: any = userStore();

    useEffect(() => {
        if (accounts.length && uStore.isSSOlLoginInProgress) {
            const accessTokenRequest = {
                scopes: loginRequest.scopes,
                account: accounts[0],
            };
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // Acquire token silent success
                    let accessToken = accessTokenResponse.accessToken;
                    return accessToken;
                })
                .then(async (accessToken) => {
                    const accessTokenForGraphAPi = await instance.acquireTokenSilent({
                        scopes: ['User.Read.All'],
                        account: accounts[0],
                    });

                    const userDetialsFromAzurAd = await axios.get(`https://graph.microsoft.com/v1.0/users/${accounts[0].username}?$expand=manager`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': 'Bearer ' + accessTokenForGraphAPi.accessToken
                        }
                    })

                    if (userDetialsFromAzurAd) {
                        let managerId = null;
                        if (userDetialsFromAzurAd.data.manager) {
                            managerId = userDetialsFromAzurAd.data.manager.mail;
                        }
                        return LoginService.UserExternalLogin({
                            accessToken,
                            userEmail: userDetialsFromAzurAd.data.mail,
                            firstName: userDetialsFromAzurAd.data.givenName,
                            lastName: userDetialsFromAzurAd.data.surname,
                            title: userDetialsFromAzurAd.data.jobTitle,
                            managerEmailId: managerId
                        })
                    }
                })
                .then((response: any) => {
                    if (response.data) {
                        if (auth.signin(response.data)) {
                            uStore.setIsSSOlLoginInProgress(false);
                            setIsLoginRequired(false);
                            const user = uStore.getUser();
                            if (user.role === "Administrator") {
                                navigate('/pending-request');
                            }
                            else {
                                navigate('/search');
                            }
                            snackBar.showSnackBar("Logged in successfully", "success");
                        } else {
                            snackBar.showSnackBar("Something Went Wrong!.", "error")
                            navigate({
                                pathname: "/account/external-login"
                            })
                        }
                    }
                })
                .catch((error) => {
                    snackBar.showSnackBar("Login with azure PMC tenant failed. Please use your credentials to login.", "error")
                    navigate({
                        pathname: "/account/external-login"
                    })
                });
        }
        if (accounts.length && !uStore.isSSOlLoginInProgress) {
            reset();
            uStore.setUser(undefined);
            uStore.reset();
            localStorage.clear();
            navigate({
                pathname: "/account/external-login"
            })
        }
    }, [accounts]);

    return (
        <>
            <div>
                <h1>SSO Loging In-progress</h1>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                </MsalAuthenticationTemplate>
            </div >
        </>
    )
}

export default MsalAuth