import React, { useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, Box, FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import InputFormControl from '../../components/controls/InputFormControl';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import InputTextAreaFormControl from '../../components/controls/InputTextAreaFormControl';
import InputSelectFormControl from '../../components/controls/InputSelectFormControl';
import { CategoryService } from '../../services/CategoryModule/CategoryService';
import InputCheckboxFormControl from '../../components/controls/InputCheckboxFormControl';
import { CategoryItemFormService } from '../../services/CategoryItemFormModule/CategoryItemFormService';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import InputMultiSelectFormControl from '../../components/controls/InputMultiSelectFormControl';

interface props {
    isOpenAddUpdateCategoryItemDialog: boolean,
    AddUpdateCategoryItemData: (id: number, categoryData: any) => void,
    handleCloseAddUpdateCategoryItemPopup: (isShowPopup: boolean) => void,
    categoryItem: any,
    ItemList: any
}

const AddUpdateCategoryItems: React.FC<props> = ({ ItemList, isOpenAddUpdateCategoryItemDialog, AddUpdateCategoryItemData, handleCloseAddUpdateCategoryItemPopup, categoryItem }) => {
    const [isUpdateImage, setIsUpdateImage] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [CategoryOption, setCategoryOption] = React.useState<any>();
    const [ItemFormOption, setItemFormOption] = React.useState<any>();
    const [SuggestiveSellItemOptions, setSuggestiveSellItemOptions] = React.useState<any>();

    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

    const validationSchema = Yup.object()
        .shape({
            description: Yup.string().required("Category Item name/description is required"),
            longDesc: Yup.string(),
            itemSubCategoryId: Yup.string().required("Please select sub category"),
            image: Yup.mixed().nullable()
                .when('itemSubCategoryId', ([itemSubCategoryId], schema) => {
                    return itemSubCategoryId !== "1" ? schema.required("Please select image for category item").test('fileType', "Please select image formate file",
                        (value: any) => { return (value !== "" && categoryItem.id === 0) ? SUPPORTED_FORMATS.includes(value[0].type) : (categoryItem.id === 0 ? false : true) })
                        : schema;
                }),
            hasForm: Yup.boolean(),
            formId: Yup.string().when('hasForm', ([hasForm], schema) => {
                return hasForm ? schema.required("Please select form") : schema;
            }),
            SuggestiveCategoryItemID: Yup.array(),
            partID: Yup.string().when('itemSubCategoryId', ([itemSubCategoryId], schema) => {
                return itemSubCategoryId === "1" ? schema.required("Part Id is required") : schema;
            }),
            price: Yup.string(),
            uom: Yup.string(),
            shortDescription: Yup.string()
        }).required();


    const {
        handleSubmit,
        control,
        register,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });



    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleCloseAddUpdateCategoryItemPopup(false);
        // setValue("description", "");
        // setValue("image", "");
        // setValue("longDesc", "");
        // setValue("itemSubCategoryId", "");
        // setValue("hasForm", false);
        // setValue("formId", "");

    };

    const handleDialog = () => {
        setOpenDialog(isOpenAddUpdateCategoryItemDialog);
    };



    const showFormField = watch('hasForm', false);
    const getItemFormOptions = async () => {
        let res = await CategoryItemFormService.getCategoryItemFormList();
        let opt: any = [];
        if (res && res.data.code === 200) {
            res.data.data.map((item: any) => {
                opt.push({ label: item.formName, value: Number(item.id) })
            })
            setItemFormOption(opt);
        }
    }
    const getSuggestiveSellItemOptions = async () => {
        let opt: any = [];

        ItemList.map((item: any) => {
            // opt.push({ label: { name: item.description, image: item.imageUrl }, value: Number(item.id) })
            opt.push({
                label: (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "40px", height: "35px", borderRadius: "0", marginRight: "20px" }}>
                            <img
                                alt=''
                                src={item.imageUrl}
                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            />
                        </div>
                        {item.description}
                    </Box>
                ), value: Number(item.id)
            })
        })

        setSuggestiveSellItemOptions(opt);
    }
    const getCategoryOptions = async () => {
        let res = await CategoryService.getCategory();
        let opt: any = [];
        if (res && res.data.code === 200) {
            res.data.data.map((catgory: any) => {
                opt.push({ label: catgory.categoryName, value: Number(catgory.id) })
            })
            setCategoryOption(opt);
        }
    }

    useEffect(() => {
        getCategoryOptions();
        getItemFormOptions();
        getSuggestiveSellItemOptions();
        handleDialog();
    }, [isOpenAddUpdateCategoryItemDialog]);

    useEffect(() => {
        if (categoryItem.id === 0) {
            setIsUpdateImage(false);
            setValue("description", "");
            setValue("image", "");
            setValue("longDesc", "");
            setValue("itemSubCategoryId", "");
            setValue("hasForm", false);
            setValue("formId", "");
            setValue("SuggestiveCategoryItemID", []);
            setValue("partID", "");
            setValue("price", "");
            setValue("uom", "");
            setValue("shortDescription", "");
        }
        else {
            setIsUpdateImage(true);
            setValue("description", categoryItem.description);
            setValue("image", "");

            if (categoryItem.longDesc) {
                setValue("longDesc", categoryItem.longDesc);
            }
            else {
                setValue("longDesc", "");
            }

            if (categoryItem.partID) {
                setValue("partID", categoryItem.partID);
            }
            else {
                setValue("partID", "");
            }

            if (categoryItem.price) {
                setValue("price", categoryItem.price);
            }
            else {
                setValue("price", "");
            }

            if (categoryItem.uom) {
                setValue("uom", categoryItem.uom);
            }
            else {
                setValue("uom", "");
            }

            if (categoryItem.shortDescription) {
                setValue("shortDescription", categoryItem.shortDescription);
            }
            else {
                setValue("shortDescription", "");
            }
            if (categoryItem.itemSubCategory) {
                setValue("itemSubCategoryId", categoryItem.itemSubCategory.id);
            }
            else {
                setValue("itemSubCategoryId", "");
            }
            if (categoryItem.catagoryItemsform) {
                setValue("formId", categoryItem.catagoryItemsform.id);
            }
            else {
                setValue("formId", "");
            }
            if (categoryItem.suggestiveItems && categoryItem.suggestiveItems.length > 0) {
                setValue("SuggestiveCategoryItemID", categoryItem.suggestiveItems);
            }
            else {
                setValue("SuggestiveCategoryItemID", []);
            }
            setValue("hasForm", categoryItem.hasForm);
        }
    }, [categoryItem])

    const onHandlePriceChange = (name: any, value: any) => {
        const amount = value
            .replace(/[^.\d]/g, "")
            .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
        if (!amount) {
            setValue(name, amount);
            return amount;
        }
        setValue(name, amount);
    };

    return (
        <Dialog sx={{ minHeight: "100%", maxHeight: "90vh" }} className='AddUserDialog' open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>{Number(categoryItem.id) === 0 ? "Add New" : "Update"} Item</DialogTitle>
            <DialogContent sx={{ overflowY: "visible" }}>
                <form onSubmit={handleSubmit(async (data: any, e: any) => {
                    data.image = data.image[0];
                    data.isdeleted = categoryItem.is_deleted;
                    data.formId = data.hasForm ? data.formId : "";
                    data.SuggestiveCategoryItemID.map((item: any) => {
                        delete item.label;
                        return item.value;
                    })

                    AddUpdateCategoryItemData(categoryItem.id, data);
                })}>
                    <DialogContentText sx={{ p: 1 }}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Part ID</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputFormControl
                                            name="partID"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Item Name</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputFormControl
                                            name="description"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Item Image</label>
                                    </div>
                                    {
                                        categoryItem.id === 0 ? (<div className='col-8'>
                                            <InputFormControl
                                                name="image"
                                                label=""
                                                errors={errors}
                                                control={control}
                                                type="file"
                                            />
                                        </div>) : (<><Box sx={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ minWidth: "50px" }} ><img
                                                alt=''
                                                src={categoryItem.imageUrl}
                                                    style={{ width: "75px", height: "70px", borderRadius: "0", marginRight: "20px" }}
                                            /></div>
                                                {
                                                    (isUpdateImage && categoryItem.id !== 0) ? (<EditIcon
                                                        onClick={() => {
                                                            setIsUpdateImage(false);
                                                        }}
                                                        style={{ marginLeft: "20px", height: "25px", width: "25px" }} />) : (
                                                        <><InputFormControl
                                                            name="image"
                                                            label=""
                                                            errors={errors}
                                                            control={control}
                                                            type="file"
                                                        />
                                                            <CloseIcon onClick={() => {
                                                                setIsUpdateImage(true);
                                                            }}
                                                                style={{ height: "25px", width: "25px", cursor: "pointer" }} /></>)
                                                }
                                            </Box>
                                            </>)
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Item Short Description</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputTextAreaFormControl
                                            name="shortDescription"
                                            label=""
                                            errors={errors}
                                            control={control}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Item Long Description</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputTextAreaFormControl
                                            name="longDesc"
                                            label=""
                                            errors={errors}
                                            control={control}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Item Category</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputSelectFormControl
                                            errors={errors}
                                            control={control}
                                            name="itemSubCategoryId"
                                            label=""
                                            options={CategoryOption}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Price</label>
                                    </div>
                                    <div className='col-8'>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(errors["price"])}
                                        >
                                            <OutlinedInput
                                                {...register("price")}
                                                name="remainingDeductible "
                                                id="outlined-adornment-amount"
                                                type="text"
                                                onChange={(e: any) =>
                                                    onHandlePriceChange("price", e.target.value)
                                                }
                                                defaultValue={"price"}
                                                startAdornment={
                                                    <InputAdornment position="start">$</InputAdornment>
                                                }
                                                label=""
                                            />
                                        </FormControl>
                                    </div>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Unit Of Measurement</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputFormControl
                                            name="uom"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label></label>
                                    </div>
                                    <div className='col-8 InputCheckBoxControl'>
                                        <InputCheckboxFormControl
                                            errors={errors}
                                            control={control}
                                            name="hasForm"
                                            label="Has Form"
                                        />
                                    </div>
                                </div>
                            </Grid>
                            {
                                showFormField &&
                                (
                                    <Grid item xs={12} md={8}>
                                        <div className='row'>
                                            <div className="form-lable col-4">
                                                <label>Form Name</label>
                                            </div>
                                            <div className='col-8'>
                                                <InputSelectFormControl
                                                    errors={errors}
                                                    control={control}
                                                    name="formId"
                                                    label=""
                                                    options={ItemFormOption}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Suggestive Sell</label>
                                    </div>
                                    <div className='col-8 InputMultiSelectControl'>
                                        <InputMultiSelectFormControl
                                            errors={errors}
                                            control={control}
                                            name="SuggestiveCategoryItemID"
                                            label=""
                                            options={SuggestiveSellItemOptions}
                                            defaultValue={categoryItem.suggestiveItems}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    <DialogActions sx={{ p: 2 }}>
                        <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleCloseDialog}>Cancel</Button>
                        <Button type='submit' sx={{ fontSize: "20px" }} variant="contained">{Number(categoryItem.id) === 0 ? "Add" : "Update"}</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog >
    )
}

export default AddUpdateCategoryItems