import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React, { createContext, useContext, useRef, useState } from 'react'
import { MetroSpinner } from "react-spinners-kit";


type LoaderContextActions = {
    showLoader: () => void;
    hideLoader: () => void;
};

interface LoaderContextProviderProps {
    children: React.ReactNode
}
const LoaderContext = createContext({} as LoaderContextActions);

const LoaderProvider: React.FC<LoaderContextProviderProps> = ({
    children,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const resolver = useRef<() => void>();

    const showLoader = () => {
        setLoading(true)
    };

    const hideLoader = () => {
        setLoading(false);
    }

    return (<>
        <LoaderContext.Provider value={{ showLoader, hideLoader }}>
            {children}
            <div className="spinnerContainer">
                <div className="spinner">
                    <MetroSpinner size={50} color="#16817c" loading={loading} />
                </div>
            </div>
        </LoaderContext.Provider >
    </>
    )
}

const useLoaderContext = (): LoaderContextActions => {
    const context = useContext(LoaderContext);

    if (!context) {
        throw new Error('useConfirmPopup must be used within an ConfirmPopupProvider');
    }

    return context;
};

export { LoaderProvider, useLoaderContext };