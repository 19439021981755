import React from 'react'
import { Autocomplete, FormControl, FormHelperText, InputLabel, TextField, createFilterOptions } from '@mui/material';
import { Controller } from 'react-hook-form';
import Select, { Props as ReactSelectProps } from 'react-select';

const filter = createFilterOptions<any>();
interface SelectDropDownProps {
    label: string;
    options: any;
    onChange?: ReactSelectProps<any, true>['onChange'];
    placeholder?: string;
    selectedValues?: any;
    errors?: any;
    name: string;
    control: any;
    defaultValue?: any
}

const InputSelectControlWithSearch: React.FC<SelectDropDownProps> = (props) => {
    const { name, label, control, options, errors, onChange, placeholder, selectedValues, defaultValue } = props;
    const [value, setValue] = React.useState<any>(null);
    const [serchstr, setSerchStr] = React.useState<any>(null);

    return (
        <>
            <InputLabel>{label}</InputLabel>
            <FormControl error={Boolean(errors[name])}>

            <Controller
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={options}
                        id="controlled-demo"
                            value={value || null}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.label;
                            }}
                        onChange={(event: any, newValue: any | null) => {
                            if (typeof newValue === 'string') {
                                setValue({
                                    title: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                setValue({
                                    title: newValue.inputValue,
                                });
                            } else {
                                setValue(newValue);
                            }
                            field.onChange(newValue?.value)
                            setValue(newValue);
                        }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some((option) => inputValue === option.label);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        value: inputValue,
                                        label: inputValue,
                                    });
                                }
                                return filtered;
                            }}
                            freeSolo
                        renderInput={(params) => (
                                <TextField {...params} variant="outlined" onChange={(e) => { }} />
                            )}  
                    />
                )}
                name={name}
                control={control}
                defaultValue={selectedValues}
            />
            {errors && <FormHelperText>{errors[name]?.message}</FormHelperText>}
            </FormControl>
        </>
    )
}

export default InputSelectControlWithSearch