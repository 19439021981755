import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import useStore from '../../store/store';
import { ICatagoryItemListResult } from '../../types/categoryListType';

interface props {
    isOpenAddRemoveHardwareItemDialog: boolean,
    AddRemoveHardwareItems: (id: number, selectedHardwareList: any) => void,
    handleCloseAddRemoveHardwareItemPopup: (isShowPopup: boolean) => void,
    employeeRolePackages: any
}

const AddRemoveHardwareItemsInPackage: React.FC<props> = ({ isOpenAddRemoveHardwareItemDialog, handleCloseAddRemoveHardwareItemPopup, AddRemoveHardwareItems, employeeRolePackages }) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [searchStr, setSearchStr] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const { allItemList } = useStore();
    const [hardwareOption, setHardwareOption] = React.useState<any>();
    const [AllHardwareList, setAllHardwareList] =
        useState<ICatagoryItemListResult[]>([]);

    const [SelectedHardwareList, setSelectedHardwareList] = useState<any>(null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleCloseAddRemoveHardwareItemPopup(false);
    };

    const handleDialog = () => {
        setOpenDialog(isOpenAddRemoveHardwareItemDialog);
    };

    const GetHardwareList = () => {
        if (allItemList && allItemList.length > 0) {
            let tempHardwareList = allItemList.filter(x => x.itemSubCategory.categoryName === "Hardware and Devices");
            setAllHardwareList(tempHardwareList);
            let opt: any = [];
            tempHardwareList.map((item: any) => {
                opt.push({
                    label: (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <div style={{ width: "40px", height: "35px", borderRadius: "0", marginRight: "20px" }}>
                                <img
                                    alt=''
                                    src={item.imageUrl}
                                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                />
                            </div>
                            {item.description}
                        </Box>
                    ), value: Number(item.id)
                })
            })
            setHardwareOption(opt);
        }
    }

    const validationSchema = Yup.object()
        .shape({
            //  categoryName: Yup.string().required("Category Name is required")
        }).required();

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        handleDialog();
        GetHardwareList();
    }, [isOpenAddRemoveHardwareItemDialog]);

    useEffect(() => {
        if (employeeRolePackages) {
            let itemIdsList: any = employeeRolePackages.itemIds.split(",");
            let SelectedItemsList: any = [];
            itemIdsList.map((id: any) => {
                const temp_obj = allItemList.find((x: ICatagoryItemListResult) => x.id === Number(id));
                if (temp_obj) {
                    SelectedItemsList.push(temp_obj);
                }
            });
            setSelectedHardwareList(SelectedItemsList);
        }
    }, [employeeRolePackages]);

    useEffect(() => {
        setSelectedHardwareList(SelectedHardwareList);
    }, [SelectedHardwareList]);

    return (
        <Dialog className='AddUserDialog' open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle className='DialogTitleDiv' sx={{ fontSize: "20px", fontWeight: "bold" }}>Add/Remove Hardware Items</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(async (data: any) => {
                    AddRemoveHardwareItems(employeeRolePackages.id, SelectedHardwareList);
                })}>
                    <DialogContentText sx={{ p: 1 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={12}>
                                <div className='row'>

                                    <div className='col-8'>
                                        <Autocomplete
                                            options={hardwareOption}
                                            id="controlled-demo"
                                            value={selectedItem}
                                            clearOnBlur={true}
                                            getOptionLabel={(option: any) => option.label}
                                            onChange={(event: any, newValue: any | null) => {
                                                setSearchStr("")
                                                //setSelectedItem(null);

                                                let selectedItem = allItemList.filter(x => x.id === newValue.value)[0];
                                                if (SelectedHardwareList !== null) {
                                                    setSelectedHardwareList([...SelectedHardwareList, selectedItem]);
                                                } else {
                                                    setSelectedHardwareList([selectedItem]);
                                                }
                                                event.preventDefault();
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" placeholder="Select Items Here....." value={searchStr} onChange={e => setSearchStr(e.target.value)} />
                                            )}
                                            filterOptions={(options, state) => {
                                                const inputValue = state.inputValue.toLowerCase()
                                                return options.filter((option: any) =>
                                                    option.label.props.children[1].toLowerCase().includes(inputValue)
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            {
                                SelectedHardwareList && SelectedHardwareList.length > 0 &&
                                <Grid item xs={12} md={12}>
                                    <div className='showHardwareListdiv'>
                                        <Stack direction="column" spacing={1} sx={{ maxHeight: "60vh", overflowY: "scroll" }}>
                                            {
                                                SelectedHardwareList.map((item: ICatagoryItemListResult) => {
                                                    return (
                                                        <Chip
                                                            sx={{ textAlign: "left" }}
                                                            label={item.description}
                                                            avatar={<img src={process.env.PUBLIC_URL + item.imageUrl} alt="" />}
                                                            onDelete={() => {
                                                                let filteredItems = SelectedHardwareList.filter((x: any) => x.id !== item.id);
                                                                setSelectedHardwareList(filteredItems);
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </Stack>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </DialogContentText>
                    <DialogActions sx={{ p: 2 }}>
                        <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleCloseDialog}>Cancel</Button>
                        <Button sx={{ fontSize: "20px" }} variant="contained" type="submit" >Save</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog >
    )
}

export default AddRemoveHardwareItemsInPackage