import { FormControl, FormHelperText, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

interface InputProps {
    name: string;
    label: string;
    control: any;
    errors?: any;
    type: string;
    initialValue?: any;
}

const InputDateControl: React.FC<InputProps> = (props) => {
    const { name, label, control, errors, type, initialValue } = props;
    return (
        <>
            <FormControl
                sx={{ width: "100%" }}
                {...props}
                error={Boolean(errors[name])}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={initialValue}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                sx={{
                                    width: "100%",
                                }}
                                slotProps={{
                                    textField: {
                                        InputProps: {
                                            sx: {
                                                height: "55px",
                                            },
                                        },
                                        variant: "outlined",
                                        error: !!errors[name],
                                        helperText: errors ? errors[name]?.message : null,
                                    },
                                }}
                                value={initialValue ? dayjs(initialValue) : value}
                                onChange={(e: any) => {
                                    onChange(e);
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </FormControl>
        </>
    );
};

export default InputDateControl;
