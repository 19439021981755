import { AppBar, Button, Card, CardActions, CardContent, Grid, IconButton, Paper, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import InputFormControl from '../controls/InputFormControl';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

const Login = () => {
    const navigate = useNavigate();

    const validationSchema = Yup.object()
        .shape({

        }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });



    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Box sx={{ display: "flex", m: 0, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <Toolbar>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                >
                                    <TextSnippetIcon onClick={() => { navigate('/search') }} sx={{ mr: 2, width: "50px", height: "auto" }} />
                                </IconButton>
                                <div className='mainTitle'>
                                    TechHub
                                </div>
                            </Toolbar>
                        </div>
                    </Box>
                </AppBar >
            </Box >
            <div className='main-layout'>
                <Box sx={{ width: "500px", maxWidth: "100%" }}>
                    <Card sx={{ borderRadius: "15px" }} variant="outlined">
                        <CardContent>
                            <div className='loginLabel'>
                                <label>Sign In</label>
                            </div>
                            <form onSubmit={handleSubmit((data) => console.log(data))}>
                                <div>
                                    <InputFormControl
                                        name="email"
                                        label="User Name"
                                        errors={errors}
                                        control={control}
                                        type=""
                                        initialValue={""}
                                    />
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <InputFormControl
                                        name="password"
                                        label="Password"
                                        errors={errors}
                                        control={control}
                                        type="password"
                                        initialValue={""}
                                    />
                                </div>

                                <div className='loginBtn'>
                                    <Button type="submit" variant='outlined' disabled={false}>
                                        Sign In
                                    </Button>
                                </div>
                                <div className='.sign-in-or-wrapper'>
                                    <span>OR</span>
                                </div>
                                <div className='loginBtnSSO'>
                                    <Button onClick={() => {
                                        navigate('/account/external-login');
                                    }} variant='outlined' disabled={false}>
                                        Single Sign-On(SSO)
                                    </Button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </Box>
            </div>
        </>
    )
}

export default Login