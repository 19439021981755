import create from "zustand";
import {
  IAccessItem,
  ICartItem,
  ICatagoryItemListResult,
  IEmployeeLifeCycle,
  IHardwareItem,
  ISoftwareAccessItem,
} from "../types/categoryListType";
import { CategoryListService } from "../services/CategoryListService";
import { persist } from "zustand/middleware";
import { UserDetialsService } from "../services/UserDetialsService";
import { IUserList } from "../types/user";
import { CartService } from "../services/CartService";
import { Icategory } from "../types/CategoryType";
import { CategoryService } from "../services/CategoryModule/CategoryService";

interface StoreState {
  isLoginRequired: boolean;
  setIsLoginRequired: (isLoginRequired: boolean) => void;

  employeeLifeCycleRequestList: IEmployeeLifeCycle[] | any;
  fetchEmployeeLifeCycleRequestList: () => Promise<IEmployeeLifeCycle[] | null>;

  hardwareList: IHardwareItem[];
  fetchHardwareList: () => Promise<IHardwareItem[] | null>;

  softwareAccessList: ISoftwareAccessItem[];
  fetchSoftwareAndAccessList: () => Promise<ISoftwareAccessItem[] | null>;

  allItemList: ICatagoryItemListResult[];
  setAllItemList: (
    allCateallItemListgoryList: ICatagoryItemListResult[]
  ) => void;
  fetchAllItemList: () => Promise<ICatagoryItemListResult[] | null>;

  userList: IUserList[];
  fetchUserList: () => Promise<IUserList[] | null>;

  allCategoryList: Icategory[] | any;
  setAllCategoryList: (allCategoryList: Icategory[]) => void;
  fetchAllCategoryList: () => Promise<Icategory[] | null>;

  reset: () => void;
}

interface CartStoreState {
  cartList: ICartItem[];
  totalCartItem: Number;
}
const useStore = create<StoreState>((set, get) => ({
  isLoginRequired: false,
  setIsLoginRequired: (status: boolean) => {
    set({ isLoginRequired: status });
  },

  hardwareList: [],
  fetchHardwareList: async () => {
    const returnedResult = (await CategoryListService.GetAllHardwareList())
      .data;
    set({ hardwareList: returnedResult.data });
    return returnedResult.data;
  },
  softwareAccessList: [],
  fetchSoftwareAndAccessList: async () => {
    const returnedResult = (
      await CategoryListService.GetAllSoftwareAndAccessList()
    ).data;
    set({ softwareAccessList: returnedResult.data });
    return returnedResult.data;
  },
  allItemList: [],
  setAllItemList: (list) => {
    set({ allItemList: list });
  },
  fetchAllItemList: async () => {
    const { allItemList } = get();
    const returnedResult = (await CategoryListService.GetAllItemList()).data;
    set({ allItemList: returnedResult.data });
    return returnedResult.data;
  },

  allCategoryList: [],
  setAllCategoryList: (category_list) => {
    set({ allCategoryList: category_list });
  },
  fetchAllCategoryList: async () => {
    const { allCategoryList } = get();

    const returnedResult = (await CategoryService.getCategory()).data;
    set({ allCategoryList: returnedResult.data });
    return returnedResult.data;
  },

  userList: [],
  fetchUserList: async () => {
    const returnedResult = (await UserDetialsService.GetAllUsers()).data;
    set({ userList: returnedResult.data });
    return returnedResult.data;
  },

  employeeLifeCycleRequestList: [],
  fetchEmployeeLifeCycleRequestList: async () => {
    const returnedResult = (
      await CategoryListService.GetEmployeeLifeCycleRequestList()
    ).data;
    set({ employeeLifeCycleRequestList: returnedResult.data });
    return returnedResult.data;
  },
  reset: () => {
    set({});
  },
}));

export const useCartListStore = create(
  persist<CartStoreState>(
    (set, get) => ({
      cartList: [],
      totalCartItem: 0,
      fetchCartList: async (email: string) => {
        set((state) => ({ cartList: [] }));
        const cartList = await CartService.GetAllCartItems(email);
        if (cartList.data.code === 200) {
          set((state: any) => ({
            cartList: cartList.data.data,
          }));
          set((state: any) => ({
            totalCartItem: cartList.data.data.length,
          }));

          return true;
        } else {
          return false;
        }
      },
      setCartList: async (email: string, cartItem: ICartItem) => {
        const pStore: any = get();
        if (cartItem) {
          const res = await CartService.AddToCatrItem(email, cartItem);
          if (res) {
            if (res.data.code === 200) {
              const oldCart = pStore.cartList;
              // set((state: any) => ({
              //   cartList: [...oldCart, cartItem],
              // }));
              set((state: any) => ({
                totalCartItem: oldCart.length + 1,
              }));
              await pStore.fetchCartList(email);
              return true;
            }
          } else {
            return false;
          }
        }
      },
      RemoveItemFromCart: async (email: string, cartItem: ICartItem) => {
        const pStore: any = get();
        if (cartItem) {
          let matchedCartItem = pStore.cartList.find(
            (x: ICartItem) => x.description === cartItem.description
          );
          if (matchedCartItem) {
            const res = await CartService.RemoveToCatrItem(email, cartItem);
            if (res) {
              if (res.data.code === 200) {
                pStore.fetchCartList(email);
              }
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      },
      clearCart: async (email: any) => {
        const pStore: any = get();
        if (pStore.cartList.length > 0) {
          const res = await CartService.ClearCartItems(email);
          if (res) {
            if (res.data.code === 200) {
              set((state) => ({ cartList: [] }));
              set((state: any) => ({
                totalCartItem: 0,
              }));
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      },
    }),
    {
      name: "cart-list", // name of the item in the storage (must be unique)
    }
  )
);

export const userStore = create(
  persist(
    (set, get) => ({
      sysAidSessionId: "",
      setSysAidSessionId: (sessionId: string) => {
        set({ sysAidSessionId: sessionId });
      },

      isSSOlLoginInProgress: false,
      setIsSSOlLoginInProgress: (status: boolean) => {
        set({ isSSOlLoginInProgress: status });
      },
      user: undefined,
      redirectUrlPath: undefined,
      getUser: () => {
        const pStore: any = get();
        return pStore.user;
      },
      setUser: (newUser: any) => {
        set({ user: newUser });
      },
      setRedirectUrlPath: (value: string | undefined) => {
        set({ redirectUrlPath: value });
      },
      reset: () => {
        set({});
      },
    }),
    {
      name: "user-data", // name of the item in the storage (must be unique)
    }
  )
);

interface FormDataStoreType {
  SavedFormData: string;
  SavedFormDataWithTitle: any;
  SavedFinalSurveyData: any;
}
export const FormDataStore = create(
  persist<FormDataStoreType>(
    (set, get) => ({
      SavedFormData: "",
      setSavedFormData: (formData: string) => {
        set({ SavedFormData: formData });
      },

      SavedFormDataWithTitle: [],

      setSavedFormDataWithTitle: (formData: any) => {
        const fStore: any = get();
        const OldFormData = fStore.SavedFormDataWithTitle;
        if (OldFormData.length > 0) {
          var hasObjectWithKey = OldFormData.some(
            (obj: any) => obj.key === formData.key
          );
          if (hasObjectWithKey) {
            OldFormData.map((item: any) => {
              if (item.key === formData.key) {
                item.value = formData.value;
              }
            });
          } else {
            OldFormData.push(formData);
          }

          set({ SavedFormDataWithTitle: OldFormData });
        } else {
          OldFormData.push(formData);
          set({ SavedFormDataWithTitle: OldFormData });
        }
      },

      SavedFinalSurveyData: [],

      setSavedFinalSurveyData: (formData: any) => {
        const fStore: any = get();
        const OldFormData = fStore.SavedFinalSurveyData;
        if (OldFormData.length > 0) {
          var hasObjectWithKey = OldFormData.some(
            (obj: any) => obj.key === formData.key
          );
          if (hasObjectWithKey) {
            OldFormData.map((item: any) => {
              if (item.key === formData.key) {
                item.value = formData.value;
              }
            });
          } else {
            OldFormData.push(formData);
          }

          set({ SavedFinalSurveyData: OldFormData });
        } else {
          OldFormData.push(formData);
          set({ SavedFinalSurveyData: OldFormData });
        }
      },

      setInitialSavedFormDataWithTitle: () => {
        set((state) => ({
          SavedFormDataWithTitle: [],
          SavedFinalSurveyData: [],
        }));
      },
    }),
    {
      name: "form-Data", // name of the item in the storage (must be unique)
    }
  )
);



export default useStore;


