import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";

interface CheckboxProps {
    name: string;
    control: any;
    label: string;
    errors: any;
    initialValue?: any;
}

const InputCheckboxFormControl: React.FC<CheckboxProps> = (props) => {
    let { name, control, label, errors, initialValue } = props;
    const methods = useForm();
    const { handleSubmit, register, getValues } = methods;

    // we are setting form value manually here

    return (
        <>
            <FormControl size={"small"} variant={"outlined"} error={Boolean(errors[name])}>
                <FormControlLabel
                    control={
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={initialValue}
                            render={({ field: { onChange, value } }) => (
                                <Checkbox
                                    {...props}
                                    checked={value}
                                    onChange={(e) => onChange(e.target.checked)}
                                />
                            )}
                        />
                    }
                    label={label}
                />
                {errors && <FormHelperText>{errors[name]?.message}</FormHelperText>}
            </FormControl>
        </>
    );
};

export default InputCheckboxFormControl;
