import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Form, Formik } from 'formik';
import { ICatagoryItemListResult } from '../../types/categoryListType';
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { PlainLightPanelless } from "survey-core/themes";
import { FormDataStore } from '../../store/store';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
interface props {
    itemData: ICatagoryItemListResult,
    isOpenFormDialog: boolean,
    handleCloseItemFormPopup: (showFormPopup: boolean) => void
    addToCartItem: (ItemDetails: ICatagoryItemListResult) => void
}
const CategoryItemForm: React.FC<props> = ({ itemData, isOpenFormDialog, handleCloseItemFormPopup, addToCartItem }) => {
    const [survey, setSurvey] = useState<Model | null>(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);

    // const datetime = new Date().getTime();
    // const newDate = moment.utc();
    // console.log("new Date", newDate);
    // moment(datetime).utcOffset(60).format('YYYY-MM-DD HH:mm')

    const fStore: any = FormDataStore();
    const finalSurveyJSON: any = null;
    const model = new Model(itemData?.catagoryItemsform.form);
    survey?.applyTheme(PlainLightPanelless);

    survey?.onValueChanged.add((survey: any, { name, title, question, value }: any) => {
        window.localStorage.setItem(
            "SavedFormData",
            JSON.stringify(survey?.data, null, 3)
        );
        if (question.inputType === "date" || question.inputType === "datetime-local") {
            const date = new Date(value);
            // const timezoneOffsetMs = date.getTimezoneOffset() * 60 * 1000; // Get local timezone offset in milliseconds
            // const timestampAdjusted = date.getTime() + timezoneOffsetMs;
            //  const date = new Date(new Date(value).toISOString()).getTime();

            //const date = new Date(new Date(value).toLocaleString('en-US', { timeZone: 'America/New_York' })).getTime();
            //date.setDate(date.getDate() + 1)
            const unixTimestamp = new Date(date).getTime();
            if (survey?.data) {
                fStore.setSavedFinalSurveyData({ key: name, value: unixTimestamp.toString() });
            }
        }
        else {
            if (survey?.data) {
                fStore.setSavedFinalSurveyData({ key: name, value: value });
            }
        }

        fStore.setSavedFormData(JSON.stringify(survey?.data) as string);

        fStore.setSavedFormDataWithTitle({ key: question.jsonObj.title, value: value });

        console.log(question.jsonObj.title);
    });

    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleCloseItemFormPopup(false);
        setShowLoader(false);
    };

    const handleDialog = () => {
        setOpenDialog(isOpenFormDialog);
    };

    useEffect(() => {
        setSurvey(new Model(itemData?.catagoryItemsform.form));
    }, [itemData?.catagoryItemsform.form]);
    useEffect(() => {
        handleDialog();
        setShowLoader(false);
        if (itemData?.catagoryItemsform.templateID === "3476" || itemData?.catagoryItemsform.formName === "General Hardware Request") {
            if (fStore.SavedFormDataWithTitle.length === 0)
                setSurvey(new Model(itemData?.catagoryItemsform.form));
        }
        else {
            setSurvey(new Model(itemData?.catagoryItemsform.form));
            fStore.setInitialSavedFormDataWithTitle();
        }
    }, [isOpenFormDialog]);

    return (
        <Dialog className='AddUserDialog' open={openDialog} onClose={handleCloseDialog}>
            {/* <DialogTitle sx={{ fontSize: "24px", fontWeight: "bold" }}>{itemData?.catagoryItemsform.formName}</DialogTitle> */}
            <DialogContent>

                <Formik
                    initialValues={{}}
                    onSubmit={(values: any, actions) => {
                        console.log(fStore.SavedFormData, fStore.SavedFormDataWithTitle, fStore.SavedFinalSurveyData);
                        const hasErrors = survey?.hasErrors(true, true);
                        if (!hasErrors) {
                            setShowLoader(true);

                            var newObj: any = {} || null;
                            var newSurveyObj: any = {} || null;
                            // Loop through the array
                            if (fStore.SavedFormDataWithTitle) {
                                fStore.SavedFormDataWithTitle.forEach((item: any) => {
                                    newObj[item.key] = item.value;
                                });
                            }
                            if (Object.keys(newObj).length !== 0) {
                                var newJsonStringOfFormDataWithTitle = JSON.stringify(newObj);
                                itemData.formDataWithTitle = (newJsonStringOfFormDataWithTitle);
                            }
                            else {
                                itemData.formDataWithTitle = null;
                            }

                            if (fStore.SavedFinalSurveyData) {
                                fStore.SavedFinalSurveyData.forEach((item: any) => {
                                    newSurveyObj[item.key] = item.value;
                                });
                            }
                            if (Object.keys(newSurveyObj).length !== 0) {

                                itemData.formData = (newSurveyObj);
                            }
                            else {
                                itemData.formData = null;
                            }
                            // Convert the object to JSON string
                            // if (fStore.SavedFormData) {
                            //     itemData.formData = JSON.parse(fStore.SavedFormData);
                            // }
                            // else {
                            //     itemData.formData = null;
                            // }

                            addToCartItem(itemData);
                        }
                    }}>
                    <Form >
                        <DialogContentText sx={{ p: 1 }}>
                            <div id="divLoadForm">{survey && <Survey model={survey} />}</div>
                        </DialogContentText>
                        <DialogActions className="dialog-btn-list" sx={{ p: 2 }}>
                            <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleCloseDialog}>Cancel</Button>
                            <Button type='submit' sx={{ fontSize: "20px", width: "115px" }} variant="contained" >{showLoader ? <CircularProgress sx={{ width: "35px", height: "35px" }} color="inherit" /> : "Continue"}</Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog >
    )
}

export default CategoryItemForm