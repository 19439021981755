import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React, { useEffect } from 'react'

interface ShowNotesProps {
    isOpenDialog: boolean;
    requestNotes: any;
    handleCloseShowNotePopup: (isShowPopup: boolean) => void
}

const ShowNotes: React.FC<ShowNotesProps> = (props) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleCloseDialog = () => {
        setOpenDialog(false);
        props.handleCloseShowNotePopup(false);
    };

    const handleDialog = () => {
        setOpenDialog(props.isOpenDialog);
    };

    useEffect(() => {
        handleDialog();
        console.log(props.requestNotes);
    }, [props.isOpenDialog])

    return (
        <>
            <Dialog className='AddUserDialog' open={props.isOpenDialog} onClose={handleCloseDialog}>
                <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>Notes</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ p: 1 }}>
                        {
                            props.requestNotes.map((note: any) => {
                                return (
                                    <p>{note.note}</p>
                                )
                            })
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleCloseDialog}>Ok</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default ShowNotes