import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useEffect } from 'react'
import InputFormControl from '../../components/controls/InputFormControl';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

interface props {
    isOpenAddUpdateCategoryDialog: boolean,
    AddUpdateCategoryDetails: (id: number, categoryName: string) => void,
    handleCloseAddUpdateCategoryPopup: (isShowPopup: boolean) => void,
    category: any
}
const AddUpdateCategory: React.FC<props> = ({ isOpenAddUpdateCategoryDialog, category, AddUpdateCategoryDetails, handleCloseAddUpdateCategoryPopup }) => {
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleCloseAddUpdateCategoryPopup(false);
    };

    const handleDialog = () => {
        setOpenDialog(isOpenAddUpdateCategoryDialog);
    };

    const validationSchema = Yup.object()
        .shape({
            categoryName: Yup.string().required("Category Name is required")
        }).required();

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        handleDialog();
    }, [isOpenAddUpdateCategoryDialog]);

    useEffect(() => {
        if (category.id === 0) {
            setValue("categoryName", "");
        }
        else {
            setValue("categoryName", category.categoryName);
        }
    }, [category])
    return (
            <Dialog className='AddUserDialog' open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle className='DialogTitleDiv' sx={{ fontSize: "20px", fontWeight: "bold" }}>{Number(category.id) === 0 ? "Add New" : "Update"} Category</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(async (data: any) => {
                        AddUpdateCategoryDetails(category.id, data.categoryName);
                    })}>
                        <DialogContentText sx={{ p: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Category Name</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputFormControl
                                            name="categoryName"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>

                                </div>
                            </Grid>
                        </Grid>
                        </DialogContentText>
                        <DialogActions sx={{ p: 2 }}>
                            <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleCloseDialog}>Cancel</Button>
                            <Button type='submit' sx={{ fontSize: "20px" }} variant="contained">{Number(category.id) === 0 ? "Add" : "Update"}</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
        </Dialog >
    )
}

export default AddUpdateCategory