import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

const siteURL = window.location.origin ?? "";

export const msalConfig = {
  auth: {
    tenantId: "dcae9cf3-6bf2-4136-8997-0bc72c0057e3", // Your tenant ID goes here
    clientId: "2eca166b-3989-4a08-bde6-635cc66e3d98", // Your client (application) ID goes here
    authority:
      "https://login.microsoftonline.com/dcae9cf3-6bf2-4136-8997-0bc72c0057e3/", // Replace the last part with your tenant ID
    //redirectUri: "https://techhub.psychmc.com/auth", // Must be the same in Azure AD portal, can be replace with an environment variable: process.env.REACT_APP_REDIRECT_URL
    //redirectUri: "http://localhost:3000/auth",
    redirectUri: siteURL + "/auth",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },

  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["api://42b4edc0-d44d-49cd-be62-5fdcdb12635e/Catalog.readWrite"],
};
export const appRoles = {
  Admin: "Admin",
};
