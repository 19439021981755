import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from '@mui/material';
import React, { createContext, useContext, useRef } from 'react'


type ConfirmationPopContextActions = {
    showPopup: (text: string, handleYes: () => void) => void;
    hidePopup: () => void;
};

interface ConfirmationPopContextProviderProps {
    children: React.ReactNode
}
const ConfirmationPopContext = createContext({} as ConfirmationPopContextActions);

const ConfirmationPopupProvider: React.FC<ConfirmationPopContextProviderProps> = ({
    children,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>('');
    const [showLoader, setShowLoader] = React.useState(false);
    const resolver = useRef<() => void>();

    const showPopup = (text: string, handleYes: () => void) => {
        setMessage(text);
        setOpen(true)
        resolver.current = handleYes;
    };

    const hidePopup = () => {
        setOpen(false);
        setMessage('');
        setShowLoader(false);
    }

    const handleClose = () => {
        setShowLoader(false);
        setOpen(false);
    };

    const handleYes = () => {
        setShowLoader(true);
        resolver.current && resolver.current();
    }

    return (<>
        <ConfirmationPopContext.Provider value={{ showPopup, hidePopup }}>
            {children}
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className='DialogTitleDiv' id="alert-dialog-title"><b>{"Confirm"}</b></DialogTitle>
                <DialogContent sx={{ marginTop: "10px" }}>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className="dialog-btn-list">
                        <Button
                            sx={{ mr: 2 }}
                            variant="outlined"
                            title="No"
                            onClick={() => handleClose()}
                        >
                            No
                        </Button>
                        <Button
                            variant="contained"
                            title="Yes"
                            onClick={() => handleYes()}
                        >
                            {showLoader ? <CircularProgress sx={{ width: "35px", height: "35px" }} color="inherit" /> : "Yes"}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </ConfirmationPopContext.Provider >
    </>
    )
}

const useConfirmPopup = (): ConfirmationPopContextActions => {
    const context = useContext(ConfirmationPopContext);

    if (!context) {
        throw new Error('useConfirmPopup must be used within an ConfirmPopupProvider');
    }

    return context;
};

export { ConfirmationPopupProvider, useConfirmPopup };