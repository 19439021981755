import { Grid, Paper, Box, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Breadcrumbs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import Loader from '../../components/common components/Loader';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IFormDetails } from '../../types/formType';
import AddIcon from "@mui/icons-material/Add";
import { CategoryItemFormService } from '../../services/CategoryItemFormModule/CategoryItemFormService';
import { useConfirmPopup } from '../../components/context/confirmation-context';
import { useSnackBar } from '../../components/context/snackbar-context';
import { GetErrorMessageFromResponse } from '../../common/commonFunction';
import { NavLink, useNavigate } from 'react-router-dom';
import useStore, { userStore } from '../../store/store';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const FormList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [formList, setFormList] = useState<IFormDetails[]>([]);
    const confirmPopupService = useConfirmPopup();
    const snackbar = useSnackBar();
    const { setAllItemList, fetchAllItemList } = useStore();
    const uStore: any = userStore();
    const navigate: any = useNavigate();

    const GetFormList = () => {
        CategoryItemFormService.getCategoryItemFormList()
            .then((res: any) => {
                if (res) {
                    setFormList(res.data.data);
                    fetchAllItemList();
                    setIsLoading(false);
                }
            })
            .catch(err =>
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error"))
    }
    const handleDelete = (id: number) => {
        confirmPopupService.showPopup("Are you sure you want to remove this form?", () => { deleteForm(id) });
    }

    const deleteForm = async (id: number) => {
        let res = await CategoryItemFormService.removeCategoryItemForm(id);
        if (res.data) {
            if (res.data.code === 200 && res.data.data === "success") {
                confirmPopupService.hidePopup();
                GetFormList();
                snackbar.showSnackBar('Form has been removed successfully.', "success");
            }
            else {
                snackbar.showSnackBar('Form has not removed successfully.', "error");
            }
        }
        else {
            snackbar.showSnackBar('Internal server error.', "error");
        }
    }

    const handleEditForm = (id: any) => {
        navigate(`/edit-form/${id}`);
    }

    useEffect(() => {
        GetFormList();
    }, [])

    return (
        <>
            <div className='breadcumDiv'>
                {
                    uStore.getUser().role === "Administrator" ?
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/pending-request" title="Pending Request">
                                Pending Request
                            </NavLink>
                            <span className="active-breadcumb">Form Management</span>
                        </Breadcrumbs>) :
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/search" title="Make A Request">
                                Make A Request
                            </NavLink>
                            <span className="active-breadcumb">Form Management</span>
                        </Breadcrumbs>)
                }
            </div>
            <Navbar title="Form Management" />
            {
                isLoading ? (
                    <Loader />
                ) :
                    (
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid sx={{ m: "auto" }} item xs={8}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        maxWidth: "100%",
                                        minHeight: "70%",
                                        maxHeight: "100%",
                                        margin: 2,
                                        textAlign: "left",
                                    }}
                                >
                                    <Box sx={{ display: "flex", p: 2, justifyContent: "space-between", alignItems: "end" }}>
                                        <h1 style={{ fontWeight: "bold" }}>Form List</h1>
                                        <Button color="primary" sx={{ backgroundColor: "rgb(109, 135, 255)", marginBottom: 2, marginRight: 1 }} size="large" variant="contained"
                                            onClick={() => {
                                                navigate(`/edit-form/0`);
                                            }}
                                        >
                                            <AddIcon /> Add Form
                                        </Button>
                                    </Box>

                                    <Paper className='requestTableDiv' sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ minHeight: 500, maxHeight: 600 }}>
                                            <Table stickyHeader aria-label="sticky table" sx={{ fontWeight: "bold" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ minWidth: "130px" }}>Form Name</TableCell>
                                                        <TableCell style={{ minWidth: "20px" }}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {formList && formList
                                                        .map((list: IFormDetails, index: Number) => {
                                                            return (
                                                                <TableRow key={Number(list.id)} hover tabIndex={-1}>
                                                                    <TableCell style={{ minWidth: "130px", fontSize: "18px" }}>{list.formName}</TableCell>
                                                                    <TableCell style={{ width: "20px" }}>
                                                                        <EditIcon
                                                                            onClick={() => {
                                                                                handleEditForm(list.id);
                                                                            }}
                                                                            style={{ marginRight: 4 }} />
                                                                        <DeleteIcon
                                                                            onClick={() => { handleDelete(Number(list.id)); }} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
            }
        </>
    )
}

export default FormList