import React, { useEffect, useState } from 'react'
import { useSnackBar } from '../../components/context/snackbar-context';
import { GetErrorMessageFromResponse } from '../../common/commonFunction';
import { Grid, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Breadcrumbs } from '@mui/material';
import Navbar from '../../components/Navbar';
import EditIcon from '@mui/icons-material/Edit';
import { useConfirmPopup } from '../../components/context/confirmation-context';
import Loader from '../../components/common components/Loader';
import { NavLink } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useStore, { userStore } from '../../store/store';
import { HardwarePackagesRoleService } from '../../services/HardwarePackagesforRolesModule/HardwarePackagesRoleService';
import AddRemoveHardwareItemsInPackage from './AddRemoveHardwareItemsInPackage';

interface Element {
    title: string;
    type: string;
    choices?: any[]; // adjust the type according to the actual type of choices
}

const HardwarePackagesRoleList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [employerRoleList, setEmployerRoleList] = useState([]);
    const [isOpenAddRemoveHardwareItemDialog, SetOpenAddRemoveHardwareItemDialog] = useState<boolean>(false);
    const uStore: any = userStore();
    const [selectedEmployeeRolePackages, setSelectedEmployeeRolePackages] = useState<any>();
    const snackbar = useSnackBar();
    const GetEmployeeRole = async () => {
        const res = await HardwarePackagesRoleService.GetEmployeeRoleList();
        if (res) {
            //  let roles = data.pages[0].elements.find((x: Element) => x.title.toLowerCase() === "employee role" && x.type === 'dropdown').choices;
            setEmployerRoleList(res.data.data);
            setIsLoading(false);
        }
    }

    const handleCloseAddRemoveHardwareItemPopup = (isShowPopup: boolean) => {
        SetOpenAddRemoveHardwareItemDialog(isShowPopup);
    }

    const AddRemoveHardwareItems = (id: number, selectedHardwareList: any) => {

        var itemIds = Object.keys(selectedHardwareList).map((k: any) => { return selectedHardwareList[k].id }).join(",");
        console.log(itemIds);

        HardwarePackagesRoleService.addRemoveHardwareItem(id, itemIds)
            .then((res: any) => {
                if (res && res.data.code === 200) {
                    handleCloseAddRemoveHardwareItemPopup(false);
                    snackbar.showSnackBar(res.data.data, "success");
                    GetEmployeeRole();
                }
                else {
                    snackbar.showSnackBar(res.data.data, "error");
                }
            })
            .catch(err => {
                handleCloseAddRemoveHardwareItemPopup(false);
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error")
            })
    }

    useEffect(() => {
        GetEmployeeRole();
    }, [])

    return (
        <> <div className='breadcumDiv'>
            {
                uStore.getUser().role === "Administrator" ?
                    (<Breadcrumbs
                        separator={<ArrowForwardIosIcon />}
                        aria-label="breadcrumb"
                    >
                        <NavLink to="/pending-request" title="Pending Request">
                            Pending Request
                        </NavLink>
                        <span className="active-breadcumb">Hardware Packages Roles Management</span>
                    </Breadcrumbs>) :
                    (<Breadcrumbs
                        separator={<ArrowForwardIosIcon />}
                        aria-label="breadcrumb"
                    >
                        <NavLink to="/search" title="Make A Request">
                            Make A Request
                        </NavLink>
                        <span className="active-breadcumb">Hardware Packages Roles Management</span>
                    </Breadcrumbs>)
            }

        </div>
            <Navbar title="Hardware Packages Roles Management" />
            {
                isLoading ? (
                    <Loader />
                ) :
                    (
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid sx={{ m: "auto" }} item xs={8}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        maxWidth: "100%",
                                        minHeight: "70%",
                                        maxHeight: "100%",
                                        margin: 2,
                                        textAlign: "left",
                                    }}
                                >
                                    <Box sx={{ display: "flex", p: 2, justifyContent: "space-between", alignItems: "end" }}>
                                        <h1 style={{ fontWeight: "bold" }}>Hardware Packages for Roles</h1>
                                    </Box>

                                    <Paper className='requestTableDiv' sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ minHeight: 500, maxHeight: 600 }}>
                                            <Table stickyHeader aria-label="sticky table" sx={{ fontWeight: "bold" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ minWidth: "130px" }}>Employee Role</TableCell>
                                                        <TableCell style={{ minWidth: "20px" }}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {employerRoleList && employerRoleList
                                                        .map((list: any, index: Number) => {
                                                            return (
                                                                <TableRow key={Number(list.id)} hover tabIndex={-1}>
                                                                    <TableCell style={{ minWidth: "130px", fontSize: "18px" }}>{list.empoyeeRole}</TableCell>
                                                                    <TableCell style={{ width: "20px" }}>
                                                                        <EditIcon
                                                                            onClick={() => {
                                                                                SetOpenAddRemoveHardwareItemDialog(true);
                                                                                setSelectedEmployeeRolePackages(list);
                                                                            }}
                                                                            style={{ marginRight: 4 }} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
            }
            <AddRemoveHardwareItemsInPackage
                AddRemoveHardwareItems={(id: number, selectedHardwareList: any) => { AddRemoveHardwareItems(id, selectedHardwareList); }}
                isOpenAddRemoveHardwareItemDialog={isOpenAddRemoveHardwareItemDialog}
                handleCloseAddRemoveHardwareItemPopup={(isShowPopup: boolean) => { handleCloseAddRemoveHardwareItemPopup(isShowPopup) }}
                employeeRolePackages={selectedEmployeeRolePackages} />
        </>
    )
}

export default HardwarePackagesRoleList