import { AxiosResponse } from "axios";
import instance from "../../common/http.common";
import {
  GetEmployeeOnboardingEquipmentList,
  RemoveEmployeeOnboardingEquipment,
  AddUpdateEmployeeOnboardingEquipment,
  GetEmployeeOnboardingEquipmentDetailsById,
} from "../../common/ApiEndPoints";
import { IFormDetails } from "../../types/formType";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";
const _baseUrl: string = `${_rootUrl}/EmployeeOnboardingEquipment`;

const GetEmployeeOnboardingList = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + GetEmployeeOnboardingEquipmentList);
};

const RemoveEmployeeOnboarding = async (
  id: number
): Promise<AxiosResponse<any>> => {
  return await instance.delete(
    `${_baseUrl + RemoveEmployeeOnboardingEquipment}/?Id=${id}`
  );
};

const AddUpdateEmployeeOnboarding = async (
  data: any
): Promise<AxiosResponse<any>> => {
  const fromdata = new FormData();
  fromdata.append("equipmentName", data.equipmentName);
  fromdata.append("equipmentImageURL", data.equipmentImageURL);
  fromdata.append("itemIds", data.itemIds);
  fromdata.append("itemQuantity", data.itemQuantity);
  fromdata.append("isDeleted", data.isdeleted);
  fromdata.append("id", data.id);
  return await instance.post(
    _baseUrl + AddUpdateEmployeeOnboardingEquipment,
    fromdata
  );
};

const GetEmployeeOnboardingById = async (
  id: any
): Promise<AxiosResponse<any>> => {
  return await instance.get(
    `${_baseUrl + GetEmployeeOnboardingEquipmentDetailsById}/?Id=${id}`
  );
};

export const EmployeeOnboardingEquipmentService = {
  GetEmployeeOnboardingList,
  RemoveEmployeeOnboarding,
  AddUpdateEmployeeOnboarding,
  GetEmployeeOnboardingById,
};
