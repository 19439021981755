import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { msalConfig } from './msal.config';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ConfirmationPopupProvider } from './components/context/confirmation-context';
import { SnackbarProvider } from './components/context/snackbar-context';
import { AuthProvider } from './components/context/auth-context';
import { LoaderProvider } from './components/context/Loader-context';
import { InformationPopupProvider } from './components/context/information-context';
import packageJson from "../../IT-Catalog-Client/package.json";
import { Dialog, DialogContent, Box, DialogActions, Button } from '@mui/material';
import { LoginService } from './services/LoginService';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);
// Default to using the first account if no account is active on page load


const Index = () => {
  const [isBundleUpdated, setIsBundleUpdated] = useState(false);

  useEffect(() => {
    getVersion();
  }, []);

  const getVersion = async () => {
    try {
      const response: any | null = await LoginService.CheckLatestAppVersion();
      if (!response || (response.code !== 200) || !response.data) {
        throw new Error("Invalid response or missing data");
      }

      if (packageJson.version !== response.data[0].appLatestVersion) {
        // alert("New version of Insight SLS App is available. Please refresh the page");
        setIsBundleUpdated(true);
      } else {
        setIsBundleUpdated(false);
      }
    } catch (error) {
      console.error("Error fetching or comparing versions:", error);
    }
  };

  // CHECK EVERY 30 MINUTES FOR THE VERSION UPDATE
  setInterval(getVersion, 30 * 60 * 1000);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

  return (
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <LoaderProvider>
      <AuthProvider>
      <ConfirmationPopupProvider>
            <InformationPopupProvider>
        <SnackbarProvider>
                  {isBundleUpdated && (
                    <Dialog
                      open={isBundleUpdated}
                      // onClose={() => setIsBundleUpdated(false)}
                      className="p-2"
                    >
                      <DialogContent>
                        <Box>
                          The application has been updated to a new version. The
                          application will now be refreshed
                        </Box>
                      </DialogContent>
                      <DialogActions className="p-4">
                        {/* <Button
                          onClick={() => setIsBundleUpdated(false)}
                          variant="outlined"
                        >
                          Cancel
                        </Button> */}
                        <Button
                          onClick={() => {
                            window.location.reload();
                            setIsBundleUpdated(false);
                          }}
                          variant="contained"
                        >
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
          <App />
        </SnackbarProvider>
            </InformationPopupProvider>
      </ConfirmationPopupProvider>
      </AuthProvider>
      </LoaderProvider>
    </MsalProvider>
  </React.StrictMode>
);
};

root.render(
  <Index></Index>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

