import { createContext, useContext } from "react";
import useStore, { userStore } from "../../store/store";
// import jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../msal.config";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

interface AuthContextType {
    getUser: () => any;
    signin: (user: any) => boolean;
    signout: () => void;
    getPermissionForRouteAccess: () => boolean;
}

let AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const { instance, accounts } = useMsal();
    const uStore: any = userStore();
    const { setIsLoginRequired } = useStore();
    let isUserLoggedIn = false;

    const tokens: any = {
        accessToken: Cookies.get("access-token"),
    };

    if (tokens.accessToken) {
        const objToken: any = jwtDecode(tokens.accessToken);
        if (objToken == null || new Date((objToken?.exp ?? 0) * 1000) < new Date()) {
            const account = accounts[0];
            if (account) {
                console.log("authcontext 1");
                instance
                    .acquireTokenSilent({
                        ...loginRequest,
                        account,
                    })
                    .then((response) => {
                        console.log("authcontext 2");
                        if (response.accessToken) {
                            console.log("authcontext 3");
                            Cookies.set("access-token", response.accessToken);
                            isUserLoggedIn = true;
                        }
                    })
                    .catch((e) => {
                        console.log("authcontext 4");
                        isUserLoggedIn = false;
                    });
            }
            else {
                console.log("authcontext 5");
                isUserLoggedIn = false;
            }
        }
        else {
            console.log("authcontext 6");
            isUserLoggedIn = true;
        }
    }
    else {
        console.log("authcontext 7");
        isUserLoggedIn = false;
    }

    const signin = (user: any): boolean => {
        let isAuthentication: boolean = false;
        if (user.token) {
            const objToken: any = jwtDecode(user.token);
            if (objToken == null || new Date((objToken?.exp ?? 0) * 1000) < new Date()) {
                isAuthentication = false;
            }
            else {
                uStore.setUser(user);
                uStore.setSysAidSessionId(user.sysAidLoginSessionId);
                const expirationDate = new Date();
                expirationDate.setFullYear(expirationDate.getFullYear() + 2);
                Cookies.set("access-token", user.token, { expires: expirationDate });
                tokens.accessToken = Cookies.get("access-token");
                isUserLoggedIn = true;
                isAuthentication = true;
            }
        }
        //     if (user.token) {
        //     uStore.setUser(user);
        //     isAuthentication = true;
        // }
        return isAuthentication;
    };

    const signout = async () => {
        Cookies.remove("access-token");
        Cookies.remove("token");
        uStore.setUser(undefined);
        uStore.reset();
        localStorage.clear();
        sessionStorage.clear();
        isUserLoggedIn = false;
        debugger;
        if (accounts.length > 0) {
            instance.logout({
                account: instance.getActiveAccount(),
                postLogoutRedirectUri: 'https://techhub.psychmc.com/account/external-login',
            });
        }
    };

    const getUser = () => {
        const user = uStore.getUser();
        if (user) {
            return user;
        }
        return null
    };

    const getPermissionForRouteAccess = () => {
        const user = uStore.getUser();
        return isUserLoggedIn || accounts.length > 0;
    }

    const value = { getUser, signin, signout, getPermissionForRouteAccess };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return useContext(AuthContext);
}
