import React, { useEffect, useState } from 'react'
import { ICartItem, ICatagoryItemListResult, ISuggestedItems } from '../../types/categoryListType'
import useStore, { useCartListStore, userStore } from '../../store/store';
import { useSnackBar } from '../context/snackbar-context';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import Carousel from 'react-multi-carousel';
import { responsiveForPopup } from '../../common/carouselCommonProperty';
import Add from "../assets/icon/Add.png";
import { useInformationPopup } from '../context/information-context';
import { CartService } from '../../services/CartService';
import { GetErrorMessageFromResponse } from '../../common/commonFunction';
import { EmployeeOnboardingEquipmentService } from '../../services/EmployeeOnboardingEquipmentModule/EmployeeOnboardingEquipmentService';
import { HardwarePackagesRoleService } from '../../services/HardwarePackagesforRolesModule/HardwarePackagesRoleService';
interface props {
    suggestiveItemList: ISuggestedItems[],
    isOpenSuggestedItemDialog: boolean,
    handleCloseNewEmployeeItemPopup: (showNewEmployeeItemPopup: boolean) => void,
    currentItemList: any,
}



const ShowNewEmployeeItems: React.FC<props> = ({ currentItemList, suggestiveItemList, isOpenSuggestedItemDialog, handleCloseNewEmployeeItemPopup }) => {

    const [showFormPopup, setShowFormPopup] = useState<boolean>(false);
    const [itemData, setItemData] = useState<any>();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [equipmentItemList, setEquipmentItemList] = useState<any>([]);
    const cStore: any = useCartListStore();
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const snackbar = useSnackBar();
    const { allItemList } = useStore();
    const uStore: any = userStore();
    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleCloseNewEmployeeItemPopup(false);
    };
    const infoPopup = useInformationPopup();
    const handleCloseItemFormPopup = (showFormPopup: boolean) => {
        setShowFormPopup(showFormPopup);
    }

    const handleDialog = () => {
        setOpenDialog(isOpenSuggestedItemDialog);
    };

    const AddMultipleItemsToCartItem = async () => {
        var filteredArray = selectedItems.filter(function (id: any) {
            return id !== null;
        });
        CartService.AddMultipleItemsintoCart(uStore.user.userEmail, filteredArray).then((res: any) => {
            if (res) {
                if (res.data.code === 200) {
                    infoPopup.showPopup("Your items have been added to your cart!");
                    cStore.fetchCartList(uStore.user.userEmail);
                    handleCloseDialog();
                }
                else {
                    snackbar.showSnackBar("Item has not been added to your cart.", "info");
                }
            }
        })
            .catch((error: any) => {
                snackbar.showSnackBar(GetErrorMessageFromResponse(error), "error");
            })

        // if (await pStore.setCartList(uStore.user.userEmail, itemDetails)) {
        //     // snackBar.showSnackBar("Item has been added to your cart!", "success");
        //     infoPopup.showPopup("Your items have been added to your cart!");

        // }
        // else
        //     snackBar.showSnackBar("Item has already in your cart.", "info");
        handleCloseItemFormPopup(false);
    }

    const handleCheckboxChange = (itemID: any, index: any) => {
        if (itemID) {
            let exData = [...selectedItems];
            const isSelected = exData[index]?.toString() === itemID?.toString();
            if (isSelected) {
            // If already selected, remove from the list
                exData = exData.map((x: any, i: number) => (i === index ? null : x));
            } else {
                // If not selected, add to the list
                exData[index] = itemID?.toString();
            }
            setSelectedItems(exData)
        }
    };

    const getEquipmetItemlist = () => {
        let employeeRole = "";
        if (currentItemList && currentItemList.formData) {
            let formDataDetails = JSON.parse(currentItemList.formData);
            employeeRole = formDataDetails['Employee Role'];
            HardwarePackagesRoleService.GetSpecificEmployeeRole(employeeRole)
            .then((res: any) => {
                if (res && res.data.code === 200) {
                    let itemsIds = res.data.data.itemIds.split(",");
                    let SelectedItemsList: any = [];
                    itemsIds.map((id: any) => {
                        const temp_obj = allItemList.find((x: ICatagoryItemListResult) => x.id === Number(id));
                        if (temp_obj) {
                            SelectedItemsList.push(temp_obj);
                        }
                    });
                    setSelectedItems(itemsIds);
                    setEquipmentItemList(SelectedItemsList);
                }
                else {
                    snackbar.showSnackBar(res.data.data, "error");
                }
            })
            .catch(err => {
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error")
            })
        }
    }

    useEffect(() => {
        getEquipmetItemlist();
        handleDialog();
    }, [isOpenSuggestedItemDialog])

    return (
        <Dialog className='AddUserDialog' open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>Suggested Additional Items</DialogTitle>
            <DialogContent>
                <div className='newEmployeeListDiv'>
                    {equipmentItemList && equipmentItemList.length > 0 ? (
                        equipmentItemList.map((list: any, i: number) => (
                            <>
                                <div className="newEmployeeListItemDiv" key={i}>
                                    <input
                                        type="checkbox"
                                        defaultChecked={true}
                                        //checked={selectedItems.includes(list.id)}
                                        onChange={() => handleCheckboxChange(list.id, i)}
                                    />
                                    <div className='newEmployeeListItemImageDiv'>
                                        <img src={list.imageUrl} alt={list.equipmentName} />
                                    </div>
                                    <span>{list.description}</span>
                                </div>
                                <hr></hr>
                            </>

                        ))
                    ) : (
                        <label>Item not found</label>
                    )}
                </div>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button sx={{ fontSize: "20px" }} variant="contained" onClick={() => {
                    handleCloseDialog();
                }}>Cancel</Button>
                {
                    equipmentItemList && equipmentItemList.length > 0 &&
                    <Button sx={{ fontSize: "20px" }} variant="contained" onClick={() => {
                    if (selectedItems && selectedItems.length > 0) {
                        AddMultipleItemsToCartItem();
                    }
                }}>Continue</Button>
                }

            </DialogActions>
        </Dialog >
    )
}

export default ShowNewEmployeeItems