
import React, { useEffect, useState } from "react";
import { Navigate, Route, useNavigate } from "react-router-dom";
import useStore, { useCartListStore, userStore } from "../../store/store";
import { useAuth } from "../../components/context/auth-context";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../../msal.config";
import { jwtDecode } from "jwt-decode";
import { useSnackBar } from "../../components/context/snackbar-context";
import Cookies from 'js-cookie';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const navigate = useNavigate();
    const uStore: any = userStore();

    const { fetchAllItemList, fetchAllCategoryList } = useStore();
    const auth: any = useAuth();
    const permission = auth.getPermissionForRouteAccess();
    //const permission = true;
    const cStore: any = useCartListStore();
    const snackbar = useSnackBar();

    const GetCartListByUser = async () => {
        const result = await cStore.fetchCartList(uStore.getUser().userEmail);
        if (!result) {
            snackbar.showSnackBar('Something Went wrong!', "error");
        }
    }

    const getItemAndCategoryList = async () => {
        let item_list = await fetchAllItemList();
        let category_list = await fetchAllCategoryList();
    };

    useEffect(() => {
        const user = uStore.getUser();
        const accessToken = Cookies.get("access-token");
        if (user && accessToken) {
            GetCartListByUser();
            getItemAndCategoryList();
        }
    }, [])
    return (
        <React.Fragment>
            {
                permission ? children : (<Navigate to={`/account/external-login`} replace />)
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;
