import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const searchCategory = (
  searchText: string,
  hardwareList?: any,
  softwareAccessList?: any,
  employeeLifeCycleRequestList?: any
) => {
  let new_list: any = [];
  let searchText_array = [];
  let list: any = [];
  if (hardwareList && hardwareList.length > 0) list = list.concat(hardwareList);
  if (softwareAccessList && softwareAccessList.length > 0)
    list = list.concat(softwareAccessList);
  if (employeeLifeCycleRequestList && employeeLifeCycleRequestList.length > 0)
    list = list.concat(employeeLifeCycleRequestList);
  searchText_array = searchText.split(" ").filter((e: any) => e);
  if (searchText_array.length === 1) {
    const searchKeywords = searchText.toLowerCase();
    new_list = list.filter((item: any) =>
      item.description.toLowerCase().includes(searchKeywords)
    );
    return new_list;
  } else {
    searchText_array.forEach((x: any) => {
      //Acount lenovo zoom / searchText_array.length = 2
      let dList = [];
      dList = list.filter((item: any) =>
        item.description.toLowerCase().includes(x)
      );
      if (new_list.length === 0) {
        new_list = new_list.concat(dList);
      } else {
        let results = dList.filter(function (obj: any) {
          return !new_list.some(function (obj2: any) {
            return obj.description === obj2.description;
          });
        });
        new_list = new_list.concat(results);
      }
    });
    const latest_list = new_list.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1;
      }
      if (a.description > b.description) {
        return 1;
      }
      return 0;
    });
    return latest_list;
  }
};

export const searchUser = (searchText: string, UserList: any) => {
  let new_list: any = [];
  let searchText_array = [];
  searchText_array = searchText.split(" ").filter((e: any) => e);
  if (searchText_array.length === 1) {
    const searchKeywords = searchText.toLowerCase();
    new_list = UserList.filter(
      (item: any) =>
        item.firstName?.toLowerCase().includes(searchKeywords) ||
        item.lastName?.toLowerCase().includes(searchKeywords) ||
        item.userEmail?.toLowerCase().includes(searchKeywords)
    );
    return new_list;
  } else {
    searchText_array.forEach((x: any) => {
      //Acount lenovo zoom / searchText_array.length = 2
      let dList = [];
      dList = UserList.filter(
        (item: any) =>
          item.firstName?.toLowerCase().includes(x) ||
          item.lastName?.toLowerCase().includes(x) ||
          item.userEmail?.toLowerCase().includes(x)
      );
      if (new_list.length === 0) {
        new_list = new_list.concat(dList);
      } else {
        let results = dList.filter(function (obj: any) {
          return !new_list.some(function (obj2: any) {
            return (
              obj.firstName === obj2.firstName ||
              obj.lastName === obj2.lastName ||
              obj.userEmail === obj2.userEmail
            );
          });
        });
        new_list = new_list.concat(results);
      }
    });
    return new_list;
  }
};
export const searchItem = (searchText: string, ItemList: any) => {
  let new_list: any = [];
  let searchText_array = [];
  searchText_array = searchText.split(" ").filter((e: any) => e);
  if (searchText_array.length === 1) {
    const searchKeywords = searchText.toLowerCase();
    new_list = ItemList.filter((item: any) =>
      item.description.toLowerCase().includes(searchKeywords)
    );
    return new_list;
  } else {
    searchText_array.forEach((x: any) => {
      //Acount lenovo zoom / searchText_array.length = 2
      let dList = [];
      dList = ItemList.filter((item: any) =>
        item.description.toLowerCase().includes(x)
      );
      if (new_list.length === 0) {
        new_list = new_list.concat(dList);
      } else {
        let results = dList.filter(function (obj: any) {
          return !new_list.some(function (obj2: any) {
            return obj.description === obj2.description;
          });
        });
        new_list = new_list.concat(results);
      }
    });
    return new_list;
  }
};
export const convertStringToJson = (item: string) => {
  const parsedItemObject: any = JSON.parse(item);
  return parsedItemObject.items;
};

export const UTCToCurrentTimeZone = (dt: any) => {
  let utcDate = new Date(dt);
  const offsetMinutes: any = utcDate.getTimezoneOffset();
  const localTime = new Date(utcDate.getTime() - offsetMinutes * 60 * 1000);
  return localTime;
};
export const ConvertDate = (dt: any) => {
  console.log("current_date", new Date(dt).getTimezoneOffset());
  let date = UTCToCurrentTimeZone(dt);
  let year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
};

export const GetTime = (dt: any) => {
  let date = UTCToCurrentTimeZone(dt);
  let hours = date.getHours();
  let minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const getLastTwelveMonths = () => {
  let date = new Date();
  let n_date: any = [];
  let monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  date.setDate(1);
  for (let i = 0; i <= 11; i++) {
    console.log(monthName[date.getMonth()] + " " + date.getFullYear());
    n_date.push(monthName[date.getMonth()] + " " + date.getFullYear());
    date.setMonth(date.getMonth() - 1);
  }
  return n_date;
};

export const GetErrorMessageFromResponse = (err: any): string => {
  const response = err?.response;
  const data: any = response?.data;

  if (data && data.errors && data.errors?.length > 0) {
    return data.errors[0].errorMessage;
  }

  if (err.message === "Network Error") {
    return "Network Error, Can not connect to API";
  }

  return "Something went wrong!";
};

export const getMonthNumberFromName = (monthName: any) => {
  const year = new Date().getFullYear();
  return new Date(`${monthName} 1, ${year}`).getMonth() + 1;
};

export const checkOverflow = (latestId: any) => {
  const elements = document.getElementsByClassName("itemList-flex-container");
  let result = false;
  Array.from(elements).forEach((element) => {
    if (element.id === latestId) {
      const hasHorizontalOverflow = element.scrollWidth > element.clientWidth;
      if (hasHorizontalOverflow) result = true;
    }
  });

  return result;
};

export const isTokenExpired = (token: string | undefined): boolean => {
  if (token) {
    const expiryTime = getTokenExpirationTime(token);
    const currentTime = new Date().getTime() / 1000;
    if (expiryTime) {
      return currentTime > expiryTime;
    }
  }
  return false;
};

export const getTokenExpirationTime = (token: string) => {
  return (jwtDecode(token) as any).exp;
};
export const ITCatalogOrderXMLCompomnent = (
  ItemList: any,
  userName: string,
  userEmail: string
) => {
  var itemDetails = "";
  var itemsPriceSum = 0.0;
  let lineCount = 0;

  let orderId = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;

  ItemList.forEach((item: any, index: number) => {
    if (
      item.category.id === 1 ||
      item.category.categoryName === "Hardware and Devices"
    ) {
      itemDetails += `<ItemOut quantity="1" lineNumber="${++lineCount}" requestedDeliveryDate="${
        new Date().toISOString().slice(0, 19) +
        "-" +
        new Date().toString().slice(29, 31) +
        ":" +
        new Date().toString().slice(31, 33)
      }">
          <ItemID>
            <SupplierPartID>${
              item.partID !== null ? item.partID : ""
            }</SupplierPartID>
          </ItemID>
          <ItemDetail>
            <UnitPrice>
              <Money currency="USD">${item.price || 0}</Money>
            </UnitPrice>
              <Description xml:lang="en">${
                item.shortDescription || ""
              }</Description>    
              <UnitOfMeasure>${item.uom || ""}</UnitOfMeasure>
              <Extrinsic name="category">${
                item.itemSubCategory.categoryName || ""
              }</Extrinsic>
              <Extrinsic name="long_description">${
                item.longDesc || ""
              }</Extrinsic>
              <Extrinsic name="imageURL">${item.imageUrl || ""}</Extrinsic>
          </ItemDetail>
        </ItemOut>`;
      itemsPriceSum += parseFloat(item.price || 0);
    }
  });

  if (itemDetails === "") {
    return null;
  } else {
    return `<?xml version="1.0" encoding="UTF-8"?>
    <!DOCTYPE cXML SYSTEM "http://xml.cxml.org/schemas/cXML/1.2.014/cXML.dtd">
    <cXML xml:lang="en-US" payloadID="1643844933.900545@stg507rsq30.int.coupahost.com" timestamp="${
      new Date().toISOString().slice(0, 19) +
      "-" +
      new Date().toString().slice(29, 31) +
      ":" +
      new Date().toString().slice(31, 33)
    }">
      <Header>
        <From>
          <Credential domain="DUNS">
            <Identity>psychmc</Identity>
          </Credential>
        </From>
        <To>
          <Credential domain="DUNS">
            <Identity>094733862</Identity>
          </Credential>
        </To>
        <Sender>
          <Credential domain="DUNS">
              <Identity>psychmc</Identity>
            <Secret>charlie16</Secret>
          </Credential>
           <UserAgent>TechHub 1.0</UserAgent>
        </Sender>
      </Header>
      <Request>
        <OrderRequest>
          <OrderRequestHeader orderID="OCN${orderId}" orderDate="${
      new Date().toISOString().slice(0, 19) +
      "-" +
      new Date().toString().slice(29, 31) +
      ":" +
      new Date().toString().slice(31, 33)
    }" type="new">
            <Total>
              <Money currency="USD">${itemsPriceSum.toFixed(2)}</Money>
            </Total>
            <ShipTo>
              <Address isoCountryCode="US" addressID="00101531">
                <Name xml:lang="en">Retail Business Services UAT</Name>
                <PostalAddress name="default">
                  <DeliverTo></DeliverTo>
                    <Street>8 Cadillac Drive #230</Street>
                    <City>Brentwood</City>
                    <State>TN</State>
                    <PostalCode>37027</PostalCode>
                    <Country isoCountryCode="US">United States</Country>
                    </PostalAddress>
                <Email name="default"></Email>
              </Address>
            </ShipTo>
            <Shipping>
              <Money currency="USD">0.0</Money>
              <Description xml:lang="en-US">BEST WAY PPD-FOB FACTORY</Description>
            </Shipping>
            <PaymentTerm payInNumberOfDays="0">
              <Discount>
                <DiscountPercent percent="0.0"/>
              </Discount>
            </PaymentTerm>
            <Contact role="endUser">
              <Name xml:lang="en">${userName}</Name>
              <Email name="default">${userEmail}</Email>
            </Contact>
            </OrderRequestHeader>
            ${itemDetails}
        </OrderRequest>
      </Request>
    </cXML>`;
  }
};