import axios, { AxiosResponse } from "axios";
import {
  AllItemList,
  GetCarouselList,
  GetEmployeeLifeCycleRequest,
  GetHardwareList,
  GetSoftwareAccessList,
} from "../common/ApiEndPoints";
import instance from "../common/http.common";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";
const _baseUrl: string = `${_rootUrl}/CatagorySerachList`;

const GetAllHardwareList = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + GetHardwareList);
};

const GetAllSoftwareAndAccessList = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + GetSoftwareAccessList);
};

const GetAllItemList = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + AllItemList);
};

const GetEmployeeLifeCycleRequestList = async (): Promise<
  AxiosResponse<any>
> => {
  return await instance.get(_baseUrl + GetEmployeeLifeCycleRequest);
};

const GetAllCarouselList = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + GetCarouselList);
};
export const CategoryListService = {
  GetAllHardwareList,
  GetAllSoftwareAndAccessList,
  GetAllItemList,
  GetAllCarouselList,
  GetEmployeeLifeCycleRequestList,
};
