import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import Loader from '../../components/common components/Loader';
import { Grid, Paper, Box, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Breadcrumbs, FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { GetErrorMessageFromResponse, searchItem, searchUser } from '../../common/commonFunction';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackBar } from '../../components/context/snackbar-context';
import { CategoryItemService } from '../../services/CategoryItemModule/CategoryItemService';
import AddIcon from "@mui/icons-material/Add";
import { useConfirmPopup } from '../../components/context/confirmation-context';
import AddUpdateCategoryItems from './AddUpdateCategoryItems';
import useStore, { userStore } from '../../store/store';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { NavLink } from 'react-router-dom';
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { ICatagoryItemListResult } from '../../types/categoryListType';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const CategoryItemList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchText, setSearchedText] = useState<string>("");
    const [categoryItemsList, setCategoryItemsList] = useState<ICatagoryItemListResult[]>([]);
    const [categoryItemDetails, setCategoryItemDetails] = useState<any>({});
    const [OpenAddUpdateCategoryItemPopup, SetOpenAddUpdateCategoryItemPopup] = useState<boolean>(false);
    const { setAllItemList, fetchAllItemList } = useStore();
    const [filteredItemList, setFilteredItemList] =
        useState<ICatagoryItemListResult[]>(categoryItemsList);
    const snackbar = useSnackBar();
    const uStore: any = userStore();
    const confirmPopupService = useConfirmPopup();
    const GetCategoryItemsList = () => {
        CategoryItemService.getCategoryItemList()
            .then((res: any) => {
                if (res) {
                    setFilteredItemList(res.data.data)
                    setCategoryItemsList(res.data.data);
                    //setAllItemList(res.data.data);
                    fetchAllItemList();
                    setIsLoading(false);
                }
            })
            .catch(err =>
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error"))
    }

    const columns: GridColDef[] = [
        {
            field: 'image', headerName: '', sortable: false, width: 100, renderCell: (params) => {
                console.log(params);
                return (
                    <>
                        <div className='ItemListGridImageDiv'>
                            <img
                            alt=''
                                src={params.row.imageUrl}
                        />
                        </div>
                    </>
                );
            }
        },
        {
            field: 'partID',
            headerName: 'Part Id',
            editable: false,
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        <div style={{ fontSize: "18px" }}>
                            {params.value}
                        </div>
                    </>
                );
            }
        },
        {
            field: 'description',
            headerName: 'Item Name',
            editable: false,
            width: 400,
            renderCell: (params) => {
                return (
                    <>
                        <div style={{ fontSize: "18px" }}>
                            {params.value}
                        </div>
                    </>
                );
            }
        },

        {
            field: 'subCategoryName',
            headerName: 'Subcategory',
            editable: false,
            sortable: true,
            width: 250,
            renderCell: (params) => {
                return (
                    <>
                        <div style={{ fontSize: "18px" }}>
                            {params.value}
                        </div>
                    </>
                );
            }
        },
        {
            field: 'hasForm',
            headerName: 'Has Form',
            editable: false,
            sortable: false,
            width: 130,
            renderCell: (params) => {
                return (
                    <>
                        <div style={{ fontSize: "18px" }}>
                            {params.value ? "Yes" : "No"}
                        </div>
                    </>
                );
            }
        },
        {
            field: "action",
            headerName: "",
            width: 100,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <EditIcon
                            onClick={() => {
                                setCategoryItemDetails(params.row);
                                SetOpenAddUpdateCategoryItemPopup(true);
                            }}
                            style={{ marginRight: 4 }} />
                        <DeleteIcon
                            onClick={() => { handleDelete(Number(params.row.id)); }}
                        />
                    </>
                );
            }
        }
    ];

    const handleDelete = (id: number) => {
        confirmPopupService.showPopup("Are you sure you want to remove this category item?", () => { deleteCategoryItem(id) });
    }

    const deleteCategoryItem = async (id: number) => {
        let res = await CategoryItemService.removeCategoryItem(id);
        if (res.data) {
            if (res.data.code === 200 && res.data.data === "success") {
                confirmPopupService.hidePopup();
                GetCategoryItemsList();
                snackbar.showSnackBar('Category item has been removed successfully.', "success");
            }
            else {
                snackbar.showSnackBar('Category item has not been removed.', "error");
            }
        }
        else {
            snackbar.showSnackBar('Internal server error.', "error");
        }
    }
    const AddUpdateCategoryItemData = (id: number, categoryItemData: any) => {
        categoryItemData.id = id;
        CategoryItemService.addUpdateCategoryItem(categoryItemData)
            .then((res: any) => {
                if (res && res.data.code === 200) {
                    handleCloseAddUpdateCategoryItemPopup(false);
                    snackbar.showSnackBar(res.data.data, "success");
                    GetCategoryItemsList();
                }
                else {
                    snackbar.showSnackBar(res.data.data, "error");
                }
            })
            .catch(err => {
                handleCloseAddUpdateCategoryItemPopup(false);
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error")
            })
    }

    const handleCloseAddUpdateCategoryItemPopup = (isShowPopup: boolean) => {
        SetOpenAddUpdateCategoryItemPopup(isShowPopup);
    }

    const InputHandler = (e: any) => {
        setSearchedText(e.target.value);
    };

    useEffect(() => {
        setSearchedText("");
        GetCategoryItemsList();
    }, []);

    useEffect(() => {
        setFilteredItemList([]);
        if (searchText.length === 0 && searchText === "") {
            setFilteredItemList(categoryItemsList);
        }
        else {
            let result: any = searchItem(
                searchText,
                categoryItemsList
            );
            if (result && result.length > 0) {
                setFilteredItemList(result);
            }
        }

    }, [searchText]);

    return (
        <>
            <div className='breadcumDiv'>
                {
                    uStore.getUser().role === "Administrator" ?
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/pending-request" title="Pending Request">
                                Pending Request
                            </NavLink>
                            <span className="active-breadcumb">Item Management</span>
                        </Breadcrumbs>) :
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/search" title="Make A Request">
                                Make A Request
                            </NavLink>
                            <span className="active-breadcumb">Item Management</span>
                        </Breadcrumbs>)
                }

            </div>
            <Navbar title="Item Management" />
            {
                isLoading ? (
                    <Loader />
                ) :
                    (
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid sx={{ m: "auto" }} item xs={8}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        maxWidth: "100%",
                                        minHeight: "70%",
                                        maxHeight: "100%",
                                        margin: 2,
                                        textAlign: "left",
                                    }}
                                >
                                    <div style={{ padding: "10px 0 0 24px" }}>
                                        <h1 style={{ fontWeight: "bold" }}>Item List</h1>
                                    </div>

                                    <Box sx={{ display: "flex", p: 2, justifyContent: "space-between", alignItems: "end" }}>
                                        <div>

                                            <FormControl sx={{ m: 1, width: '120%' }} variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type="text"
                                                    onChange={(e: any) => {
                                                        InputHandler(e);
                                                    }}
                                                    placeholder="Search here"
                                                    endAdornment={
                                                        <InputAdornment position="end" sx={{ cursor: "pointer " }}>
                                                            <SearchSharpIcon
                                                            />
                                                        </InputAdornment>
                                                    }
                                                    label="Search"
                                                />
                                            </FormControl>
                                        </div>
                                        <Button color="primary" sx={{ backgroundColor: "rgb(109, 135, 255)", marginBottom: 2, marginRight: 1 }} size="large" variant="contained"
                                            onClick={() => {
                                                let data = {
                                                    id: 0,
                                                    description: "",
                                                    longDesc: "",
                                                    hasForm: false,
                                                    imageUrl: "",
                                                    itemSubCategory: null
                                                }
                                                setCategoryItemDetails(data);
                                                SetOpenAddUpdateCategoryItemPopup(true);
                                            }}
                                        >
                                            <AddIcon /> Add Item
                                        </Button>
                                    </Box>

                                    <Paper className='requestTableDiv' sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ minHeight: 500 }}>
                                            <div className='ItemDataGridDiv'>
                                                <DataGrid
                                                    rows={filteredItemList}
                                                    columns={columns}
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: {
                                                                pageSize: 20,
                                                            },
                                                        },
                                                    }}
                                                    rowHeight={80}
                                                />
                                            </div>
                                            {/* <Table stickyHeader aria-label="sticky table" sx={{ fontWeight: "bold" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ minWidth: "50px" }}></TableCell>
                                                        <TableCell style={{ minWidth: "100px" }}>Item Name</TableCell>
                                                        <TableCell style={{ minWidth: "60px" }}>Subcategory</TableCell>
                                                        <TableCell style={{ minWidth: "60px" }}>HasForm</TableCell>
                                                        <TableCell style={{ minWidth: "20px" }}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {sortedfilteredItemList && sortedfilteredItemList
                                                        .map((list: ICatagoryItemListResult, index: Number) => {
                                                            return (
                                                                <TableRow key={Number(list.id)} hover tabIndex={-1}>
                                                                    <TableCell style={{ minWidth: "50px" }} ><img
                                                                        alt=''
                                                                        src={list.imageUrl}
                                                                        style={{ width: "70px", height: "70px", borderRadius: "0" }}
                                                                    /></TableCell>
                                                                    <TableCell style={{ minWidth: "100px" }}>{list.description}</TableCell>
                                                                    <TableCell style={{ minWidth: "60px" }}>{list.itemSubCategory.categoryName}</TableCell>
                                                                    <TableCell style={{ minWidth: "60px" }}>{list.hasForm === true ? "Yes" : "No"}</TableCell>
                                                                    <TableCell style={{ width: "20px" }}>
                                                                        <EditIcon
                                                                            onClick={() => {
                                                                                debugger;
                                                                                setCategoryItemDetails(list);
                                                                                SetOpenAddUpdateCategoryItemPopup(true);
                                                                            }}
                                                                            style={{ marginRight: 4 }} />
                                                                        <DeleteIcon
                                                                            onClick={() => { handleDelete(Number(list.id)); }}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table> */}
                                        </TableContainer>
                                    </Paper>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
            }

            <AddUpdateCategoryItems ItemList={categoryItemsList} isOpenAddUpdateCategoryItemDialog={OpenAddUpdateCategoryItemPopup} AddUpdateCategoryItemData={(id: number, categoryData: any) => { AddUpdateCategoryItemData(id, categoryData); }} handleCloseAddUpdateCategoryItemPopup={(isShowPopup: boolean) => { handleCloseAddUpdateCategoryItemPopup(isShowPopup) }} categoryItem={categoryItemDetails} />
        </>
    )
}

export default CategoryItemList