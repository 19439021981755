import {
  Button,
  List,
  ListItem,
  Box,
} from "@mui/material";
import { ICatagoryItemListResult, ISoftwareAccessItem, ISuggestedItems } from "../types/categoryListType";
import React, { useEffect, useState } from "react";
import { searchCategory } from "../common/commonFunction";
import useStore, { useCartListStore, userStore } from "../store/store";
import ReactPaginate from "react-paginate";
import { useSnackBar } from "../components/context/snackbar-context";
import CategoryItemForm from "../components/modelPopup/CategoryItemForm";
import { useSearchParams } from "react-router-dom";
import { useInformationPopup } from "../components/context/information-context";
import SuggestiveSellItemList from "../components/SuggestiveSellItemList";
import ShowNewEmployeeItems from "../components/modelPopup/ShowNewEmployeeItems";
import { Icategory } from './../types/CategoryType';

interface resultListProps {
  searchText: string;
  ItemList: ICatagoryItemListResult[];
}

const ResultListPage: React.FC<resultListProps> = ({ searchText, ItemList }) => {
  const [searchedfilteredList, setSearchedFilteredList] = useState<ICatagoryItemListResult[]>();
  const { allCategoryList } = useStore();
  // const [searchItemText, setSearchItemText] = useState<string>(props.searchText ?? "");
  const [showFormPopup, setShowFormPopup] = useState<boolean>(false);
  const [showNewEmployeeItemsPopup, setShowNewEmployeeItemsPopup] = useState<boolean>(false);
  const [resultListTitle, setResultListTitle] = useState<string>("");
  const [itemData, setItemData] = useState<any>();
  const [showSuggestedItemPopup, setShowSuggestedItemPopup] = useState<boolean>(false);
  const [currentItemDetails, setCurrentItemDetails] = useState<any>();
  const [suggestiveItemList, setSuggestiveItemList] = useState<ISuggestedItems[]>([]);
  const pStore: any = useCartListStore();
  const snackBar = useSnackBar();
  const PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const uStore: any = userStore();
  const [searchParams] = useSearchParams();
  const infoPopup = useInformationPopup();

  const handlePageChange = (selectedPage: any) => {
    setCurrentPage(selectedPage.selected); 
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCloseItemFormPopup = (showFormPopup: boolean) => {
    setShowFormPopup(showFormPopup);
  }

  const AddToCartItem = async (itemDetails: ICatagoryItemListResult) => {
    itemDetails.formData = JSON.stringify(itemDetails.formData);
    if (itemDetails.suggestiveSellCategoryItems && itemDetails.suggestiveSellCategoryItems.length > 0) {
      if (pStore.setCartList(uStore.user.userEmail, itemDetails)) {
        handleCloseItemFormPopup(false);
        setCurrentItemDetails(itemDetails);
        setSuggestiveItemList(itemDetails.suggestiveSellCategoryItems);
        if (itemDetails.id === 44) {
          setShowNewEmployeeItemsPopup(true);
        }
        else {
          setShowSuggestedItemPopup(true);
        }
        // snackBar.showSnackBar("Item has been added to your cart!", "success");
        //  infoPopup.showPopup("Item has been added to your cart !");

      }
      else {
        handleCloseItemFormPopup(false);
        snackBar.showSnackBar("Item has already in your cart.", "info");
      }
    }
    else {

      if (await pStore.setCartList(uStore.user.userEmail, itemDetails)) {
        // snackBar.showSnackBar("Item has been added to your cart!", "success");
        infoPopup.showPopup("Item has been added to your cart !");

      }
      else
        snackBar.showSnackBar("Item has already in your cart.", "info");
      handleCloseItemFormPopup(false);
    }
  }

  const offset = currentPage * PER_PAGE;

  const currentPageData = searchedfilteredList && searchedfilteredList?.slice(offset, offset + PER_PAGE)
    .map((list: any, i: number) => (
      <>
        <ListItem key={i}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        bgcolor: "background.paper",
                        p: 1,
                        borderRadius: 1,
                      }}
                    >
            <div className="resulItmeImage">
              <img alt="" src={process.env.PUBLIC_URL + list.imageUrl}></img>
                      </div>
                      <div className="resultItemDesc">
                        <div className="resultItemShortDesc">
                          <span>{list.description}</span>
                        </div>
                        <div className="resultItemLongDesc">
                          <p>{list.longDesc}</p>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <Button
                            variant="contained"
                            onClick={() => {

                                if (list.hasForm && list.catagoryItemsform) {
                                  setItemData(list);
                                  setShowFormPopup(true);
                                }
                                else if (list.suggestiveSellCategoryItems && list.suggestiveSellCategoryItems.length > 0) {
                                  if (pStore.setCartList(uStore.user.userEmail, list)) {
                                    // snackBar.showSnackBar("Item has been added to your cart!", "success");
                                    setShowSuggestedItemPopup(true);
                                    setCurrentItemDetails(list);
                                    setSuggestiveItemList(list.suggestiveSellCategoryItems);
                                    //infoPopup.showPopup("Item has been added to your cart !");
                                  }
                                }
                                else {
                                  if (pStore.setCartList(uStore.user.userEmail, list))
                                    // snackBar.showSnackBar("Item has been added to your cart!", "success");
                                    infoPopup.showPopup("Item has been added to your cart !");
                                }
                            }}
                          >
                            Add to Cart
                          </Button>
                        </div>
                      </div>
                    </Box>
        </ListItem> 
      </>
    ));

  const handleCloseSuggestedItemPopup = (showSuggestedItemPopup: boolean) => {
    setShowSuggestedItemPopup(showSuggestedItemPopup);
  }
  const handleCloseNewEmployeeItemPopup = (showNewEmployeeItemPopup: boolean) => {
    setShowNewEmployeeItemsPopup(showNewEmployeeItemPopup);
  }
  useEffect(() => {
    setCurrentPage(0);
    setSearchedFilteredList([]);
    setTotalPages(0);
    if (searchText !== "") {
      let result: ICatagoryItemListResult[] = searchCategory(
        searchText,
        ItemList
      );
      if (result && result.length > 0) {
        setSearchedFilteredList(result);
        setTotalPages(Math.ceil(result.length / PER_PAGE));
      }
    }
    else {
      setSearchedFilteredList(ItemList);
      setTotalPages(Math.ceil(ItemList.length / PER_PAGE));
    }
  }, [searchText]);

  useEffect(() => {
    setCurrentPage(0);
    const param1 = searchParams.get('category');
    if (param1) {
      allCategoryList.map((category_data: Icategory) => {
        if (category_data.categoryName.toLowerCase().replaceAll(" ", '_') === param1) {
          setResultListTitle(category_data.categoryName);
        }
      });
    }
    else {
      setResultListTitle("result");
    }

  }, [searchParams])


  useEffect(() => {
    setTotalPages(0);
    setSearchedFilteredList(ItemList);
  }, []);

  useEffect(() => {
    if (searchText !== "") {
      let result: ICatagoryItemListResult[] = searchCategory(
        searchText,
        ItemList
      );
      if (result && result.length > 0) {
        setSearchedFilteredList(result);
        setTotalPages(Math.ceil(result.length / PER_PAGE));
      }
    }
    else {
      setSearchedFilteredList(ItemList);
      setTotalPages(Math.ceil(ItemList.length / PER_PAGE));
    }
  }, [ItemList]);

  return (
    <div className="ResultListDiv">
      <h2>{resultListTitle}</h2>
          <List sx={{ textAlign: "center", width: "100%", overflow: "auto" }}>
        {searchedfilteredList && searchedfilteredList.length > 0 ? (
          <div>
            {currentPageData}
            <div className="paginationDiv">
            <ReactPaginate
              previousLabel={"< Previous"}
              nextLabel={"Next >"}
              pageRangeDisplayed={2}
                pageClassName={"pagination_li"}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              breakLabel={"..."}
              pageCount={totalPages}
              onPageChange={handlePageChange}
              forcePage={currentPage}
                renderOnZeroPageCount={null} />
            </div>
          </div>
        ) : (
          <label style={{ fontSize: "24px" }}>Result not found</label>
        )
        }
      </List >

      <CategoryItemForm itemData={itemData} isOpenFormDialog={showFormPopup} handleCloseItemFormPopup={(showFormPopup) => { handleCloseItemFormPopup(showFormPopup) }} addToCartItem={(itemData) => AddToCartItem(itemData)} />
      <SuggestiveSellItemList currentItemList={currentItemDetails} suggestiveItemList={suggestiveItemList} isOpenSuggestedItemDialog={showSuggestedItemPopup} handleCloseSuggestedItemPopup={(showSuggestedItemPopup) => { handleCloseSuggestedItemPopup(showSuggestedItemPopup) }} />
      <ShowNewEmployeeItems currentItemList={currentItemDetails} suggestiveItemList={suggestiveItemList} isOpenSuggestedItemDialog={showNewEmployeeItemsPopup} handleCloseNewEmployeeItemPopup={(showNewEmployeeItemPopup) => { handleCloseNewEmployeeItemPopup(showNewEmployeeItemPopup) }} />
    </div >

  );
};

export default ResultListPage;
