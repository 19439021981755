import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Avatar, Menu, MenuItem } from '@mui/material';
import useStore, { useCartListStore, userStore } from '../store/store';
import React, { useEffect, useState } from 'react';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './context/auth-context';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Icategory } from '../types/CategoryType';
interface navbarProps {
  title: string
}
const Navbar: React.FC<navbarProps> = (props) => {

  const { isLoginRequired, setIsLoginRequired } = useStore();
  const navigate = useNavigate();
  const uStore: any = userStore();
  const cStore: any = useCartListStore();
  const [anchorAccountEl, setAnchorAccountEl] = useState<null | HTMLElement>(null);
  const openAccountEl = Boolean(anchorAccountEl);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { allCategoryList, allItemList } = useStore();
  const [anchorAdminEl, setAnchorAdminEl] = useState<null | HTMLElement>(null);
  const openAdministrative = Boolean(anchorAdminEl);

  const auth = useAuth();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdministrativeClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAdminEl(event.currentTarget);
  };
  const handleAdministrativeClose = () => {
    setAnchorAdminEl(null);
  };

  const handleAccountELLogoutClose = () => {
    setAnchorAccountEl(null);
  };
  const handleAccountELLogoutClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAccountEl(event.currentTarget);
  };

  const handleCartClick = () => {
    navigate("/confirm-request");
  }

  const handleLogout = () => {
    auth.signout();
    navigate({ pathname: "/account/external-login" });

  }

  useEffect(() => {
    if (isLoginRequired) {
      auth.signout();
      navigate({ pathname: "/account/external-login" });
      setIsLoginRequired(false)
    }
  }, [isLoginRequired])


  return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Box sx={{ display: "flex", m: 0, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div className='NavbarTitleLeftMenu'>
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                >
                {/* <img onClick={() => { navigate('/search') }} style={{ width: "45px", height: "auto" }} alt="" src={process.env.PUBLIC_URL + '/ITCatalogLogo.png'} /> */}
                <TextSnippetIcon onClick={() => { navigate('/search') }} sx={{ width: "45px", height: "auto" }} />
                </IconButton>
                <div
                  className='mainTitle'
                >
                  {props.title}
                </div>
              {
                allCategoryList && allCategoryList.length > 0 && (
                  <> <span onClick={() => {
                    navigate('/search');
                  }}>Home</span>
                  </>
                )
              }
              {
                allCategoryList.map((category_details: Icategory) => {
                  if (category_details.id === 1 || category_details.id === 2 || category_details.id === 4) {
                    return (
                      <span onClick={() => {
                        // navigate('/search?category=hardware');
                        navigate(`/search?category=${category_details.categoryName.toLowerCase().replaceAll(" ", '_')}`);
                      }}>{category_details.categoryName}</span>
                    )
                  }
                })
              }
            </Toolbar>
          </div>
          <div>
            <Toolbar>
            <div className='NavbarUserInfoDiv'>
              <div onClick={handleAccountELLogoutClick}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", p: 0.5 }}>
                  <span>{uStore.getUser().firstName + ' ' + uStore.getUser().lastName}</span>
                  <Avatar sx={{ height: "25px", width: "25px" }} src="/broken-image.jpg" />
                  </Box>
              </div>
                <Menu
                  anchorEl={anchorAccountEl}
                  id="account-menu"
                  open={openAccountEl}
                  onClose={handleAccountELLogoutClose}
                  onClick={handleAccountELLogoutClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            <div className='NavbarCartInfoDiv'>
              <div onClick={handleCartClick}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <ShoppingCartIcon style={{ height: "30px", width: "30px" }} />
                  <span>Cart</span>
                  <label>{cStore.totalCartItem}</label>
                </Box>
              </div>
            </div>
            <div className='NavbarKenbanMenuDiv'>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                  sx={{ ml: 2 }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={(e) => { navigate('/search'); handleClose(); }}>
                    Make a Request
                  </MenuItem>
                  <MenuItem onClick={(e) => { navigate('/pending-request'); handleClose(); }}>
                    Pending Requests
                  </MenuItem>
                  {
                    (uStore.getUser().role).toLowerCase() === "administrator" &&
                    (
                      <>
                        <MenuItem onClick={(e) => { navigate('/historical-request'); handleClose(); }}>
                          Historical Requests
                        </MenuItem>
                        <MenuItem onClick={handleAdministrativeClick}>
                          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <label> Administrative</label>
                            <ArrowRightRoundedIcon />
                          </Box>
                        </MenuItem>
                      </>
                    )
                  }

                </Menu>

                <Menu
                  anchorEl={anchorAdminEl}
                  id="admin-menu"
                  open={openAdministrative}
                  onClose={handleAdministrativeClose}
                  onClick={handleAdministrativeClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={(e) => { navigate('/user-management'); handleClose(); }}>
                    User Management
                  </MenuItem>
                  <MenuItem onClick={(e) => { navigate('/categoryitem-management'); handleClose(); }}>
                    Item management
                  </MenuItem>
                  <MenuItem onClick={(e) => { navigate('/category-management'); handleClose(); }}>
                    Category management
                  </MenuItem>
                  <MenuItem onClick={(e) => { navigate('/form-list'); handleClose(); }}>
                    Form management
                  </MenuItem>
                  <MenuItem onClick={(e) => { navigate('/employee-onboarding-equipment'); handleClose(); }}>
                    Employee Onboarding Equipment
                  </MenuItem>
                  <MenuItem onClick={(e) => { navigate('/hardware-package-for-role'); handleClose(); }}>
                    Hardware Packages for Roles
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </div>
          </Box>
      </AppBar >
    </Box >
  )
}

export default Navbar