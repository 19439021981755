import React from "react";
import { MetroSpinner } from "react-spinners-kit";

const Loader: React.FC<any> = ({ loading }) => {
  return (
    <div className="spinnerContainer">
      <div className="spinner">
        <MetroSpinner size={50} color="#16817c" loading={loading} />
      </div>
    </div>
  );
};

export default Loader;
