import React, { useContext, useEffect, useRef, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStore, { useCartListStore, userStore } from '../store/store';
import { ISuggestedItems, ISoftwareAccessItem, ICatagoryItemListResult, ICartItem } from '../types/categoryListType';
import SuggestiveSellItemList from './SuggestiveSellItemList';
import Loader from './common components/Loader';
import { useInformationPopup } from './context/information-context';
import { useSnackBar } from './context/snackbar-context';
import CategoryItemForm from './modelPopup/CategoryItemForm';
import Add from "../assets/icon/Add.png";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import ShowNewEmployeeItems from './modelPopup/ShowNewEmployeeItems';

interface props {
    searchText?: string;
    categoryName: string;
}
const MainScreenItemList: React.FC<props> = (props) => {
    const [isLoading, setIsloading] = useState(true);
    const [showFormPopup, setShowFormPopup] = useState<boolean>(false);
    const [showSuggestedItemPopup, setShowSuggestedItemPopup] = useState<boolean>(false);
    const [showNewEmployeeItemsPopup, setShowNewEmployeeItemsPopup] = useState<boolean>(false);
    const [itemData, setItemData] = useState<any>();
    const [currentItemDetails, setCurrentItemDetails] = useState<any>();
    const [suggestiveItemList, setSuggestiveItemList] = useState<ISuggestedItems[]>([]);
    const [filteredAllItemList, setFilteredAllItemList] =
        useState<ICatagoryItemListResult[]>([]);
    const [currentNewEmployeeRole, setCurrentNewEmployeeRole] = useState("");
    const pStore: any = useCartListStore();
    const { allCategoryList, allItemList } = useStore();
    const snackBar = useSnackBar();
    const uStore: any = userStore();
    const navigate = useNavigate();
    const infoPopup = useInformationPopup();
    const getItemList = async () => {
        if (allItemList && allItemList.length > 0) {
            setFilteredAllItemList(allItemList.filter(x => x.itemSubCategory.categoryName === props.categoryName));
            setIsloading(false);
        }
    };
    const [position, setPosition] = React.useState(0);
    type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
    const menuRef: any = useRef();

    const handleCloseItemFormPopup = (showFormPopup: boolean) => {
        setShowFormPopup(showFormPopup);
    }
    const handleCloseSuggestedItemPopup = (showSuggestedItemPopup: boolean) => {
        setShowSuggestedItemPopup(showSuggestedItemPopup);
    }

    const handleCloseNewEmployeeItemPopup = (showNewEmployeeItemPopup: boolean) => {
        setShowNewEmployeeItemsPopup(showNewEmployeeItemPopup);
    }
    const AddToCartItem = async (itemDetails: ICatagoryItemListResult) => {
        itemDetails.formData = JSON.stringify(itemDetails.formData);
        if (itemDetails.suggestiveSellCategoryItems && itemDetails.suggestiveSellCategoryItems.length > 0) {
            if (pStore.setCartList(uStore.user.userEmail, itemDetails)) {
                handleCloseItemFormPopup(false);
                setCurrentItemDetails(itemDetails);
                setSuggestiveItemList(itemDetails.suggestiveSellCategoryItems);
                if (itemDetails.id === 44) {
                    setShowNewEmployeeItemsPopup(true);
                }
                else {
                    setShowSuggestedItemPopup(true);
                }
            }
            else {
                handleCloseItemFormPopup(false);
                snackBar.showSnackBar("Item has already in your cart.", "info");
            }
        }
        else {

            if (await pStore.setCartList(uStore.user.userEmail, itemDetails)) {
                // snackBar.showSnackBar("Item has been added to your cart!", "success");
                infoPopup.showPopup("Item has been added to your cart !");
            }
            else
                snackBar.showSnackBar("Item has already in your cart.", "info");
            handleCloseItemFormPopup(false);
        }
    }
    const LeftArrow = () => {
        const visibility: any = React.useContext(VisibilityContext);
        const isFirstItemVisible = visibility.useIsVisible('first', true);
        return (
            <button style={visibility.scrollContainer.current && visibility.scrollContainer.current.scrollWidth > visibility.scrollContainer.current.clientWidth ? { display: 'block' } : { display: 'none' }} disabled={position === 0 ? true : false} className='BackCarouselIcon' onClick={() => { visibility.scrollPrev() }}><span > <ArrowBackIosNewIcon /></span ></button>
        );
    };

    const RightArrow = () => {
        const visibility: any = React.useContext(VisibilityContext);
        let isDisable = visibility.scrollContainer.current && (visibility.scrollContainer.current.scrollWidth - visibility.scrollContainer.current.scrollLeft) === visibility.scrollContainer.current.clientWidth;
        return (
            <button style={visibility.scrollContainer.current && visibility.scrollContainer.current.scrollWidth > visibility.scrollContainer.current.clientWidth ? { display: 'block' } : { display: 'none' }} disabled={isDisable ? true : false} className='ForwardCarouselIcon' onClick={() => { visibility.scrollNext() }}><span ><ArrowForwardIosIcon /></span></button>
        );
    };
    const getList =
        filteredAllItemList && filteredAllItemList.length > 0 ? (
            filteredAllItemList.map((list: ISoftwareAccessItem, i: number) => (
                <div className="listItemDiv" key={i}>
                    <div className="subcategoryImage">
                        <img alt='' src={process.env.PUBLIC_URL + list.imageUrl}></img>
                        {/* <div className="image-container" style={{ backgroundImage: setBackGroundImageURL(list.imageUrl) }}>
</div> */}
                    </div>
                    <div className="subcategoryDesc">
                        <div className="subcategoryImageDesc">
                            <span title={list.description}>{list.description} </span>
                        </div>
                        <div className="subcategoryAddtoCart">
                            <img
                                alt=""
                                src={Add}
                                onClick={() => {
                                        if (list.hasForm && list.catagoryItemsform) {
                                            setItemData(list);
                                            setShowFormPopup(true);
                                        }
                                        else if (list.suggestiveSellCategoryItems && list.suggestiveSellCategoryItems.length > 0) {
                                            if (pStore.setCartList(uStore.user.userEmail, list)) {
                                                // snackBar.showSnackBar("Item has been added to your cart!", "success");
                                                setShowSuggestedItemPopup(true);
                                                setCurrentItemDetails(list);
                                                setSuggestiveItemList(list.suggestiveSellCategoryItems);
                                                infoPopup.showPopup("Item has been added to your cart !");
                                            }
                                        }
                                        else {
                                            if (pStore.setCartList(uStore.user.userEmail, list))
                                                // snackBar.showSnackBar("Item has been added to your cart!", "success");
                                                infoPopup.showPopup("Item has been added to your cart !");
                                        }
                                }}
                            ></img>
                        </div>
                    </div>
                </div>
            ))

        ) : (
            <label>Item not found</label>
        );

    const restorePosition = React.useCallback(
        ({
            scrollContainer
        }: any) => {
            if (scrollContainer.current) {
                scrollContainer.current.scrollLeft = position;

                // scrollToItem(getItemById('test15'), 'auto');
            }
        },
        [position]
    );


    // const savePosition = React.useCallback(
    //     ({ scrollContainer }: scrollVisibilityApiType) =>
    //         !!scrollContainer.current &&
    //         setPosition(scrollContainer.current.scrollLeft),
    //     []
    // );

    const savePosition = (sccrollPos: any) => {
        setPosition(sccrollPos.scrollContainer.current.scrollLeft);
        // setCurrentContainerWidth(sccrollPos.scrollContainer.current.scrollWidth)
    }
    useEffect(() => {
        getItemList();
    }, []);
    return (
        <>

            <Card
                className="ItemListDiv"
                sx={{
                    minWidth: "85%",
                    maxWidth: "100%",
                    minHeight: "300px",
                    textAlign: "center",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
            >
                <CardContent>
                    <div className="ItemListTitleDiv">
                        <h2 onClick={() => {
                            // navigate('?category=software_access');
                            navigate(`?category=${props.categoryName.toLowerCase().replaceAll(" ", '_')}`);
                        }}>{props.categoryName}</h2>
                    </div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <ScrollMenu onInit={restorePosition}
                                onScroll={savePosition}
                                transitionDuration={1500}
                                LeftArrow={LeftArrow} RightArrow={RightArrow} children={getList} />
                        </>
                    )}
                </CardContent>
            </Card>
            <CategoryItemForm itemData={itemData} isOpenFormDialog={showFormPopup} handleCloseItemFormPopup={(showFormPopup) => { handleCloseItemFormPopup(showFormPopup) }} addToCartItem={(itemData) => AddToCartItem(itemData)} />
            <SuggestiveSellItemList currentItemList={currentItemDetails} suggestiveItemList={suggestiveItemList} isOpenSuggestedItemDialog={showSuggestedItemPopup} handleCloseSuggestedItemPopup={(showSuggestedItemPopup) => { handleCloseSuggestedItemPopup(showSuggestedItemPopup) }} />
            <ShowNewEmployeeItems currentItemList={currentItemDetails} suggestiveItemList={suggestiveItemList} isOpenSuggestedItemDialog={showNewEmployeeItemsPopup} handleCloseNewEmployeeItemPopup={(showNewEmployeeItemPopup) => { handleCloseNewEmployeeItemPopup(showNewEmployeeItemPopup) }} />
        </>
    )
}

export default MainScreenItemList