import { Box, FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select, { Props as ReactSelectProps } from 'react-select';
import makeAnimated from 'react-select/animated';

interface MultiSelectDropDownProps {
    label: string;
    options: any;
    onChange?: ReactSelectProps<any, true>['onChange'];
    placeholder?: string;
    selectedValues?: any;
    errors?: any;
    name: string;
    control: any;
    defaultValue?: any
}

const InputMultiSelectFormControl: React.FC<MultiSelectDropDownProps> = (props) => {

    const { name, label, control, options, errors, onChange, placeholder, selectedValues, defaultValue } = props;
    const [selectedOptions, setSelectedOptions] = useState<any | undefined>(selectedValues);

    const handleSelectChange: ReactSelectProps<any, true>['onChange'] = (selectedValues, actionMeta) => {
        // const newSelectedOption = selectedValues.map(opt => ({
        //     label: opt.label,
        //     innerValue: opt.value,
        //     value: opt.value + "_" + Math.random()
        // }));
        setSelectedOptions(selectedValues as any[]);
        if (onChange) {
            onChange(selectedValues, actionMeta);
        }

        // options.push(selectedValues);
    };
    const animatedComponents = makeAnimated();

    const customFilter = (option: any, rawInput: any) => {
        const inputValue = rawInput.toLowerCase();
        const label = option.label.props.children[1].toLowerCase();

        return label.includes(inputValue);
    };

    useEffect(() => {
        if (defaultValue && defaultValue.length > 0) {
            let results = options.filter(function (obj: any) {
                return defaultValue.some(function (obj2: any) {
                    return obj.value === obj2.value;
                });
            });
            setSelectedOptions(results);
        }
    }, [defaultValue])

    // const customOptions = options.map((option: any) => {
    //     // Disable the option if it's already selected
    //     const isOptionSelected = selectedOptions.some((selectedOption: any) => selectedOption.value === option.value);
    //     return {
    //         ...option,
    //         isDisabled: isOptionSelected,
    //     };
    // });

    return (
        <>
            <InputLabel>{label}</InputLabel>
            <FormControl error={Boolean(errors[name])}>
            <Controller
                render={({ field }) => (
                        <Select
                        className='form-control'
                            options={options}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        value={selectedOptions}
                        filterOption={customFilter}
                            defaultValue={defaultValue}
                        onChange={(selectedValues, actionMeta) => {
                            handleSelectChange(selectedValues, actionMeta);
                            field.onChange(selectedValues);
                        }}
                        placeholder={placeholder || 'Select options...'}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                    />
                )}
                name={name}
                control={control}
                    defaultValue={selectedValues}
            />
                {errors && <FormHelperText sx={{ color: "red" }}>{errors[name]?.message}</FormHelperText>}
            </FormControl>
        </>
    );
};

export default InputMultiSelectFormControl;