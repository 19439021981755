import { Box, Breadcrumbs, Button, Grid, List, ListItem, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCartListStore, userStore } from '../store/store';
import { useConfirmPopup } from '../components/context/confirmation-context';
import { useSnackBar } from '../components/context/snackbar-context';
import Navbar from '../components/Navbar';
import { UserRequestListService } from '../services/UserRequestListService';
import { NavLink, useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { GetErrorMessageFromResponse } from '../common/commonFunction';
import { ICartItem } from '../types/categoryListType';
import Loader from '../components/common components/Loader';
import InputCheckboxFormControl from '../components/controls/InputCheckboxFormControl';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserDetialsService } from '../services/UserDetialsService';
import InputSelectControlWithSearch from './../components/controls/InputSelectControlWithSearch';
import AddUpdateAddress from './Shipping Address/AddUpdateAddress';
import CircularProgress from '@mui/material/CircularProgress';

// interface ConfirmRequestPage {
//     isSteper: boolean,
//     changeStep: (data: any) => void;
// }
const ConfirmRequestPage: React.FC = () => {
    const [isLoading, setIsloading] = useState<boolean>(false);
    const [isOpenAddUpdateAddressDialog, setIsOpenAddUpdateAddressDialog] = React.useState(false);
    const [hasHardware, setHasHardware] = useState<boolean>(false);
    const [BehalfUserList, setBehalfUserList] = React.useState<any>();
    const navigate = useNavigate();
    const cStore: any = useCartListStore();
    const uStore: any = userStore();
    const snackbar = useSnackBar();
    const confirmPopupService = useConfirmPopup();
    const [showLoader, setShowLoader] = React.useState(false);

    const handleRemove = (item: any) => {
        confirmPopupService.showPopup("Are you sure you want to remove this item from cart?", () => { handleCancelRemoveItemClose(item) });
    }
    const handleCancelRequest = () => {
        confirmPopupService.showPopup("Are you sure you want to cancel entire request?", () => { cancelRequest(); });
    }
    const validationSchema = Yup.object()
        .shape({
            hasBehalfUser: Yup.boolean(),
            behalfUserId: Yup.string().when('hasBehalfUser', ([hasBehalfUser], schema) => {
                return hasBehalfUser ? schema.required("Please select behalf of user") : schema;
            }),
        }).required();

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const showBehalfUser = watch('hasBehalfUser', false);
    const behalfUserId = watch('behalfUserId', "");

    const handleClearCart = () => {
        confirmPopupService.showPopup("This will remove ALL items from your cart. Are you sure you want to continue?", handleConfirmClearCart);
    }

    const handleConfirmClearCart = () => {

        if (cStore.clearCart(uStore.user.userEmail)) {
            confirmPopupService.hidePopup();
            snackbar.showSnackBar("Your cart has been cleared successfully.", "success");
        }
        else {
            confirmPopupService.hidePopup();
            snackbar.showSnackBar("Your cart has not been cleared.", "error");
        }
        confirmPopupService.hidePopup();
    }

    const cancelRequest = () => {
        if (cStore.clearCart(uStore.user.userEmail)) {
            navigate('/search');
            confirmPopupService.hidePopup();
            snackbar.showSnackBar("Your request has been canceled.", "success");
        }
        else {
            confirmPopupService.hidePopup();
            snackbar.showSnackBar("Your request has not been canceled.", "error");
        }
    }

    const handleConfirmRequest = async () => {
        let items = [];
        setShowLoader(true);
        if (cStore.cartList.length > 0)
            items = cStore.cartList;

        // const cXMlRequestPayload = ITCatalogOrderXMLCompomnent(items);
        // const parser = new DOMParser();
        // const xmlDoc = parser.parseFromString(cXMlRequestPayload, 'application/xml');

        UserRequestListService.SaveConfirmRequestList(behalfUserId, items, uStore.getUser().userEmail)
            .then((res: any) => {
                if (res) {
                    if (res.data.code === 200 && res.data.data === "success") {
                        cStore.clearCart(uStore.user.userEmail);
                        snackbar.showSnackBar('Your request is successfully sent.', "success");
                        navigate('/pending-request')
                    }
                    else {
                        snackbar.showSnackBar('Your request is not sent successfully.', "error");
                    }
                    setShowLoader(false);
                }
            })
            .catch(err => {
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error");
                setShowLoader(false);
            })
    }

    const ConvertFormDataToArray = (stringData: any) => {
        const parsedItemObject: any = JSON.parse(stringData);
        return Object.keys(parsedItemObject);

    }
    const handleCancelRemoveItemClose = (item: any): void => {
        let msg: boolean = cStore.RemoveItemFromCart(uStore.user.userEmail, item);

        if (msg) {
            confirmPopupService.hidePopup();
            snackbar.showSnackBar("Item has been removed from your cart.", "success");
        }
        else {
            confirmPopupService.hidePopup();
            snackbar.showSnackBar("Item has not been removed from your cart.", "error");
        }
        CheckCartItemHasHardware();
    };
    const getBehalfUserOptions = async () => {
        let res = await UserDetialsService.GetAllUsers();
        let opt: any = [];
        if (res && res.data.code === 200) {
            res.data.data.map((user: any) => {
                opt.push({ label: user.firstName + ", " + user.lastName + " - " + user.userEmail, value: (user.id).toString() })
            })
            setBehalfUserList(opt);
        }
    }

    const CheckCartItemHasHardware = () => {
        setHasHardware(false);
        if (cStore.cartList && cStore.cartList.length > 0) {
            cStore.cartList.map((list: any) => {
                if (list.category.categoryName.toLowerCase() === "hardware") {
                    setHasHardware(true);
                }
            })
        }
    }
    const handleCloseShippingAddressPopup = (isShowPopup: boolean) => {
        setIsOpenAddUpdateAddressDialog(isShowPopup);
    }

    const AddUpdateShippingAddress = (addressDetails: any) => {

    }

    useEffect(() => {
        CheckCartItemHasHardware();
        setIsloading(false);
    }, [cStore.cartList]);

    useEffect(() => {
        setIsloading(true);
        CheckCartItemHasHardware();
        getBehalfUserOptions();
        setIsloading(false);
        setShowLoader(false);
    }, []);
    return (
        <>
            <div className='breadcumDiv'>
                <Breadcrumbs
                    separator={<ArrowForwardIosIcon />}
                    aria-label="breadcrumb"
                >
                    <NavLink to="/search" title="Make a Request">
                        Make a Request
                    </NavLink>
                    <span className="active-breadcumb">  Confirm Request</span>
                </Breadcrumbs>
            </div>
            <Navbar title="Confirm Request " />


            {
                isLoading ? (<Loader />) : (
                    <Paper
                        elevation={3}
                        sx={{
                            m: "auto",
                            minWidth: "60%",
                            maxWidth: "60%",
                            textAlign: "start",
                            pr: 0,
                        }}
                    >
                        <form onSubmit={handleSubmit(async (data: any, e: any) => {
                            handleConfirmRequest();
                        })}
                        >
                            <Typography className="cartHeader" variant="h5" component="div">
                                Items in your cart
                            </Typography>
                            <List sx={{ margin: "auto", textAlign: "center", width: "100%", mt: 2 }}>
                                {
                                    cStore.cartList && cStore.cartList.length > 0 ? (
                                        cStore.cartList.map((list: ICartItem) => {
                                            return (
                                                <ListItem key={Number(list.id)}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            alignItems: "center",
                                                            flexDirection: "row",
                                                            bgcolor: "background.paper",
                                                            p: 1,
                                                            borderRadius: 1,
                                                        }}
                                                    >
                                                        <div className="resulItmeImage">
                                                            <img alt="" src={list.imageUrl}></img>
                                                        </div>
                                                        <div className="resultItemDesc">
                                                            <div className="resultItemShortDesc">
                                                                <span>{list.description}</span>
                                                            </div>
                                                            <div className="resultItemLongDesc">
                                                                {
                                                                    list.hasForm && list.formDataWithTitle ? (
                                                                        ConvertFormDataToArray(list.formDataWithTitle).map((data: any) => {
                                                                            return (<p>{data} :  {JSON.parse(list.formDataWithTitle)[data]}</p>)
                                                                        })
                                                                    ) : (<p>{list.longDesc}</p>)
                                                                }
                                                                {/* {list.longDesc} */}
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "left", marginTop: "10px" }}>
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        handleRemove(list);
                                                                    }}
                                                                >
                                                                    Remove from cart
                                                                </Button>
                                                            </div>

                                                        </div>
                                                    </Box>
                                                </ListItem>)
                                        })
                                    )
                                        :
                                        (
                                            <label style={{ fontSize: "24px" }}>Your cart is empty</label>
                                        )
                                }
                                {
                                    cStore.cartList && cStore.cartList.length > 0 &&
                                    (
                                        <>
                                            {/* <div>
                                                {
                                                    hasHardware &&
                                                    (<div className='ShippingAddressDiv'>
                                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <h2>Your Addresses</h2>
                                                            <Button sx={{ height: "50px" }} variant="contained" onClick={() => {
                                                                setIsOpenAddUpdateAddressDialog(true);
                                                            }}> + Add a new address</Button>
                                                        </Box>
                                                        <hr></hr>

                                                    </div>)
                                                }
                                            </div> */}
                                            <div className='BehalfUserDiv'>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={7}>
                                                        <div className='row'>
                                                            <div className="form-lable col-4">
                                                                <label></label>
                                                            </div>
                                                            <div className='col-8 InputCheckBoxControl'>
                                                                <InputCheckboxFormControl
                                                                    errors={errors}
                                                                    control={control}
                                                                    name="hasBehalfUser"
                                                                    label="I am making this request on behalf of another user"
                                                                />
                                                            </div>

                                                        </div>
                                                    </Grid>
                                                    {
                                                        showBehalfUser &&
                                                        (
                                                            <Grid item xs={12} md={7}>
                                                                <div className='row'>
                                                                    <div className="form-lable col-4" style={{ textAlign: "left", marginBottom: "5px" }}>
                                                                        <label>Specify the user below:</label>
                                                                    </div>
                                                                    <div className='col-8'>
                                                                        <InputSelectControlWithSearch
                                                                            errors={errors}
                                                                            control={control}
                                                                            name="behalfUserId"
                                                                            label=""
                                                                            options={BehalfUserList}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </div>
                                        </>
                                    )
                                }

                            </List>

                            {
                                cStore.cartList && cStore.cartList.length > 0 ?
                                    (
                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                                            <Button
                                                sx={{ marginBottom: 2, marginRight: 1, height: "52px" }} size="large" variant="contained"
                                                onClick={() => {
                                                    handleClearCart();
                                                }}
                                                disabled={cStore.totalCartItem === 0 ? true : false}
                                            >
                                                Clear cart
                                            </Button>
                                            <Button type="submit" sx={{ marginBottom: 2, marginRight: 1, height: "52px", width: "165px" }} size="large" variant="contained">{showLoader ? <CircularProgress sx={{ width: "35px", height: "35px" }} color="inherit" /> : "Confirm Request"}</Button>
                                            <Button sx={{ marginBottom: 2, marginRight: 1, height: "52px" }} size="large" variant="contained"
                                                onClick={() => {
                                                    handleCancelRequest();
                                                }}>Cancel Request</Button>
                                        </div>)
                                    : ""
                            }
                        </form>
                    </Paper>)
            }
            <AddUpdateAddress isOpenAddUpdateAddressDialog={isOpenAddUpdateAddressDialog} handleCloseShippingAddressPopup={(showPopup) => handleCloseShippingAddressPopup(showPopup)} AddUpdateShippingAddress={(addressDetails: any) => { AddUpdateShippingAddress(addressDetails) }} />
        </>

    );
}

export default ConfirmRequestPage