import { Alert, AppBar, Button, Card, CardContent, IconButton, Toolbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import InputFormControl from '../controls/InputFormControl';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from '../../msal.config';
import { useMsal } from '@azure/msal-react';
import useStore, { useCartListStore, userStore } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { UserDetialsService } from '../../services/UserDetialsService';
import { useSnackBar } from '../context/snackbar-context';
import { GetErrorMessageFromResponse } from '../../common/commonFunction';
import { useAuth } from '../context/auth-context';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';


const ExternalLogin = () => {
    const [error, setError] = useState("");
    const { instance } = useMsal();
    const navigate: any = useNavigate();
    const { setIsLoginRequired, reset } = useStore();
    const uStore: any = userStore();
    const snackbar = useSnackBar();
    const auth: any = useAuth();
    const validationSchema = Yup.object()
        .shape({
            email: Yup.string().email().required()
        }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        const user = uStore.getUser();
        const accessTokenRequest = {
            scopes: ["user.read"],
        };
        const accessToken = Cookies.get("access-token");
        if (user && accessToken) {
            setIsLoginRequired(false);
            if (user.role === "Administrator") {
                navigate('/pending-request');
            }
            else {
                navigate('/search');
            }
        }
            // if (user) {
            //     if (user.role === "Administrator") {
            //         navigate('/pending-request');
            //     }
            //     else {
            //         navigate('/search');
            //     }
            // }
        else {
            reset();
            uStore.setUser(undefined);
            uStore.reset();
            localStorage.clear();
            sessionStorage.clear();
        }
    }, []);

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Box sx={{ display: "flex", m: 0, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <Toolbar>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                >
                                    {/* <img style={{ width: "45px", height: "auto" }} alt="" src={process.env.PUBLIC_URL + '/ITCatalogLogo.png'} /> */}
                                    <TextSnippetIcon onClick={() => { navigate('/search') }} sx={{ mr: 2, width: "45px", height: "auto" }} />
                                </IconButton>
                                <div
                                    className='mainTitle'
                                >
                                    TechHub
                                </div>
                            </Toolbar>
                        </div>
                    </Box>
                </AppBar >
            </Box >
            <div className='main-layout'>
                <Box sx={{ width: "500px", maxWidth: "100%" }}>
                    <Card sx={{ borderRadius: "15px" }} variant="outlined">
                        <CardContent>
                            <div className='loginLabel'>
                                <label>Single Sign-On</label>
                            </div>
                            <form onSubmit={handleSubmit(async (data: any) => {
                                console.log(data);
                                reset();
                                uStore.setUser(undefined);
                                uStore.reset();
                                localStorage.clear();

                                uStore.setIsSSOlLoginInProgress(true);
                                instance
                                    .loginRedirect({
                                        authority: msalConfig.auth.authority,
                                        scopes: ["openid", "profile", "user.read"],
                                        loginHint: data.email,
                                    })
                                    .then((res) => {
                                    }).catch((e: any) => {
                                        console.log("error", e);

                                        snackbar.showSnackBar("Somthing went wrong!", "error");
                                        navigate('/account/external-login');
                                    });
                            })}>
                                <div >
                                    <InputFormControl
                                        name="email"
                                        label="User Name"
                                        errors={errors}
                                        control={control}
                                        type=""
                                        initialValue={""}
                                    />
                                </div>
                                {
                                    error && <Alert sx={{ mt: 2, mx: "auto", width: "88%" }} icon={false} severity="error"><b>{error}</b></Alert>
                                }

                                <div className='loginBtn'>
                                    <Button type="submit" variant='outlined' disabled={false}>
                                        Login using SSO
                                    </Button>
                                </div>
                                <div className='.sign-in-or-wrapper'>
                                    <span>OR</span>
                                </div>
                                <div className='loginBtnSSO'>
                                    <a href='/account/login'>
                                        Return to Sign In Page
                                    </a>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </Box>
            </div>
        </>
    )
}

export default ExternalLogin