import axios, { AxiosResponse } from "axios";
import {
  AddNote,
  CancelItemFromRequest,
  CancelRequest,
  ChangeItemStatusOfSpecificRequest,
  ChangeRequestStatus,
  CompleteRequest,
  GetHistoricalRequestList,
  GetPendingRequestList,
  GetUserRequestList,
  SaveConfirmRequest,
  UpdateStartDateIntoForm,
} from "../common/ApiEndPoints";
import instance from "../common/http.common";
import { useCartListStore } from "../store/store";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";
const _baseUrl: string = `${_rootUrl}/UserRequestList`;

const GetAllUserRequestList = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + GetUserRequestList);
};

const GetAllPendingRequestList = async (
  userMail: any
): Promise<AxiosResponse<any>> => {
  return await instance.get(
    `${_baseUrl + GetPendingRequestList}/?user=${userMail}`
  );
};

const GetAllHistoricalRequestList = async (
  userMail: any
): Promise<AxiosResponse<any>> => {
  return await instance.get(
    `${_baseUrl + GetHistoricalRequestList}/?user=${userMail}`
  );
};

const CancelRequestList = async (id: any): Promise<AxiosResponse<any>> => {
  return await instance.delete(`${_baseUrl + CancelRequest}/?requestId=${id}`);
};

const CompleteRequestList = async (id: any): Promise<AxiosResponse<any>> => {
  return await instance.put(_baseUrl + CompleteRequest, id);
};

const AddNoteForRequest = async (
  requestId: any,
  noteText: string,
  user: string
): Promise<AxiosResponse<any>> => {
  let data = {
    requestId: requestId,
    note: noteText,
    userName: user,
  };
  return await instance.post(_baseUrl + AddNote, data);
};

const CancelItemOfRequest = async (
  requestId: any,
  itemId: any
): Promise<AxiosResponse<any>> => {
  return await instance.delete(
    `${
      _baseUrl + CancelItemFromRequest
    }/?requestId=${requestId}&&itemId=${itemId}`
  );
};

const ChangeCurrentRequestStatus = async (
  requestWithItem: any,
  newStatus: string,
  currentUser: string,
  deniedReason: any = null,
  cXMlRequestPayload: any = null,
  ItemsWithGeneralHardwareRequest: any = null,
  ItemsWithoutGeneralHardwareRequest: any = null
): Promise<AxiosResponse<any>> => {
  let data = {
    requestId: requestWithItem.id,
    newStatus: newStatus,
    currentUser: currentUser,
    deniedReason: deniedReason,
    Items: requestWithItem.items,
    ItemsWithoutGeneralHardwareRequest: ItemsWithoutGeneralHardwareRequest,
    ItemsWithGeneralHardwareRequest: ItemsWithGeneralHardwareRequest,
    InsightDetails: {
      cXMLRequestPayload: cXMlRequestPayload,
    },
  };
  return await instance.post(_baseUrl + ChangeRequestStatus, data);
};

const ChangeCurrentRequestItemStatus = async (
  requestId: any,
  item: any,
  newStatus: string,
  currentUser: string,
  deniedReason: any = null,
  cXMlRequestPayload: any = null,
  SysAidpayload: any = null
): Promise<AxiosResponse<any>> => {
  let data = {
    requestId: requestId,
    newStatus: newStatus,
    currentUser: currentUser,
    deniedReason: deniedReason,
    Items: item,
    InsightDetails: {
      cXMLRequestPayload: cXMlRequestPayload,
    },
    sysAidDetails: {
      sysAidPayload: SysAidpayload,
      sysAidTemplateId: item.sysAidTemplateId,
    },
  };
  return await instance.post(
    _baseUrl + ChangeItemStatusOfSpecificRequest,
    data
  );
};
const SaveConfirmRequestList = async (
  behalfUserId: any,
  item: any,
  username: string
): Promise<AxiosResponse<any>> => {
  let data = {
    behalfUserId: behalfUserId,
    username: username,
    items: item,
    status: "Pending",
  };
  return await instance.post(_baseUrl + SaveConfirmRequest, data);
};

const UpdateStartDateIntoItemFormDetails = async (
  selectedRequestId: any,
  selectedItemId: any,
  formDetailsWithTitle: any,
  formData: any
): Promise<AxiosResponse<any>> => {
  let data = {
    RequestId: selectedRequestId,
    ItemId: selectedItemId,
    formDetailsWithTitle: JSON.stringify(formDetailsWithTitle),
    formData: JSON.stringify(formData),
  };
  return await instance.put(_baseUrl + UpdateStartDateIntoForm, data);
};

export const UserRequestListService = {
  GetAllUserRequestList,
  SaveConfirmRequestList,
  GetAllPendingRequestList,
  CancelRequestList,
  CompleteRequestList,
  GetAllHistoricalRequestList,
  AddNoteForRequest,
  ChangeCurrentRequestStatus,
  CancelItemOfRequest,
  ChangeCurrentRequestItemStatus,
  UpdateStartDateIntoItemFormDetails,
};
