import { AxiosResponse } from "axios";
import instance from "../../common/http.common";
import {
  AddOrUpdateCategoryItem,
  AddUpdateCategory,
  DeleteCategoryItem,
  GetCategoryItems,
} from "../../common/ApiEndPoints";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";
const _baseUrl: string = `${_rootUrl}/CategoryItem`;

const getCategoryItemList = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + GetCategoryItems);
};

const removeCategoryItem = async (id: number): Promise<AxiosResponse<any>> => {
  return await instance.delete(
    `${_baseUrl + DeleteCategoryItem}/?categoryItemId=${id}`
  );
};

const addUpdateCategoryItem = async (
  categoryitem: any
): Promise<AxiosResponse<any>> => {
  const fromdata = new FormData();
  fromdata.append("description", categoryitem.description);
  fromdata.append("formId", categoryitem.formId);
  fromdata.append("hasForm", categoryitem.hasForm);
  fromdata.append("id", categoryitem.id);
  fromdata.append("image", categoryitem.image);
  fromdata.append("itemSubCategoryId", categoryitem.itemSubCategoryId);
  fromdata.append("longDesc", categoryitem.longDesc);
  fromdata.append("partID", categoryitem.partID);
  fromdata.append("price", categoryitem.price);
  fromdata.append("uom", categoryitem.uom);
  fromdata.append("shortDescription", categoryitem.shortDescription);
  fromdata.append(
    "SuggestiveCategoryItemID",
    JSON.stringify(categoryitem.SuggestiveCategoryItemID)
  );
  return await instance.post(_baseUrl + AddOrUpdateCategoryItem, fromdata);
};

export const CategoryItemService = {
  getCategoryItemList,
  removeCategoryItem,
  addUpdateCategoryItem,
};
