import React, { useEffect, useState } from 'react'
import { CategoryService } from '../../services/CategoryModule/CategoryService';
import { useSnackBar } from '../../components/context/snackbar-context';
import { GetErrorMessageFromResponse } from '../../common/commonFunction';
import { Grid, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Box, Breadcrumbs } from '@mui/material';
import Navbar from '../../components/Navbar';
import { Icategory } from '../../types/CategoryType';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirmPopup } from '../../components/context/confirmation-context';
import AddUpdateCategory from './AddUpdateCategory';
import Loader from '../../components/common components/Loader';
import AddIcon from "@mui/icons-material/Add";
import { NavLink } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useStore, { userStore } from '../../store/store';

const CategoryList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [categoryList, setCategoryList] = useState<Icategory[]>([]);
    const [OpenAddUpdateCategoryPopup, SetOpenAddUpdateCategoryPopup] = useState<boolean>(false);
    const uStore: any = userStore();
    const { setAllCategoryList, fetchAllItemList } = useStore();
    const [categoryDetails, setCategoryDetails] = useState<any>({
        id: 0,
        categoryName: ""
    });
    const confirmPopupService = useConfirmPopup();
    const snackbar = useSnackBar();
    const GetCategoryList = () => {
        CategoryService.getCategory()
            .then(async (res: any) => {
                if (res) {
                    await fetchAllItemList();
                    setCategoryList(res.data.data);
                    setAllCategoryList(res.data.data);
                    setIsLoading(false);
                }
            })
            .catch(err =>
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error"))
    }

    const handleDelete = (id: number) => {
        confirmPopupService.showPopup("Are you sure you want to remove this category?", () => { deleteCategory(id) });
    }

    const deleteCategory = async (id: number) => {
        let res = await CategoryService.removeCategory(id);
        if (res.data) {
            if (res.data.code === 200 && res.data.data === "success") {
                confirmPopupService.hidePopup();
                GetCategoryList();
                snackbar.showSnackBar('Category has been removed successfully.', "success");
            }
            else {
                snackbar.showSnackBar('Category has not removed successfully.', "error");
            }
        }
        else {
            snackbar.showSnackBar('Internal server error.', "error");
        }
    }

    const handleCloseAddUpdateCategoryPopup = (isShowPopup: boolean) => {
        SetOpenAddUpdateCategoryPopup(isShowPopup);
    }

    const AddUpdateCategoryDetails = (id: number, categoryName: string) => {
        CategoryService.addOrUpdateCategory(id, categoryName)
            .then((res: any) => {
                if (res && res.data.code === 200) {
                    handleCloseAddUpdateCategoryPopup(false);
                    snackbar.showSnackBar(res.data.data, "success");
                    GetCategoryList();
                }
                else {
                    snackbar.showSnackBar(res.data.data, "error");
                }   
            })
            .catch(err => {
                handleCloseAddUpdateCategoryPopup(false);
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error")
            })
    }
    useEffect(() => {
        GetCategoryList();
    }, [])

    return (
        <> <div className='breadcumDiv'>
            {
                uStore.getUser().role === "Administrator" ?
                    (<Breadcrumbs
                        separator={<ArrowForwardIosIcon />}
                        aria-label="breadcrumb"
                    >
                        <NavLink to="/pending-request" title="Pending Request">
                            Pending Request
                        </NavLink>
                        <span className="active-breadcumb">Category Management</span>
                    </Breadcrumbs>) :
                    (<Breadcrumbs
                        separator={<ArrowForwardIosIcon />}
                        aria-label="breadcrumb"
                    >
                        <NavLink to="/search" title="Make A Request">
                            Make A Request
                        </NavLink>
                        <span className="active-breadcumb">Category Management</span>
                    </Breadcrumbs>)
            }

        </div>
            <Navbar title="Category Management" />
            {
                isLoading ? (
                    <Loader />
                ) :
                    (
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid sx={{ m: "auto" }} item xs={8}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        maxWidth: "100%",
                                        minHeight: "70%",
                                        maxHeight: "100%",
                                        margin: 2,
                                        textAlign: "left",
                                    }}
                                >
                                    <Box sx={{ display: "flex", p: 2, justifyContent: "space-between", alignItems: "end" }}>
                                        <h1 style={{ fontWeight: "bold" }}>Category List</h1>
                                        <Button color="primary" sx={{ backgroundColor: "rgb(109, 135, 255)", marginBottom: 2, marginRight: 1 }} size="large" variant="contained"
                                            onClick={() => {
                                                let data = {
                                                    id: 0,
                                                    categoryName: ""
                                                }
                                                setCategoryDetails(data);
                                                SetOpenAddUpdateCategoryPopup(true);
                                            }}
                                        >
                                            <AddIcon /> Add Category
                                        </Button>
                                    </Box>

                                    <Paper className='requestTableDiv' sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ minHeight: 500, maxHeight: 600 }}>
                                            <Table stickyHeader aria-label="sticky table" sx={{ fontWeight: "bold" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ minWidth: "130px" }}>Category Name</TableCell>
                                                        <TableCell style={{ minWidth: "100px" }}>Category Status</TableCell>
                                                        <TableCell style={{ minWidth: "20px" }}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {categoryList && categoryList
                                                        .map((list: Icategory, index: Number) => {
                                                            return (
                                                                <TableRow key={Number(list.id)} hover tabIndex={-1}>
                                                                    <TableCell style={{ minWidth: "130px", fontSize: "18px" }}>{list.categoryName}</TableCell>
                                                                    <TableCell style={{ minWidth: "100px", fontSize: "18px" }}>{list.isDeleted === true ? "In-Active" : "Active"}</TableCell>
                                                                    <TableCell style={{ width: "20px" }}>
                                                                        <EditIcon
                                                                            onClick={() => {
                                                                                setCategoryDetails(list);
                                                                                SetOpenAddUpdateCategoryPopup(true);
                                                                            }}
                                                                            style={{ marginRight: 4 }} />
                                                                        <DeleteIcon
                                                                            onClick={() => { handleDelete(Number(list.id)); }} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
            }

            <AddUpdateCategory isOpenAddUpdateCategoryDialog={OpenAddUpdateCategoryPopup} AddUpdateCategoryDetails={(id: number, categoryName: string) => { AddUpdateCategoryDetails(id, categoryName); }} handleCloseAddUpdateCategoryPopup={(isShowPopup: boolean) => { handleCloseAddUpdateCategoryPopup(isShowPopup) }} category={categoryDetails} />
        </>
    )
}



export default CategoryList