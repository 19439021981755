import { IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import React, { useState } from 'react'
import { userStore } from '../store/store';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface PendingRequestItemsKenbanMenusProps {
    list: any,
    item: any,
    handleCancelItemOfRequest: (requestId: any, itemId: any) => void,
    approveItemOfRequest: (requestId: any, item: any, currentStatus: string, createdUserEmail: string, CreatedUserName: string) => void
    deniedItemOfRequest: (requestId: any, item: any, currentStatus: string, deniedReason: string, handleDeniedItemReasonCloseDialog: () => void) => void
}
const PendingRequestItemsKenbanMenu: React.FC<PendingRequestItemsKenbanMenusProps> = (props) => {

    const [denyReasonText, setDenyReasonText] = useState<string>("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDeniedReasonDialog, setOpenDeniedReasonDialog] = React.useState(false);
    const open = Boolean(anchorEl);
    const uStore: any = userStore();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeniedItemReasonCloseDialog = () => {
        setOpenDeniedReasonDialog(false);
    };
    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    props.list.isOwnRequest === false &&
                    (
                        <>
                            <MenuItem disabled={props.item.status === "Approved" ? true : false}
                                onClick={() => {
                                    props.approveItemOfRequest(props.list.id, props.item, "Approved", props.list.createdUserEmail, props.list.createdUserName);
                                }}>
                                Approve
                            </MenuItem>
                            <MenuItem disabled={props.item.status === "Denied" ? true : false}
                                onClick={() => {
                                    setOpenDeniedReasonDialog(true);
                                    // props.deniedRequest(props.list.id, "Denied");
                                }}>
                                Deny
                            </MenuItem>
                        </>
                    )
                }
                <MenuItem disabled={(props.item.status === "Approved" && (uStore.getUser().role).toLowerCase() !== "administrator") ? true : false} onClick={(e) => {
                    props.handleCancelItemOfRequest(props.list.id, props.item.id);
                }}>
                    Cancel Item
                </MenuItem>
            </Menu>


            <Dialog className='DeniedRequestDialog' sx={{ width: "100%" }} open={openDeniedReasonDialog} onClose={handleDeniedItemReasonCloseDialog}>
                <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>Denied Item Reason</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ p: 1 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="denyReason"
                            label="Deny Reason"
                            type="text"
                            value={denyReasonText}
                            onChange={(event) => { setDenyReasonText(event?.target.value) }}
                            multiline
                            rows={5}
                            variant="outlined"
                        />
                    </DialogContentText>

                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleDeniedItemReasonCloseDialog}>Cancel</Button>
                    <Button sx={{ fontSize: "20px" }} variant="contained" onClick={() => {
                        props.deniedItemOfRequest(props.list.id, props.item, "Denied", denyReasonText, handleDeniedItemReasonCloseDialog)
                    }}>Add</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default PendingRequestItemsKenbanMenu