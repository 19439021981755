import { useMsal } from "@azure/msal-react";
import { userStore } from "../store/store";
import axiosInstance from "./http.common";
import { loginRequest } from "../msal.config";
import Cookies from "js-cookie";

export default function SetupInterceptors(store: any) {
  const pStore: any = userStore();
  const { instance } = useMsal();

  axiosInstance.interceptors.request.use(function (config: any) {
    const user = pStore.getUser();
    const accessToken = Cookies.get("access-token");
    if (user) {
      config.headers = {
        ...config.header,
        Authorization: "Bearer " + accessToken,
      };
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        console.log("interseptor 1");
        try {
          const account = instance.getActiveAccount();
          if (account) {
            console.log("interseptor 2");
            const response = await instance.acquireTokenSilent({
              ...loginRequest,
              account,
            });
            console.log("interseptor 3");
            if (response.accessToken) {
              Cookies.set("access-token", response.accessToken);
            }
          } else {
            console.log("interseptor 4");
            console.log(error);
          }
          console.log("interseptor 5");
          if (originalRequest.data) {
            originalRequest.data = JSON.parse(originalRequest.data);
          }
          return axiosInstance(originalRequest);
        } catch (error) {
          // Handle refresh token error or redirect to login
          console.log(error);
        }
      }
      return Promise.reject(error);
    }
  );
}
