import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";


interface SelectProps {
    name: string;
    label: string;
    control: any;
    errors?: any;
    options?: any;
    defValue?: any;
}


const InputSelectFormControl: React.FC<SelectProps> = (props) => {
    const { name, label, control, options, errors, defValue } = props;
    return (
        <>
            <FormControl {...props} error={Boolean(errors[name])}>
                <InputLabel>{label}</InputLabel>
                <Controller
                    render={({ field }) => (
                        <Select label={label} {...field}>
                            <MenuItem value="">
                                <em>--Select--</em>
                            </MenuItem>
                            {options?.map((opt: any) => {
                                return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                            })}
                        </Select>
                    )}
                    name={name}
                    control={control}
                    defaultValue={defValue}
                />
                {errors && <FormHelperText>{errors[name]?.message}</FormHelperText>}
            </FormControl>
        </>
    );
};

export default InputSelectFormControl;