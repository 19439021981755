import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import React, { useEffect } from 'react'
import InputFormControl from '../controls/InputFormControl';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import InputDateControl from './../controls/InputDateControl';
import { daDK } from '@mui/x-data-grid';

interface ShowNotesProps {
    isOpenDialog: boolean;
    formDetails?: any;
    selectedItemDetails?: any,
    selectedRequestId?: any,
    handleCloseShowItemDetialsPopup: (isShowPopup: boolean) => void
    changeStartDate: (date: any, formDetailsWithTitle: any, formData: any, selectedItemId: any, selectedRequestId: any) => void
}

const ShowItemFormData: React.FC<ShowNotesProps> = (props) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [startDateValue, setStartDateValue] = React.useState("");
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setStartDateValue("");
        props.handleCloseShowItemDetialsPopup(false);
    };

    const handleDialog = () => {
        setOpenDialog(props.isOpenDialog);
    };

    const validationSchema = Yup.object()
        .shape({
            startDate: Yup.string().required("Start date is required")
        }).required();

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        handleDialog();
        console.log(props.formDetails);
    }, [props.isOpenDialog])



    return (
        <>
            <Dialog className='AddUserDialog' open={props.isOpenDialog} onClose={handleCloseDialog}>
                <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>Form Details</DialogTitle>
                <form onSubmit={handleSubmit(async (data: any) => {
                    props.changeStartDate(data.startDate, props.formDetails, props.selectedItemDetails.formData, props.selectedItemDetails.id, props.selectedRequestId);
                })}>
                <DialogContent>
                    <DialogContentText sx={{ p: 1 }}>
                        {
                                props.formDetails.length > 0 && Object.keys(JSON.parse(props.formDetails)).map((data: any, index: number) => {
                                    const fieldValue = JSON.parse(props.formDetails)[data];

                                    // Check if the current field is a date
                                    const isDateField = data.toLowerCase() === "start date" && typeof fieldValue === 'string' && !isNaN(Date.parse(fieldValue));
                                    const parsedDate = isDateField ? new Date(fieldValue) : null;

                                    return (
                                        <div key={index}>
                                            {isDateField && props.selectedItemDetails?.description === 'New Employee Onboarding' ? (
                                                <p>
                                                    <b>{data}</b> :
                                                    <InputDateControl
                                                        key={index}
                                                        name="startDate"
                                                        label=""
                                                        errors={errors}
                                                        control={control}
                                                        type=""
                                                        initialValue={parsedDate}
                                                    />
                                                </p>
                                            ) : (
                                                <p>
                                                    <b>{data}</b> : {fieldValue}
                                                </p>
                                            )}
                                        </div>
                                    );
                            })
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleCloseDialog}>Cancel</Button>
                    {(props.selectedItemDetails && props.selectedItemDetails.description === "New Employee Onboarding") &&
                            <Button type='submit' sx={{ fontSize: "20px" }} variant="contained">Save</Button>
                    }
                </DialogActions>
                </form>
            </Dialog >
        </>
    )
}

export default ShowItemFormData