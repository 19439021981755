export const GetHardwareList = "/GetHardwareList";
export const GetSoftwareAccessList = "/GetSoftwareAndAccessList";
export const AllItemList = "/GetAllItemList";
export const GetCarouselList = "/GetCarouselList";
export const GetEmployeeLifeCycleRequest = "/GetEmployeeLifeCycleRequestList";

// GetRequestDetails
export const GetUserRequestList = "/GetUserRequestList";
export const GetPendingRequestList = "/GetPendingRequestList";
export const GetHistoricalRequestList = "/GetHistoricalRequestList";
export const SaveConfirmRequest = "/SaveConfirmRequest";
export const CancelRequest = "/CancelPendingRequestList";
export const CompleteRequest = "/CompleteRequest";
export const AddNote = "/AddNote";
export const ChangeRequestStatus = "/ChangeRequestStatus";
export const CancelItemFromRequest = "/CancelSpecificItemFromRequest";
export const ChangeItemStatusOfSpecificRequest =
  "/ChangeItemStatusOfSpecificRequest";
export const UpdateStartDateIntoForm = "/UpdateStartDateIntoItemFormDetails";

export const ExternalLoginRequest = "/SSOlogin";
export const CheckAppVersion = "/CheckLatestAppVersion";

//User details
export const GetUsers = "/GetUserList";
export const DeleteUser = "/DeleteUser";
export const CheckUser = "/CheckUser";
export const AddUser = "/AddNewUser";
export const UpdateUserAsAdministrator = "/UpdateUserAsAdministrator";

//Cart details

export const AddItemIntoCart = "/AddItemIntoCart";
export const AddMultipleItemIntoCart = "/AddMultipleItemIntoCart";
export const GetCartListByUser = "/GetCartListByUser";
export const RemoveItemFromCart = "/RemoveItemFromCart";
export const ClearCart = "/ClearCart";

//Category module

export const GetCategoryList = "/GetCategoryList";
export const GetCategoryDetailsById = "/GetCategoryDetailsById";
export const DeleteCategory = "/RemoveCategory";
export const AddUpdateCategory = "/AddUpdateCategory";

//Category Item module

export const GetCategoryItems = "/GetCategoryItemList";
export const DeleteCategoryItem = "/RemoveCategoryItem";
export const AddOrUpdateCategoryItem = "/addUpdateCategoryItem";

//Category Item module

export const GetItemFormList = "/GetItemFormList";
export const DeleteItemForm = "/RemoveItemForm";
export const AddUpdateItemForm = "/AddUpdateItemForm";
export const GetFormDataById = "/GetFormDetailsById";

//Employee on boarding module

export const GetEmployeeOnboardingEquipmentList =
  "/GetEmployeeOnboardingEquipmentList";
export const RemoveEmployeeOnboardingEquipment =
  "/RemoveEmployeeOnboardingEquipment";
export const AddUpdateEmployeeOnboardingEquipment =
  "/AddUpdateEmployeeOnboardingEquipment";
export const GetEmployeeOnboardingEquipmentDetailsById =
  "/GetEmployeeOnboardingEquipmentDetailsById";

//Hardware packages for role

export const GetEmployeeRolesList = "/GetEmployeeRole";
export const AddRemoveHardwareItemsForRole = "/AddRemoveHardwareItemsForRole";
export const SpecificEmployeeRole = "/GetSpecificEmployeeRole";