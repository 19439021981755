import {
    FormControl,
    FormHelperText,
    TextField,
    TextareaAutosize,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

interface InputProps {
    name: string;
    label: string;
    control: any;
    errors?: any;
    initialValue?: any;
}

const InputTextAreaFormControl: React.FC<InputProps> = (props) => {
    const { name, label, control, errors, initialValue } = props;
    return (
        <>
            <FormControl {...props} error={Boolean(errors[name])}>
                <Controller
                    render={({ field: { onChange, value } }) => (
                        <TextareaAutosize
                            style={{ width: "100%", fontSize: "16px", fontFamily: "Verdana, Geneva, Tahoma, sans-serif", lineHeight: "inherit" }}
                            id="outlined-required"
                            minRows={3}
                            onChange={onChange}
                            defaultValue={value}
                        />
                    )}
                    name={name}
                    control={control}
                    defaultValue={initialValue}
                />
                {errors && <FormHelperText>{errors[name]?.message}</FormHelperText>}
            </FormControl>
        </>
    );
};

export default InputTextAreaFormControl;
