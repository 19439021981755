export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const responsiveForPopup = {
  mobile: {
    // the naming can be any, depends on you.
    breakpoint: { max: 992, min: 0 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 992 },
    items: 2,
    slidesToSlide: 1,
  },
};
  