import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, Box, DialogActions, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import InputFormControl from '../../components/controls/InputFormControl';
import InputMultiSelectFormControl from '../../components/controls/InputMultiSelectFormControl';
import { GetErrorMessageFromResponse } from '../../common/commonFunction';
import { CategoryItemService } from '../../services/CategoryItemModule/CategoryItemService';
import { useSnackBar } from '../../components/context/snackbar-context';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

interface props {
    isOpenAddEditEmployeeOnBoardingDetailsDialog: boolean,
    AddEditEmployeeOnBoardingData: (id: number, categoryData: any) => void,
    handleCloseAddEditEmployeeOnBoardingPopup: (isShowPopup: boolean) => void,
    itemDetails: any
}

const AddEditEmployeeOnBoardingDetails: React.FC<props> = ({ itemDetails, isOpenAddEditEmployeeOnBoardingDetailsDialog, AddEditEmployeeOnBoardingData, handleCloseAddEditEmployeeOnBoardingPopup }) => {
    const [isUpdateImage, setIsUpdateImage] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [itemListOption, setItemListOption] = React.useState<any>();
    const [defaultItemIds, SetdefaultItemIds] = useState<any>([]);
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
    const snackbar = useSnackBar();
    const getItemListOptions = async () => {
        let opt: any = [];

        CategoryItemService.getCategoryItemList()
            .then((res: any) => {
                if (res) {
                    res.data.data.map((item: any) => {
                        // opt.push({ label: { name: item.description, image: item.imageUrl }, value: Number(item.id) })
                        opt.push({
                            label: (
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: "40px", height: "35px", borderRadius: "0", marginRight: "20px" }}>
                                        <img
                                            alt=''
                                            src={item.imageUrl}
                                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                        />
                                    </div>
                                    {item.description}
                                </Box>
                            ), value: Number(item.id)
                        })
                    })
                    setItemListOption(opt);
                }
            })
            .catch((err: any) =>
                snackbar.showSnackBar(GetErrorMessageFromResponse(err), "error"))

    }
    const validationSchema = Yup.object()
        .shape({
            equipmentName: Yup.string().required("Equipment Name is required"),
            equipmentImageURL: Yup.mixed().nullable().required("Please select image for category item")
                .test('fileType', "Please select image formate file",
                    (value: any) => { return (value !== "" && itemDetails.id === 0) ? SUPPORTED_FORMATS.includes(value[0].type) : (itemDetails.id === 0 ? false : true) }),
            itemIds: Yup.array().min(1, "At least one item should be selected").required("At least one Item should be selected"),
            itemQuantity: Yup.number()
        }).required();

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const handleDialog = () => {
        setOpenDialog(isOpenAddEditEmployeeOnBoardingDetailsDialog);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleCloseAddEditEmployeeOnBoardingPopup(false);
        // setValue("description", "");
        // setValue("image", "");
        // setValue("longDesc", "");
        // setValue("itemSubCategoryId", "");
        // setValue("hasForm", false);
        // setValue("formId", "");

    };

    useEffect(() => {
        getItemListOptions();
        handleDialog();
    }, [isOpenAddEditEmployeeOnBoardingDetailsDialog]);
    useEffect(() => {
        if (itemDetails.id === 0) {
            setIsUpdateImage(false);
            setValue("equipmentName", "");
            setValue("equipmentImageURL", "");
            setValue("itemQuantity", 1);
            setValue("itemIds", []);
            SetdefaultItemIds([]);
        }
        else {

            setIsUpdateImage(true);
            setValue("itemQuantity", itemDetails.itemQuantity);
            setValue("equipmentName", itemDetails.equipmentName);
            setValue("equipmentImageURL", "");
            if (itemDetails && itemDetails.itemIds && itemDetails.itemIds.split(",").length > 0) {
                const resultArray = itemDetails.itemIds.split(",").map((value: Number) => ({ value: Number(value) }));
                SetdefaultItemIds(resultArray);
                setValue("itemIds", resultArray);
            }
            else {
                setValue("itemIds", []);
            }
        }
    }, [itemDetails])

    return (
        <Dialog className='AddUserDialog' open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle className='DialogTitleDiv' sx={{ fontWeight: "bold" }}>{Number(itemDetails.id) === 0 ? "Add New" : "Update"} Employee Onboarding Equipment</DialogTitle>
            <DialogContent sx={{ overflowY: "visible" }}>
                <form onSubmit={handleSubmit(async (data: any, e: any) => {
                    data.equipmentImageURL = data.equipmentImageURL[0];
                    data.isdeleted = itemDetails.isDeleted;
                    data.itemIds.map((item: any) => {
                        delete item.label;
                        return item.value;
                    })
                    data.itemIds = Object.keys(data.itemIds).map((k: any) => { return data.itemIds[k].value }).join(",");

                    AddEditEmployeeOnBoardingData(itemDetails.id, data);
                })}>
                    <DialogContentText sx={{ p: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Label Name</label>
                                    </div>
                                    <div className='col-8'>
                                        <InputFormControl
                                            name="equipmentName"
                                            label=""
                                            errors={errors}
                                            control={control}
                                            type=""
                                        />
                                    </div>

                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Icon Image</label>
                                    </div>
                                    {
                                        itemDetails.id === 0 ? (<div className='col-8'>
                                            <InputFormControl
                                                name="equipmentImageURL"
                                                label=""
                                                errors={errors}
                                                control={control}
                                                type="file"
                                            />
                                        </div>) : (<><Box sx={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ minWidth: "50px" }} ><img
                                                alt=''
                                                src={itemDetails.equipmentImageURL}
                                                style={{ width: "75px", height: "70px", borderRadius: "0", marginRight: "20px" }}
                                            /></div>
                                            {
                                                (isUpdateImage && itemDetails.id !== 0) ? (<EditIcon
                                                    onClick={() => {
                                                        setIsUpdateImage(false);
                                                    }}
                                                    style={{ marginLeft: "20px", height: "25px", width: "25px" }} />) : (
                                                    <><InputFormControl
                                                        name="equipmentImageURL"
                                                        label=""
                                                        errors={errors}
                                                        control={control}
                                                        type="file"
                                                    />
                                                        <CloseIcon onClick={() => {
                                                            setIsUpdateImage(true);
                                                        }}
                                                            style={{ height: "25px", width: "25px", cursor: "pointer" }} /></>)
                                            }
                                        </Box>
                                        </>)
                                    }

                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='row'>
                                    <div className="form-lable col-4">
                                        <label>Attached Items</label>
                                    </div>
                                    <div className='col-8 InputMultiSelectControl'>
                                        <InputMultiSelectFormControl
                                            errors={errors}
                                            control={control}
                                            name="itemIds"
                                            label=""
                                            options={itemListOption}
                                            defaultValue={defaultItemIds}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    <DialogActions sx={{ p: 2 }}>
                        <Button sx={{ fontSize: "20px" }} variant="contained" onClick={handleCloseDialog}>Cancel</Button>
                        <Button type='submit' sx={{ fontSize: "20px" }} variant="contained">{Number(itemDetails.id) === 0 ? "Add" : "Update"}</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog >
    )
}

export default AddEditEmployeeOnBoardingDetails