import React, { useEffect, useState } from "react";
import Minus from "../assets/icon/minus.png";
import {
  Box,
  Button,
  List,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import { useCartListStore, userStore } from "../store/store";
import { ICartItem } from "../types/categoryListType";
import { useConfirmPopup } from "../components/context/confirmation-context";
import { useSnackBar } from "../components/context/snackbar-context";
import { useNavigate } from "react-router-dom";
import Loader from "../components/common components/Loader";


const CartPage = () => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const navigate = useNavigate();
  const pStore: any = useCartListStore();
  const uStore: any = userStore();
  const [totalCartItem, setTotalCartItem] = useState(0);

  useEffect(() => {
    setTotalCartItem(pStore.cartList?.length);
    if (pStore.cartList)
      setIsloading(false);

  }, [pStore.cartList]);

  const snackbar = useSnackBar();
  const confirmPopupService = useConfirmPopup();

  const handleRemove = (item: any) => {
    confirmPopupService.showPopup("Are you sure you want to remove this item?", () => { handleCancelConfirmClose(item) });

  }

  const handleClearCart = () => {
    confirmPopupService.showPopup("This will remove ALL items from your cart. Are you sure you want to continue?", handleConfirmClearCart);
  }

  const handleConfirmClearCart = () => {
    pStore.clearCart(uStore.user.userEmail);
    setTotalCartItem(0);
    confirmPopupService.hidePopup();
  }

  const handleCancelConfirmClose = (item: any): void => {
    let msg: boolean = pStore.RemoveItemFromCart(uStore.user.userEmail, item);

    if (msg) {
      confirmPopupService.hidePopup();
      snackbar.showSnackBar("Item has been removed from your cart.", "success");
    }
    else {
      confirmPopupService.hidePopup();
      snackbar.showSnackBar("Item has not been removed from your cart.", "error");
    }
  };

  return (
      <Paper
        elevation={4}
        sx={{
          maxWidth: "100%",
          minHeight: "80vh",
          maxHeight: "fit-content",
          padding: 2,
          margin: 2,
          textAlign: "center",
        }}
      >
        <Typography className="cartHeader" variant="h5" component="div">
          Items in your cart
        </Typography>
        <Typography component="div">
          <Button sx={{ marginBottom: 2, marginRight: 1 }} size="large" variant="contained"
            disabled={totalCartItem === 0 ? true : false}
            onClick={() => {
              navigate('/confirm-request');
            }}>
            Proceed to Checkout
          </Button>
          <Button
            sx={{ marginBottom: 2 }}
            size="large"
            variant="outlined"
            onClick={() => {
              handleClearCart();
            }}
            disabled={totalCartItem === 0 ? true : false}
          >
            Clear cart
          </Button>
          <div>
            <label>Total Items : {totalCartItem}</label>
          </div>
        {
          isLoading ?
            (
              <Loader />
            ) :
            (
              <List sx={{ textAlign: "center", width: "100%", overflowY: "auto" }}>
                {pStore.cartList && pStore.cartList.length ? (
                  pStore.cartList.map((item: ICartItem, i: number) => (
                    <ListItem key={i}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          p: 1,
                          m: 1,
                          bgcolor: "background.paper",
                          borderRadius: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "row",
                            justifyContent: "center",
                            width: "100%"
                          }}
                        >
                          <div>
                            <div className="requestItemImage">
                              <img
                                key={i}
                                src={process.env.PUBLIC_URL + item.imageUrl}
                                alt=""
                              />
                            </div>
                            <div className="CartItemDesc">
                              <span>{item.description}</span>
                            </div>
                          </div>
                          <div className="cartRemoveImage">
                            <div>
                              <img
                                alt=""
                                src={Minus}
                                onClick={() => {
                                  handleRemove(item);
                                }}
                              ></img>
                            </div>
                          </div>
                        </Box>
                      </Box>
                    </ListItem>
                  ))
                ) : (
                  <label className="emptyCartText">Your cart is empty</label>
                )}
              </List>
            )
        }

        </Typography>
    </Paper>
  );
};

export default CartPage;
