import { AxiosResponse } from "axios";
import instance from "../common/http.common";
import {
  AddUser,
  CheckUser,
  DeleteUser,
  GetUsers,
  UpdateUserAsAdministrator,
} from "../common/ApiEndPoints";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";
const _baseUrl: string = `${_rootUrl}/User`;

const GetAllUsers = async (): Promise<AxiosResponse<any>> => {
  return await instance.get(_baseUrl + GetUsers);
};

const AddNewUser = async (userData: any): Promise<AxiosResponse<any>> => {
  return await instance.post(_baseUrl + AddUser, userData);
};

const CancelUser = async (id: any): Promise<AxiosResponse<any>> => {
  return await instance.delete(`${_baseUrl + DeleteUser}/?userID=${id}`);
};

const UpdateUserRole = async (id: any): Promise<AxiosResponse<any>> => {
  return await instance.post(
    `${_baseUrl + UpdateUserAsAdministrator}/?userID=${id}`
  );
};

const CheckIsUserExist = async (email: any): Promise<AxiosResponse<any>> => {
  return await instance.get(`${_baseUrl + CheckUser}/?email=${email}`);
};

export const UserDetialsService = {
  GetAllUsers,
  CancelUser,
  CheckIsUserExist,
  AddNewUser,
  UpdateUserRole,
};
