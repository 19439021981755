import { Card, CardContent, List, Box, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Breadcrumbs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ConvertDate, GetTime, getLastTwelveMonths, getMonthNumberFromName } from '../common/commonFunction';
import Navbar from '../components/Navbar';
import Loader from '../components/common components/Loader';
import { UserRequestListService } from '../services/UserRequestListService';
import { NavLink } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { userStore } from '../store/store';
import ShowNotes from '../components/ShowNotes';
import InfoIcon from '@mui/icons-material/Info';
import ShowItemFormData from '../components/modelPopup/ShowItemFormData';

const HistoricalRequests = () => {

    const [isLoading, setIsloading] = useState(true);
    const [selectedH_RequestNotes, SetSelectedH_RequestNotes] = useState<any>([]);
    const [OpenShowNotePopup, SetOpenShowNotePopup] = useState(false);
    const [LastTwelveMonths, setLastTwelveMonths] = useState<any>([]);
    const [historicalRequests, setHistoricalRequests] = React.useState([]);
    const [filteredHistoricalRequests, setFilteredHistoricalRequests] = React.useState([]);
    const [monthText, setMonthText] = React.useState('');
    const uStore: any = userStore();
    const [selectedItemFormDetails, SetSelectedItemFormDetails] = useState<any>([]);
    const [OpenShowItemDetailsPopup, SetOpenShowItemDetailsPopup] = useState(false);

    const handleChange = (event: SelectChangeEvent) => {
        setFilteredHistoricalRequests([]);
        setMonthText(event.target.value as string);
        let mnth = getMonthNumberFromName(event.target.value.split(" ")[0]) - 1;
        let year = Number(event.target.value.split(" ")[1]);
        let firstDay = new Date(year, mnth, 1);
        let lastDay = new Date(year, mnth + 1, 0);
        let new_historicalRequests = historicalRequests.filter((list: any) => {
            return new Date(list.createdDate) >= firstDay && new Date(list.createdDate) <= lastDay
        });
        if (new_historicalRequests && new_historicalRequests.length > 0) {
            setFilteredHistoricalRequests(new_historicalRequests);
        }
    };
    const getHistoricalRequest = async () => {
        setFilteredHistoricalRequests([]);
        let res = await UserRequestListService.GetAllHistoricalRequestList(uStore.getUser().userEmail);
        if (res.data) {
            console.log(res.data.data);
            setHistoricalRequests(res.data.data);
            setIsloading(false);
            let res_data = res.data.data;
            var date = new Date(), y = date.getFullYear(), m = date.getMonth();
            var firstDay = new Date(y, m, 1);
            var lastDay = new Date(y, m + 1, 0);
            res_data = res_data.filter((list: any) => {
                return new Date(list.createdDate) >= firstDay && new Date(list.createdDate) <= lastDay
            });
            if (res_data && res_data.length > 0) {
                setFilteredHistoricalRequests(res_data);
            }
            console.log("res_data", res_data);
        }
    }

    useEffect(() => {
        if (LastTwelveMonths.length > 0) {
            setMonthText(LastTwelveMonths[0]);
        }
    }, [LastTwelveMonths])


    useEffect(() => {
        setMonthText("");
        let res = getLastTwelveMonths();
        setLastTwelveMonths(res);
        getHistoricalRequest();

    }, []);

    const handleCloseShowNotePopup = (isShowPopup: boolean) => {
        SetOpenShowNotePopup(isShowPopup);
    }

    const handleCloseShowItemDetialsPopup = (isShowPopup: boolean) => {
        SetOpenShowItemDetailsPopup(isShowPopup);
    }

    return (
        <>
            <div className='breadcumDiv'>
                {
                    uStore.getUser().role === "Administrator" ?
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/pending-request" title="Pending Request">
                                Pending Request
                            </NavLink>
                            <span className="active-breadcumb">Historical Request</span>
                        </Breadcrumbs>) :
                        (<Breadcrumbs
                            separator={<ArrowForwardIosIcon />}
                            aria-label="breadcrumb"
                        >
                            <NavLink to="/search" title=" Make A Request">
                                Make A Request
                            </NavLink>
                            <NavLink to="/pending-request" title="Pending Request">
                                Pending Request
                            </NavLink>
                            <span className="active-breadcumb">Historical Request</span>
                        </Breadcrumbs>)
                }

            </div>
            <Navbar title="Historical Request" />
            <Card
                sx={{
                    mt: 3,
                    p: 2,
                    minWidth: "100%",
                    maxWidth: "100%",
                    textAlign: "start",
                    boxShadow: "none",
                    pr: 0,
                }}
            >
                <CardContent>
                    {
                        isLoading ? (
                            <Loader />
                        ) :
                            (
                                <div>

                                    <FormControl sx={{ width: "30%", m: 3 }}>
                                        <InputLabel id="demo-simple-select-label">Select a month</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={monthText}
                                            label="Select a month"
                                            onChange={handleChange} >
                                            {
                                                LastTwelveMonths.map((month: any) => {
                                                    return (
                                                        <MenuItem value={month}>{month}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <List sx={{ margin: "auto", textAlign: "center", width: "95%" }}>
                                        <Grid container sx={{ gridAutoRows: "1fr" }} rowSpacing={2} spacing={3}>
                                            {
                                                filteredHistoricalRequests && filteredHistoricalRequests.length > 0 ? (
                                                    filteredHistoricalRequests.map((list: any) => {
                                                        return (
                                                            <Grid item xs={12} sm={6} md={6} lg={4} key={list.id} sx={{ mb: 3 }}>
                                                                <Card elevation={3} sx={{ pl: 2, pt: 2, pr: 2, height: "100%" }} className='PendingRequestItem'>
                                                                    <Box sx={{ display: "flex", mb: 3, justifyContent: "space-between" }}>
                                                                        {
                                                                            list.behalfUserName ?
                                                                                (
                                                                                    <label className='PendingRequestUserDetails'>
                                                                                        Requested by {list.createdUserName} (on behalf of {list.behalfUserName})   on {ConvertDate(list.createdDate)} @ {GetTime(list.createdDate)}
                                                                                    </label>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <label className='PendingRequestUserDetails'>
                                                                                        Requested by {list.createdUserName} on {ConvertDate(list.createdDate)} @ {GetTime(list.createdDate)}
                                                                                    </label>
                                                                                )
                                                                        }
                                                                        <div>
                                                                            {
                                                                                ((list.request_Notes && list.request_Notes.length > 0) &&
                                                                                    (
                                                                                        <img onClick={() => {
                                                                                            SetOpenShowNotePopup(true);
                                                                                            SetSelectedH_RequestNotes(list.request_Notes);
                                                                                        }} className='NoteListIcon' src={process.env.PUBLIC_URL + '/Icon/notes.png'} alt='' />
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    </Box>
                                                                    <div>

                                                                        {
                                                                            list.items.map((item: any, index: number) => {
                                                                                return (
                                                                                    <Box key={item.id} sx={{ display: "flex", mb: 3, justifyContent: "center", alignItems: "start" }}>
                                                                                        <div style={{ width: "90%" }}>
                                                                                            <div className='requestItemStatusDesc'>
                                                                                                {
                                                                                                    item.status === "Pending" &&
                                                                                                    (<span >
                                                                                                        <b>Status : </b> Pending
                                                                                                    </span>)
                                                                                                }
                                                                                                {
                                                                                                    item.status === "Approved" && (
                                                                                                        <Box sx={{ display: "flex", mb: 3, justifyContent: "center", alignItems: "center" }}>
                                                                                                            <img className='ApprovedDeniedIcon' src={process.env.PUBLIC_URL + '/Icon/Approved.png'} alt='' /> <span style={{ marginLeft: "10px" }}>
                                                                                                                <b> Approved </b>
                                                                                                            </span>
                                                                                                        </Box>
                                                                                                    )
                                                                                                }
                                                                                                {
                                                                                                    item.status === "Denied" && (
                                                                                                        <Box sx={{ display: "flex", mb: 3, justifyContent: "center", alignItems: "center" }}>
                                                                                                            <img className='ApprovedDeniedIcon' src={process.env.PUBLIC_URL + '/Icon/Denied.png'} alt='' /> <span style={{ marginLeft: "10px" }}>
                                                                                                                <b> Denied :  </b> {item.deniedReason}
                                                                                                            </span>
                                                                                                        </Box>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                            <div className="requestItemImage">
                                                                                                <img alt="" src={process.env.PUBLIC_URL + item.imageUrl}></img>
                                                                                            </div>
                                                                                            <div className="requestItemDesc">
                                                                                                <span>{item.description}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            item.formData && (<InfoIcon
                                                                                                onClick={() => {
                                                                                                    SetOpenShowItemDetailsPopup(true);
                                                                                                    SetSelectedItemFormDetails(item.formData);
                                                                                                }}
                                                                                                style={{ height: "40px", width: "30px" }} />)
                                                                                        }
                                                                                    </Box >
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {/* {
                                                                            <div style={{ textAlign: "left", width: "80%" }}>
                                                                            {
                                                                                list.status === "Approved" && (<label className='PendingRequestUserDetails'>
                                                                                    <p><b>Approved By {list.statusTrack.changedBy} on  {ConvertDate(list.statusTrack.changedByDate)} @ {GetTime(list.statusTrack.changedByDate)} </b></p>
                                                                                </label>)

                                                                            }
                                                                            {
                                                                                list.status === "Denied" && (<><label className='PendingRequestUserDetails'>
                                                                                    <p><b>Denied By {list.statusTrack.changedBy} on  {ConvertDate(list.statusTrack.changedByDate)} @ {GetTime(list.statusTrack.changedByDate)} </b></p>
                                                                                </label>
                                                                                    <label className='PendingRequestUserDetails'>
                                                                                        <p><b>Denied Reason  : </b> {list.statusTrack.deniedReason}</p>
                                                                                    </label></>)
                                                                            }
                                                                            {
                                                                                list.status === "Completed" && (<label className='PendingRequestUserDetails'>
                                                                                    <p><b>Requested completed on  {ConvertDate(list.statusTrack.changedByDate)} @ {GetTime(list.statusTrack.changedByDate)}</b></p>
                                                                                </label>)

                                                                            }

                                                                        </div>
                                                                    } */}

                                                                </Card>
                                                            </Grid>
                                                        )
                                                    })
                                                ) :
                                                    (
                                                        <label className='noDataFoundMessage'> No Historical Request Found for {monthText}</label>
                                                    )
                                            }
                                        </Grid>
                                    </List>
                                </div>
                            )
                    }
                </CardContent>
            </Card>
            <ShowNotes requestNotes={selectedH_RequestNotes} isOpenDialog={OpenShowNotePopup} handleCloseShowNotePopup={(isShowPopup) => { handleCloseShowNotePopup(isShowPopup) }} />
            {/* <ShowItemFormData formDetails={selectedItemFormDetails} isOpenDialog={OpenShowItemDetailsPopup} handleCloseShowItemDetialsPopup={(isShowPopup: any) => { handleCloseShowItemDetialsPopup(isShowPopup) }} /> */}
        </>
    )
}

export default HistoricalRequests